import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import api from '../../api/index';
import Page from '../../components/Page/index';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import { FIELD_FACILITY_ID } from '../../schema/constants';
import PinInput from '../../components/PinInput';
import FacilityName from '../../components/FacilityName';


const FIELD_PIN = 'PIN';

const schema = Yup.object().shape({
  [FIELD_FACILITY_ID]: Yup.string().required(),
  [FIELD_PIN]: Yup.string()
    .matches(/^[0-9]{4}$/, 'PIN is a 4 digit number')
    .required('You need a PIN to login'),
});

const LoginWithPin = ({ facilityId, onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');
  const onSubmit = useCallback(
    async (values) => {
      try {
        setSubmissionError('');
        const { accessToken, tokenExpiry } = await api.post(
          process.env.REACT_APP_DAILY_REPORT_LOGIN_SERVICE_URL,
          values,
        );
        onSuccess({ accessToken, tokenExpiry });
      } catch (error) {
        const errorMessage =
          error.response && error.response.status === 401 ? 'Invalid PIN' : 'Something went wrong';
        setSubmissionError(errorMessage);
      }
    },
    [onSuccess],
  );

  return (
    <Page>
	  <FacilityName h1/>
      <Section title="PIN Entry" />
      <SectionContent>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            [FIELD_FACILITY_ID]: facilityId,
          }}
        >
          {({ dirty, isValid, isSubmitting, handleSubmit, setFieldValue }) => {
            const onChangePin = (e) => {
              const {
                target: { value },
              } = e;
              setFieldValue(FIELD_PIN, value);
              setSubmissionError('');
            };

            return (
              <Form>
                <Field
                  name={FIELD_PIN}
                  id="pin-component"
                  label="4-digit facility PIN"
                  component={PinInput}
                  type="number"
                  onChange={onChangePin}
                  data-testid="pin-input-test"
                />
                <Submission
                  text={isSubmitting ? 'Continue...' : 'Continue'}
                  canSubmit={dirty && isValid}
                  handleSubmit={handleSubmit}
                  error={submissionError}
                  isSubmitting={isSubmitting}
                  id="fa-button-submit-form"
                />
              </Form>
            );
          }}
        </Formik>
      </SectionContent>
    </Page>
  );
};

export default LoginWithPin;
