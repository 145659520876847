import {
  ISO_PROFILE_WORKERS,
  ISO_COVID,
  ISO_PROFILE_NUMBER_MED_STAFF,
  ISO_PROFILE_NUMBER_CLINICAL_STAFF,
  ISO_PROFILE_NUMBER_NURSES,
  ISO_PROFILE_NUMBER_LAB_TECHNICIANS,
  ISO_PROFILE_NUMBER_OTHER_STAFF,
  ISO_PROFILE_NUMBER_NON_TECH_STAFF,
  ISO_PROFILE_NUMBER_BEDS,
  ISO_PROFILE_NUMBER_ICU_BEDS,
  ISO_PROFILE_NUMBER_COVID,
  ISO_PROFILE_NUMBER_MALES_UNDER5,
  ISO_PROFILE_NUMBER_MALES_6TO17,
  ISO_PROFILE_NUMBER_MALES_18TO59,
  ISO_PROFILE_NUMBER_MALES_60PLUS,
  ISO_PROFILE_NUMBER_FEMALES_UNDER5,
  ISO_PROFILE_NUMBER_FEMALES_6TO17,
  ISO_PROFILE_NUMBER_FEMALES_18TO59,
  ISO_PROFILE_NUMBER_FEMALES_60PLUS,
  ISO_PROFILE_NUMBER_UNDERLYING,
  ISO_PROFILE_NUMBER_TREATED_ICU,
  ISO_PROFILE_NUMBER_TESTED_ICU,
  ISO_PROFILE_NUMBER_RECOVERIES,
  ISO_PROFILE_NUMBER_DEATHS,
} from '../../schema/constants';
import { Field, getIn } from 'formik';
import React from 'react';

import { Box } from 'reflexbox/styled-components';
import InlineNumber from '../../components/Input/InlineNumber';
import InputText from '../../components/Input/Text';
import Label from '../../components/Label';
import Section from '../../components/Section';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import { isDeveloperMode } from '../../utils/debug';

const getWorkerSum = (vals) => {
  let sum = 0;
  if (!vals[ISO_PROFILE_WORKERS]) {
    return 0;
  }
  // eslint-disable-next-line
  for (let [key, val] of Object.entries(vals[ISO_PROFILE_WORKERS])) {
    sum = sum + val;
  }
  return sum;
};

const Details = ({ canSubmit, isSubmitting, handleSubmit, values, submissionError, errors }) => (
  <Box>
    <Section title="Staff" />
    <SectionContent>
      <div style={{ marginBottom: '20px' }}>
        <Label label="How many of the following staff members are employed at your isolation facility?" />
      </div>
      <Field
        label="Medical officers?"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_MED_STAFF}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_MED_STAFF}`)}
        className={`iso-${ISO_PROFILE_NUMBER_MED_STAFF}`}
      />
      <Field
        label="Clinical officers?"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_CLINICAL_STAFF}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_CLINICAL_STAFF}`)}
        className={`iso-${ISO_PROFILE_NUMBER_CLINICAL_STAFF}`}
      />
      <Field
        label="Nurses?"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_NURSES}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_NURSES}`)}
        className={`iso-${ISO_PROFILE_NUMBER_NURSES}`}
      />
      <Field
        label="Lab technicians?"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_LAB_TECHNICIANS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_LAB_TECHNICIANS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_LAB_TECHNICIANS}`}
      />
      <Field
        label="Other technical staff"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_OTHER_STAFF}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_OTHER_STAFF}`)}
        className={`iso-${ISO_PROFILE_NUMBER_OTHER_STAFF}`}
      />
      <Field
        label="Other non-technical staff"
        name={`${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_NON_TECH_STAFF}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_WORKERS}.${ISO_PROFILE_NUMBER_NON_TECH_STAFF}`)}
        className={`iso-${ISO_PROFILE_NUMBER_NON_TECH_STAFF}`}
      />

      <p>
        Total staff <span className="iso-numberOfIsolationUnitStaff">{getWorkerSum(values)}</span>
      </p>
    </SectionContent>
    <Section title="Capacity" />
    <SectionContent>
      <Field
        label="How many beds does your facility have?"
        name={`${ISO_PROFILE_NUMBER_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_NUMBER_BEDS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_BEDS}`}
      />
      <Field
        label="How many ICU beds (with ventilators) does your facility have?"
        name={`${ISO_PROFILE_NUMBER_ICU_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_PROFILE_NUMBER_ICU_BEDS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_ICU_BEDS}`}
      />
      <Field
        label="How many patients with COVID-19 have been admitted at your facility to date?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_COVID}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_COVID}`)}
        className={`iso-${ISO_PROFILE_NUMBER_COVID}`}
      />
    </SectionContent>
    <Section title="Patients" />
    <SectionContent>
      <Label label="Of total admitted, how many males are currently in isolation in the following age ranges?" />

      <Field
        label="Aged 5 and under?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_UNDER5}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_UNDER5}`)}
        className={`iso-${ISO_PROFILE_NUMBER_MALES_UNDER5}`}
      />
      <Field
        label="Aged 6 to 17?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_6TO17}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_6TO17}`)}
        className={`iso-${ISO_PROFILE_NUMBER_MALES_6TO17}`}
      />
      <Field
        label="Aged 18 to 59?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_18TO59}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_18TO59}`)}
        className={`iso-${ISO_PROFILE_NUMBER_MALES_18TO59}`}
      />
      <Field
        label="Aged 60 or over?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_60PLUS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_MALES_60PLUS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_MALES_60PLUS}`}
      />

      <Label label="Of total admitted, how many female are currently in isolation in the following age ranges?" />

      <Field
        label="Aged 5 and under?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_UNDER5}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_UNDER5}`)}
        className={`iso-${ISO_PROFILE_NUMBER_FEMALES_UNDER5}`}
      />
      <Field
        label="Aged 6 to 17?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_6TO17}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_6TO17}`)}
        className={`iso-${ISO_PROFILE_NUMBER_FEMALES_6TO17}`}
      />
      <Field
        label="Aged 18 to 59?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_18TO59}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_18TO59}`)}
        className={`iso-${ISO_PROFILE_NUMBER_FEMALES_18TO59}`}
      />
      <Field
        label="Aged 60 or over?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_60PLUS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_FEMALES_60PLUS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_FEMALES_60PLUS}`}
      />
      <Field
        label="How many COVID patients have underlying health conditions?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_UNDERLYING}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_UNDERLYING}`)}
        className={`iso-${ISO_PROFILE_NUMBER_UNDERLYING}`}
      />
      <Field
        label="How many patients have been treated for COVID-19 in ICU to date?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_TREATED_ICU}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_TREATED_ICU}`)}
        className={`iso-${ISO_PROFILE_NUMBER_TREATED_ICU}`}
      />
      <Field
        label="How many patients have been tested for COVID-19 in ICU to date?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_TESTED_ICU}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_TESTED_ICU}`)}
        className={`iso-${ISO_PROFILE_NUMBER_TESTED_ICU}`}
      />
      <Field
        label="How many COVID-19 recoveries have there been at your facility to date?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_RECOVERIES}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_RECOVERIES}`)}
        className={`iso-${ISO_PROFILE_NUMBER_RECOVERIES}`}
      />
      <Field
        label="How many COVID-19 deaths have there been at your facility to date?"
        name={`${ISO_COVID}.${ISO_PROFILE_NUMBER_DEATHS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${ISO_COVID}.${ISO_PROFILE_NUMBER_DEATHS}`)}
        className={`iso-${ISO_PROFILE_NUMBER_DEATHS}`}
      />

      {isDeveloperMode && (
        <>
          <p>Developer mode enabled</p>
          <p>{JSON.stringify(values)}</p>
          <p>{JSON.stringify(errors)}</p>
        </>
      )}
      <Submission
        text={isSubmitting ? 'Submitting...' : 'Submit'}
        canSubmit={canSubmit}
        handleSubmit={handleSubmit}
        error={submissionError}
        isSubmitting={isSubmitting}
        id="iso-button-submit-form"
      />
    </SectionContent>
  </Box>
);

export default Details;
