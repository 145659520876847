import styled from 'styled-components';
import theme from '../../theme';
import { Flex } from 'reflexbox';

const Page = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;
  background-color: ${theme.colors.lightGrey};
`;

export const PageCenteredContent = styled(Flex)`
  height: 100vh;
  flex-direction: column;
  justify-content: center;
`;

export default Page;
