import { Form, Formik } from 'formik';
import { Persister, useHydration } from '../../components/Persistence/index';
import React, { useCallback, useEffect, useState } from 'react';

import Confirmation from './Confirmation';
import Details from './Details';
import {
  FIELD_FACILITY_ID, QU_FACILITY_NAME, FIELD_FACILITY_TYPE,
  QU_PERSIST_PROFILE_PREFIX,
} from '../../schema/constants';
import Footer from '../../components/Footer';
import InCharges from './InCharges';
import NotFound from '../NotFound';
import Page from '../../components/Page/index';
import api from '../../api/index';
import { generateSchema } from '../../schema/validation/quarantineUnitProfile';
import { generatePersistenceKey } from '../../helpers/index';
import rg4js from 'raygun4js';
import { scrollToTopOfPage } from '../../helpers/index';

const STEP_IN_CHARGES = 'InCharges';
const STEP_DETAILS = 'Details';
const STEP_CONFIRMATION = 'Confirmation';

const QuarantineUnitProfiling = ({ facilityId }) => {
  const [currentStep, setCurrentStep] = useState(STEP_IN_CHARGES);
  const [pin, setPin] = useState();
  const [submissionError, setSubmissionError] = useState('');
  const [sessionStorageKey] = useState(generatePersistenceKey(QU_PERSIST_PROFILE_PREFIX));
  const initialValues = useHydration(sessionStorageKey, {
    [FIELD_FACILITY_ID]: facilityId,
    [FIELD_FACILITY_TYPE]: 'quarantine',
    inChargeMembers: [{}],
    [QU_FACILITY_NAME]: '',

  });

  const name = initialValues && initialValues.inChargeMembers[0] ? initialValues.inChargeMembers[0].name : 'unknown';

  useEffect(() => rg4js('setUser',
    {
      identifier: facilityId,
      isAnonymous: false,
      fullName: name,
    }), [facilityId, name],
  );

  const continueToStep2 = useCallback(() => {
    setCurrentStep(STEP_DETAILS);
    scrollToTopOfPage();
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSubmissionError('');
        const response = await api.post(process.env.REACT_APP_FACILITY_SERVICE_URL, values);
        if (response && response.updatedDocument && response.updatedDocument.PIN) {
          sessionStorage.removeItem(sessionStorageKey);
          setPin(response.updatedDocument.PIN);
          setCurrentStep(STEP_CONFIRMATION);
        }
      } catch (error) {
        console.error(error);
        setSubmissionError('Something went wrong');
      }
    },
    [sessionStorageKey],
  );

  if (!facilityId) {
    // TODO: return some useful message
    return <NotFound/>;
  }

  return (
    <Page>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={generateSchema()}
              validateOnMount={true}>
        {({ dirty, isValid, isSubmitting, handleSubmit, errors, values }) => {
          return (
            <Form>
              <Persister name={sessionStorageKey} ignoredKeys={[FIELD_FACILITY_ID]}/>
              {currentStep === STEP_IN_CHARGES && (
                <InCharges errors={errors} values={values} onContinue={continueToStep2}/>
              )}
              {currentStep === STEP_DETAILS && (
                <Details
                  values={values}
                  handleSubmit={handleSubmit}
                  canSubmit={isValid}
                  submissionError={submissionError}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />
              )}
              {currentStep === STEP_CONFIRMATION && <Confirmation pin={pin}/>}
            </Form>
          );
        }}
      </Formik>
      <Footer/>
    </Page>
  );
};

export default QuarantineUnitProfiling;
