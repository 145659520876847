import styled from 'styled-components';
import theme, { pxToEms } from '../theme';

const ErrorMessage = styled.span`
  display: block;
  color: ${theme.colors.alert};
  font-size: ${pxToEms(12)};
  line-height: ${pxToEms(19)};
  height: ${pxToEms(19)};
  margin-top: ${theme.spacing(1)};
`;

export default ErrorMessage;
