import Page, { PageCenteredContent } from '../../components/Page/index';
import theme, { pxToEms } from '../../theme';

import { Box } from 'reflexbox/styled-components';
import ConfirmationMessage from '../../components/ConfirmationMessage';
import React from 'react';
import styled from 'styled-components';

const PinContainer = styled.div`
  margin: ${theme.spacing(4)};
  background: ${theme.colors.coreTurquoise};
  border-radius: 7px;
  padding: ${theme.spacing(2)} 0;
`;

const StyledBox = styled(Box)`
  max-width:30em;
  margin:0 auto;
  padding: 1em;
`;

const Pin = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${pxToEms(40)};
  color: ${theme.colors.white};
  text-align: center;
  letter-spacing: 12px;
`;

const FacilityProfilingConfirmation = ({ pin }) => (
  <Page>
    <PageCenteredContent>
      <StyledBox>
        <ConfirmationMessage data-testid="qu-confirmation-message">
        Thank you for giving The Ministry of Health a status update.
        </ConfirmationMessage>
        <ConfirmationMessage>
        Your cooperation will allow us to address issues and allocate resource to facilities quickly during the coronavirus pandemic.</ConfirmationMessage>
        <ConfirmationMessage>
        You can access tomorrow's report using this PIN:
        </ConfirmationMessage>

        <PinContainer>
          <Pin data-testid="qu-pin">{pin}</Pin>
        </PinContainer>

      </StyledBox>
    </PageCenteredContent>
  </Page>
);

export default FacilityProfilingConfirmation;
