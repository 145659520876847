import React from 'react';
import { Field, FieldArray, getIn } from 'formik';
import { Box } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components';
import theme from '../../theme';
import { AddButton, DeleteButton } from '../Button/Icon';
import Label from '../Label';
import InputText from './Text';
import InputPhone from './Phone';
import InputGroup from './Group';

const ElementContainer = styled(Box)`
  padding: ${(props) => theme.spacing(props.numInputs === 1 ? 1 : 3)} 0;

  ${({ numInputs }) =>
    numInputs > 1 &&
    css`
      border-top: 1px solid ${theme.colors.borderGrey};
    `}
`;

const InputArray = ({ label, elements = [], name, inputs, addEnabled = true, buttonLabel }) => {
  const numInputs = inputs.length;

  return (
    <InputGroup>
      {label && <Label htmlFor={name} label={label} />}
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <Box>
            {elements.map((el, elIdx) => {
              return (
                <ElementContainer key={`element-${elIdx}_${numInputs}`} numInputs={numInputs}>
                  {inputs.map(({ id, label: labels, ...inputProps }, inputIdx) => {
                    const fieldKey = `${elIdx}${id ? `.${id}` : ''}`;
                    const inputElementName = `${name}.${fieldKey}`;
                    const inputElementClassName = `${inputElementName}`.replace(/\./gi, '-');
                    let inputLabel = '';
                    if (typeof labels !== 'string') {
                      // it's an array
                      // 0 = first, 1 = others
                      if (elIdx === 0) {
                        inputLabel = labels[0];
                      } else {
                        inputLabel = labels[1] || labels[0];
                      }
                    } else {
                      // it's a string (hopefully!)
                      inputLabel = labels;
                    }

                    return (
                      <Field
                        key={`elIdx_${numInputs}_${inputIdx}`}
                        label={inputLabel}
                        id={inputElementName}
                        name={inputElementName}
                        className={inputElementClassName}
                        component={inputProps.type === 'phone' ? InputPhone : InputText}
                        actions={
                          inputIdx === 0 &&
                          // showing delete button on last item only:
                          // if trying to remove non-last item,
                          // form value will update, but react element key does not change
                          // and component does not re-render, showing previous value
                          elIdx === elements.length - 1 &&
                          elIdx > 0 && <DeleteButton onClick={() => remove(elIdx)} />
                        }
                        {...inputProps}
                        value={getIn(elements, fieldKey)}
                      />
                    );
                  })}
                </ElementContainer>
              );
            })}
            {addEnabled && (
              <Box mt={elements.length === 0 ? theme.spacing(3) : 0}>
                <AddButton
                  label={buttonLabel}
                  onClick={() => {
                    push({});
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      />
    </InputGroup>
  );
};

export default InputArray;
