import {
  CURRENT_SITUATION,
  FIELD_ANYTHING_TO_REPORT,
  FIELD_DEATHS_CONFIRMED_CORONAVIRUS,
  FIELD_DEATHS_SUSPECTED_CORONAVIRUS,
  FIELD_DEATHS_TOTAL,
  FIELD_EMERGENCY_TROLLEY_LEVEL,
  FIELD_ESSENTIAL_MEDS_STOCK,
  FIELD_FACILITY_WAS_OPEN,
  FIELD_NUM_BEDS_AVAILABLE,
  FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN,
  FIELD_NUM_CORONAVIRUS_RECOVERIES,
  FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE,
  FIELD_NUM_ICU_BEDS_AVAILABLE,
  FIELD_NUM_PATIENTS_SEEN,
  FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION,
  FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN,
  FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS,
  FIELD_OPERATIONAL_THERMAL_GUN,
  FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  FIELD_SUPPLY_CORONAVIRUS_TESTS,
  FIELD_SUPPLY_STERILISATION,
  FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS,
  FIELD_TRACED_CONTACTS,
  OPTIONS_PPE,
  OPTIONS_STERILISING_EQUIPMENT,
  OPTIONS_STOCK,
  PAST_DAY,
  FIELD_FACILITY_TYPE,
} from '../../schema/constants';
import { Field, Form, Formik, getIn, setIn } from 'formik';
import { Persister, useHydration } from '../../components/Persistence/index';
import React, { useEffect, useState } from 'react';

import InputCheckboxes from '../../components/Input/Checkboxes';
import InputRadio from '../../components/Input/Radio';
import InputText from '../../components/Input/Text';
import Page from '../../components/Page/index';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import api from '../../api/index';
import dayjs from 'dayjs';
import { isDeveloperMode } from '../../utils/debug';
import schema from '../../schema/validation/dailyReport';
import { scrollToTopOfPage } from '../../helpers/index';
import { yesOrNoOptions } from '../../helpers/inputs';

const DailyGeneralUpdate = ({ accessToken, facilityId, onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');
  useEffect(() => {
    scrollToTopOfPage();
  }, []);
  const [sessionStorageKey] = useState(`dr-${dayjs().format('YYYY-MM-DD')}`);
  const initialValues = useHydration(sessionStorageKey, {
    facilityId,
    accessToken,
    [FIELD_FACILITY_TYPE]: 'facility',
  });

  const onSubmit = async (values) => {
    values = setCovidDeathsTo0If0Deaths(values);
    try {
      setSubmissionError('');
      await api.post(process.env.REACT_APP_DAILY_REPORT_SERVICE_URL, values);
      sessionStorage.removeItem(sessionStorageKey);
      onSuccess();
    } catch (error) {
      console.error(error);
      setSubmissionError('Something went wrong');
    }
  };

  const setCovidDeathsTo0If0Deaths = (values) => {
    if (getIn(values, `${PAST_DAY}.${FIELD_DEATHS_TOTAL}`) === 0) {
      values = setIn(values, `${PAST_DAY}.${FIELD_DEATHS_CONFIRMED_CORONAVIRUS}`, 0);
      values = setIn(values, `${PAST_DAY}.${FIELD_DEATHS_SUSPECTED_CORONAVIRUS}`, 0);
    }
    return values;
  };

  return (
    <Page>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ isValid, isSubmitting, handleSubmit, values, errors }) => (
          <Form>
            <Persister name={sessionStorageKey} ignoredKeys={['facilityId', 'accessToken']} />
            <Section title="Capacity" />
            <SectionContent>
              <Field
                label="Was your facility open today?"
                name={`${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`}
                className={`fa-${FIELD_FACILITY_WAS_OPEN}`}
                component={InputRadio}
                options={yesOrNoOptions}
                value={getIn(values, `${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`)}
              />
              {getIn(values, `${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`) !== false && (
                <>
                  <Field
                    label="How many health workers were unable to attend their shift today? Please give best estimate."
                    name={`${CURRENT_SITUATION}.${FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE}`}
                    className={`fa-${FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE}`,
                    )}
                  />
                  <Field
                    label="How many patients have you seen in the last 24 hours? Please give best estimate."
                    name={`${PAST_DAY}.${FIELD_NUM_PATIENTS_SEEN}`}
                    className={`fa-${FIELD_NUM_PATIENTS_SEEN}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_NUM_PATIENTS_SEEN}`)}
                  />
                  <Field
                    label="How many beds in your facility are available right now?"
                    name={`${CURRENT_SITUATION}.${FIELD_NUM_BEDS_AVAILABLE}`}
                    className={`fa-${FIELD_NUM_BEDS_AVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${CURRENT_SITUATION}.${FIELD_NUM_BEDS_AVAILABLE}`)}
                  />
                  <Field
                    label="How many ICU beds (with ventilators) are available at your facility right now?"
                    name={`${CURRENT_SITUATION}.${FIELD_NUM_ICU_BEDS_AVAILABLE}`}
                    className={`fa-${FIELD_NUM_ICU_BEDS_AVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${CURRENT_SITUATION}.${FIELD_NUM_ICU_BEDS_AVAILABLE}`)}
                  />
                  <Field
                    label="How many contacts have been identified from confirmed coronavirus patients today?"
                    name={`${PAST_DAY}.${FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS}`}
                    className={`fa-${FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS}`)}
                  />

                  <Field
                    label="Of the known contacts, how many have been traced today?"
                    name={`${PAST_DAY}.${FIELD_TRACED_CONTACTS}`}
                    className={`fa-${FIELD_TRACED_CONTACTS}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_TRACED_CONTACTS}`)}
                  />
                </>
              )}
            </SectionContent>
            {getIn(values, `${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`) !== false && (
              <>
                <Section title="COVID-19" />
                <SectionContent>
                  <Field
                    label="How many people presented at your facility today with suspected coronavirus?"
                    name={`${PAST_DAY}.${FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN}`}
                    className={`fa-${FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN}`)}
                  />
                  <Field
                    label="How many people presented at your facility today with suspected coronavirus had underlying health conditions?"
                    name={`${PAST_DAY}.${FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS}`}
                    className={`fa-${FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${PAST_DAY}.${FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS}`,
                    )}
                  />
                  <Field
                    label="How many new cases of coronavirus were confirmed at your facility today?"
                    name={`${PAST_DAY}.${FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN}`}
                    className={`fa-${FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN}`)}
                  />
                  <Field
                    label="How many coronavirus recoveries were there at your facility today? "
                    name={`${PAST_DAY}.${FIELD_NUM_CORONAVIRUS_RECOVERIES}`}
                    className={`fa-${FIELD_NUM_CORONAVIRUS_RECOVERIES}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_NUM_CORONAVIRUS_RECOVERIES}`)}
                  />
                  <Field
                    label=" How many people with suspected coronavirus were released from isolation today? "
                    name={`${PAST_DAY}.${FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION}`}
                    className={`fa-${FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${PAST_DAY}.${FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION}`,
                    )}
                  />
                  <Field
                    label="Do you currently have a 7 day supply of the following PPE? Please check available items."
                    name={FIELD_SEVEN_DAY_SUPPLY_OF_PPE}
                    className={`fa-${FIELD_SEVEN_DAY_SUPPLY_OF_PPE}`}
                    component={InputCheckboxes}
                    boxes={OPTIONS_PPE}
                    value={values[FIELD_SEVEN_DAY_SUPPLY_OF_PPE]}
                  />
                  <Field
                    label="Do you currently have a 7 day supply of following sterilisation commodities? Please check available items."
                    name={FIELD_SUPPLY_STERILISATION}
                    className={`fa-${FIELD_SUPPLY_STERILISATION}`}
                    component={InputCheckboxes}
                    boxes={OPTIONS_STERILISING_EQUIPMENT}
                    value={values[FIELD_SUPPLY_STERILISATION]}
                  />
                  <Field
                    label="Do you currently have a 7 day supply of viral transport mediums (VTMs)? "
                    name={FIELD_SUPPLY_CORONAVIRUS_TESTS}
                    className={`fa-${FIELD_SUPPLY_CORONAVIRUS_TESTS}`}
                    component={InputRadio}
                    options={yesOrNoOptions}
                    value={getIn(values, FIELD_SUPPLY_CORONAVIRUS_TESTS)}
                  />
                  <Field
                    label="Do you have an operational thermal-gun? "
                    name={`${CURRENT_SITUATION}.${FIELD_OPERATIONAL_THERMAL_GUN}`}
                    className={`fa-${FIELD_OPERATIONAL_THERMAL_GUN}`}
                    component={InputRadio}
                    options={yesOrNoOptions}
                    value={getIn(values, `${CURRENT_SITUATION}.${FIELD_OPERATIONAL_THERMAL_GUN}`)}
                  />
                </SectionContent>
              </>
            )}
            <Section title="Deaths" />
            <SectionContent>
              <Field
                label="How many total deaths have there been at your facility in the last 24 hours?"
                name={`${PAST_DAY}.${FIELD_DEATHS_TOTAL}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                className={`fa-${PAST_DAY}-${FIELD_DEATHS_TOTAL}`}
                value={getIn(values, `${PAST_DAY}.${FIELD_DEATHS_TOTAL}`)}
              />
              {getIn(values, `${PAST_DAY}.${FIELD_DEATHS_TOTAL}`) > 0 && (
                <>
                  <Field
                    label="Of these, how many had suspected coronavirus?"
                    name={`${PAST_DAY}.${FIELD_DEATHS_SUSPECTED_CORONAVIRUS}`}
                    className={`fa-${FIELD_DEATHS_SUSPECTED_CORONAVIRUS}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_DEATHS_SUSPECTED_CORONAVIRUS}`)}
                  />
                  <Field
                    label="Of these, how many had confirmed coronavirus?"
                    name={`${PAST_DAY}.${FIELD_DEATHS_CONFIRMED_CORONAVIRUS}`}
                    className={`fa-${FIELD_DEATHS_CONFIRMED_CORONAVIRUS}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${PAST_DAY}.${FIELD_DEATHS_CONFIRMED_CORONAVIRUS}`)}
                  />
                </>
              )}
            </SectionContent>
            <Section title="Medication Stock Levels" />
            <SectionContent>
              <Field
                label="Indicate your emergency trolley / tray stock level:"
                name={`${CURRENT_SITUATION}.${FIELD_EMERGENCY_TROLLEY_LEVEL}`}
                component={InputRadio}
                options={OPTIONS_STOCK}
                className={`fa-${CURRENT_SITUATION}-${FIELD_EMERGENCY_TROLLEY_LEVEL}`}
                value={getIn(values, `${CURRENT_SITUATION}.${FIELD_EMERGENCY_TROLLEY_LEVEL}`)}
              />
              <Field
                label="Indicate your essential medicines stock level:"
                name={`${CURRENT_SITUATION}.${FIELD_ESSENTIAL_MEDS_STOCK}`}
                component={InputRadio}
                options={OPTIONS_STOCK}
                className={`fa-${CURRENT_SITUATION}-${FIELD_ESSENTIAL_MEDS_STOCK}`}
                value={getIn(values, `${CURRENT_SITUATION}.${FIELD_ESSENTIAL_MEDS_STOCK}`)}
              />
            </SectionContent>
            <Section title="Other" />
            <SectionContent>
              <Field
                label="Please add any comments, concerns or questions."
                name={FIELD_ANYTHING_TO_REPORT}
                component={InputText}
                asTextArea
                rows={4}
                placeholder="300 characters (maximum)"
                value={values[FIELD_ANYTHING_TO_REPORT]}
              />
              {isDeveloperMode && (
                <>
                  <p>Developer mode enabled</p>
                  <p>{JSON.stringify(values)}</p>
                  <p>{JSON.stringify(errors)}</p>
                </>
              )}
              <Submission
                text={isSubmitting ? 'Submitting...' : 'Submit'}
                canSubmit={isValid}
                handleSubmit={handleSubmit}
                error={submissionError}
                isSubmitting={isSubmitting}
              />
            </SectionContent>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default DailyGeneralUpdate;
