import React from 'react';
import Page from '../../components/Page/index';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';

const SessionExpired = () => {
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Page>
      <Section title="Session expired" />
      <SectionContent>
        <p>
          Your session has expired. Please refresh the page or{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={refreshPage}>click here</a> to retry.
        </p>
      </SectionContent>
    </Page>
  );
};

export default SessionExpired;
