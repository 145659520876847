import smoothscroll from 'smoothscroll-polyfill';
import dayjs from 'dayjs';

try{
  smoothscroll.polyfill();
}catch(e){
  console.error('smoothscroll polyfill error: ', e);
}

export const scrollToTopOfPage = () => {
  if (global.window) {
    try{
      window.scrollTo(0, 0);
    } catch(e) {
      window.document.body.scrollTop = 0;
      window.document.body.scrollLeft = 0;
    }
  }
};


export const scrollToFooter = () => {
  if (global.window) {
    try{
      window.scrollTo(0, document.body.scrollHeight);
    } catch(e) {
      window.document.body.scrollTop = document.body.scrollHeight;
      window.document.body.scrollLeft = 0;
    }
  }
};


export const generatePersistenceKey = prefix => {
  return `${prefix}-${dayjs().format('YYYY-MM-DD')}`;
};