// health centers
export const QUARANTINE_CENTER = 'quarantine';
export const ISOLATION_CENTER = 'isolation';
export const COMMUNITY_HEALTH_CENTER = 'community';

// SERVICES AVAILABLE

export const FIELD_SERVICES_AVAILABLE = 'servicesAvailableAtFacility';
export const HIV = 'HIV';
export const TUBERCOLOSIS = 'TB';
export const MATERNITY = 'maternity';
export const FIELD_MATERNAL_DELIVERIES = 'maternalDeliveries';
export const FIELD_COMMUNITY_HEALTH_UNIT = 'communityHealthUnit';

// CONDITIONS
export const HYPERTENSION = 'hypertension';
export const DIABETES = 'diabetes';
export const OPTIONS_CONDITIONS = [
  { id: HIV, label: 'HIV' },
  { id: TUBERCOLOSIS, label: 'TB' },
  { id: HYPERTENSION, label: 'Hypertension' },
  { id: DIABETES, label: 'Diabetes' },
];

// PPE (EQUIPMENT)
export const GOGGLES = 'goggles';
export const DISPOSABLE_APRONS = 'disposableAprons';
export const MASKS = 'masks';
export const GLOVES = 'gloves';
export const OVERALLS = 'overalls';
export const GUMBOOTS = 'gumboots';
export const OPTIONS_PPE = [
  { id: GOGGLES, label: 'Goggles' },
  { id: MASKS, label: 'Masks' },
  { id: OVERALLS, label: 'Overall' },
  { id: DISPOSABLE_APRONS, label: 'Disposable aprons' },
  { id: GLOVES, label: 'Gloves' },
  { id: GUMBOOTS, label: 'Gumboots' },
];

// Sterilising equipment
export const HAND_SANITIZER = 'handSanitiser';
export const LIQUID_SOAP = 'liquidSoap';
export const CHLORINE = 'chlorineForDisinfection';
export const DECONTAMINATION_SPRAYS = 'spraysForDecontamination';

export const OPTIONS_STERILISING_EQUIPMENT = [
  { id: HAND_SANITIZER, label: 'Hand sanitiser' },
  { id: LIQUID_SOAP, label: 'Liquid soap' },
  { id: CHLORINE, label: 'Disinfection chlorine' },
  { id: DECONTAMINATION_SPRAYS, label: 'Decontamination spray' },
];
// Profiling
export const FIELD_FACILITY_ID = 'facilityId';
export const FIELD_FACILITY_TYPE = 'facilityType';
export const FIELD_IN_CHARGE_MEMBERS = 'inChargeMembers';

// Health workers
export const HEALTH_WORKERS = 'healthWorkers';
export const FIELD_NUMBER_HW_MEDICAL = 'numberOfMedicalOfficers';
export const FIELD_NUMBER_HW_CLINICAL = 'numberOfClinicalOfficers';
export const FIELD_NUMBER_HW_NURSE = 'numberOfNurses';
export const FIELD_NUMBER_HW_LABTECH = 'numberOfLabTechnicians';
export const FIELD_NUMBER_HW_OTHER_TECHNICAL = 'numberOfOtherTechnicalStaff';
export const FIELD_NUMBER_HW_NON_TECHNICAL = 'numberOfOtherNonTechnicalStaff';
export const FIELD_NUMBER_HW_HEALTH_ASSISTANTS = 'hasCommunityHealthAssistants';
export const FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF = 'numberOfTrainedCoronavirusStaff';

// FACILITY DETAILS
export const FACILITY_DETAILS = 'facilityDetails';
export const FIELD_CATCHMENT_POPULATION = 'catchmentPopulation';
export const FIELD_AVERAGE_DAILY_PATIENTS = 'averageDailyPatients';
export const FIELD_NUMBER_BEDS = 'numberOfBeds';
export const FIELD_NUMBER_ICU_BEDS = 'numberOfICUBeds';
export const FIELD_NUMBER_CORONAVIRUS_BEDS = 'numberOfCovid19Beds';

// COVID
export const COVID = 'covid19';
export const FIELD_COVID_HOLDING_AREA = 'holdingArea';
export const FIELD_ISOLATION_FACILITY = 'isolationFacility';
export const FIELD_QUARANTINE_FACILITY = 'quarantineFacility';
export const FIELD_DOES_COVID_TESTING = 'doesCovidTesting';
export const FIELD_OPERATIONAL_THERMAL_GUN = 'hasOperationalThermalGun';

export const FIELD_PPE = 'personalProtectiveEquipmentInStock';
export const FIELD_STERILISING_EQUIPMENT = 'steralisingEquipmentInStock';
export const FIELD_COVID_TEST_KITS = 'coronaVirusTestingKitsInStock';

export const FIELD_DATE_LAST_KEMSA_DELIVERY = 'dateOfLastDeliveryFromKEMSA';

// MEDICATION
export const FIELD_ESSENTIAL_MEDS = 'essentialMedicationsOutOfStock';
export const FIELD_EMERGENCY_TRAY_MISSING_ITEMS = 'emergencyTrayMissingItems';
export const FIELD_MEDS_OUT_OF_STOCK = 'medicationsOutOfStockWithinThreeMonths';
export const FIELD_DATE_LAST_DELIVERY = 'dateOfLastDeliveryFromKEMSA';

// DAILY REPORT
export const FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE = 'numHealthWorkersUnavailable';
export const CURRENT_SITUATION = 'currentSituation';
export const FIELD_NUM_BEDS_AVAILABLE = 'numBedsAvailable';
export const FIELD_NUM_ICU_BEDS_AVAILABLE = 'numICUBedsAvailable';

export const PAST_DAY = 'past24Hours';
export const FIELD_SUPPLY_OF_ALL_ESSENTIAL_MEDS = 'sevenDaySupplyOfAllEssentialMeds';
export const FIELD_EMERGENCY_TROLLEY_LEVEL = 'emergencyTrolleyLevel';
export const FIELD_ESSENTIAL_MEDS_STOCK = 'essentialMedicinesStock';
export const OPTIONS_SUPPLY_OF_ALL_ESSENTIAL_MEDS = [
  { id: HIV, label: 'HIV' },
  { id: TUBERCOLOSIS, label: 'TB' },
  { id: HYPERTENSION, label: 'Hypertension' },
  { id: DIABETES, label: 'Diabetes' },
];

export const FIELD_SUPPLY_STERILISATION = 'essentialSterilisationEquipmentInStock';
export const FIELD_NUM_PATIENTS_SEEN = 'numPatientsSeen';
export const FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN = 'numSuspectedCoronavirusPatientsSeen';
export const FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS =
  'numSuspectedCoronavirusWithUnderlyingConditions';
export const FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN = 'numConfirmedCoronavirusPatientsSeen';
export const FIELD_NUM_CORONAVIRUS_RECOVERIES = 'numCoronavirusRecoveries';
export const FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION =
  'numCoronavirusReleasedFromIsolation';
export const FIELD_SEVEN_DAY_SUPPLY_OF_PPE = 'sevenDaySupplyOfPPE';
export const FIELD_ANYTHING_TO_REPORT = 'anythingToReport';

export const ERROR_MESSAGE_REQUIRED = 'This field is required';

export const FIELD_SUPPLY_CORONAVIRUS_TESTS = 'coronavirusTestsInStock';

export const FIELD_DEATHS_TOTAL = 'totalDeathCount';
export const FIELD_DEATHS_SUSPECTED_CORONAVIRUS = 'suspectedCoronavirusDeathCount';
export const FIELD_DEATHS_CONFIRMED_CORONAVIRUS = 'confirmedCoronavirusDeathCount';

export const FIELD_FACILITY_WAS_OPEN = 'facilityWasOpen';

export const FIELD_EMERGENCY_TROLLEY_OUT_OF_STOCK = 'emergencyTrolleyOutOfStock';

export const FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS = 'totalContactsFromCoronavirusPatients';

export const FIELD_TRACED_CONTACTS = 'contactsTraced';

export const FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE = 'confirmedCoronavirusCasesToDate';
export const FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE = 'recoveredCoronavirusCasesToDate';
export const FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE = 'confirmedCoronavirusDeathsToDate';

export const OUT_OF_STOCK = 'outOfStock';
export const SEVEN_DAYS_STOCK = 'sevenDaysStock';
export const GREATER_THAN_SEVEN_DAYS_STOCK = 'greaterThanSevenDaysStock';

export const OPTIONS_STOCK = [
  { label: 'Item(s) out of stock', value: OUT_OF_STOCK },
  { label: 'Less than 7 day supply', value: SEVEN_DAYS_STOCK },
  { label: 'Greater than 7 day supply', value: GREATER_THAN_SEVEN_DAYS_STOCK },
];

export const CS_CALLBACK_NAME = 'name';
export const CS_CALLBACK_PHONE = 'phone';
export const CS_CALLBACK_MESSAGE = 'message';
export const CS_CALLBACK_COUNTY = 'county';


// DAILY_REPORT_QUARANTINE
export const QU_PERSIST_DAILY_PREFIX = 'qud';
// CAPACITY
export const QH_NUMBER_STAFF_UNAVAILABLE = 'quarantineStaffUnavailable';
export const QU_NUMBER_HW_MEDICAL_UNAVAILABLE = 'numberOfQuarantineUnitMedAndTechStaffUnavailable';
export const QU_NUMBER_HW_SUPPORT_UNAVAILABLE = 'numberOfQuarantineUnitSupportStaffUnavailable';
export const QU_NUMBER_HW_POLICE_UNAVAILABLE = 'numberOfQuarantineUnitPoliceUnavailable';
export const QU_NUMBER_HW_KITCHEN_UNAVAILABLE = 'numberOfQuarantineUnitKitchenStaffUnavailable';
export const QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE =
  'numberOfQuarantineUnitEnforcementStaffUnavailable';
export const QU_NUMBER_HW_OTHER_UNAVAILABLE = 'numberOfQuarantineUnitOtherStaffUnavailable';

// GUESTS
export const GUESTS = 'guests';
export const FIELD_NUM_GUESTS = 'numGuests';
export const FIELD_NUM_GUESTS_PAST_24HOURS = 'numGuestsPast24Hours';
export const FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS = 'numGuestsUnderlyingConditions';
export const FIELD_NUM_GUESTS_PREGNANT = 'numGuestsPregnant';
export const FIELD_NUM_MALE_GUESTS = 'numMaleGuests';
export const FIELD_NUM_FEMALE_GUESTS = 'numFemaleGuests';

// COVID-19 Test
export const FIELD_NUM_RETURNED_TESTS = 'numReturnedTests';
export const FIELD_NUM_POSITIVE_TESTS = 'numPositiveTests';
export const FIELD_NUM_NEGATIVE_TESTS = 'numNegativeTests';
export const FIELD_PENDING_COVID_TESTS = 'numPendingCovidTests';
export const FIELD_NUM_TESTED_GUESTS = 'numTestedGuests';

// GUESTS TRANSFERRED
export const FIELD_NUM_POSITIVE_TRANSFERED = 'numPositiveTransferred';
export const FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED =
  'numWithUnderlyingConditionsTransferred';
export const FIELD_NUM_COVID_POSITIVE_WITH_UNDERLYING_CONDITIONS_TRANSFERRED =
  'numCovidPositiveWithUnderlyingConditionsTransferred';
export const FIELD_NUM_DISCHARGED_COVID_NEGATIVE = 'numDischargedWithCovidNegative';

// STOCK AVAILABILITY
export const QUARANTINE_CENTER_STOCK = 'quarantineCenterStock';

export const COVID19_TESTING_KITS = 'covid19TestingKits';
export const VIRAL_TRANSPORT_MEDIA = 'viralTransportMedia';
export const ESSENTIAL_MEDICATION = 'essentialMedication';
export const EMERGENCY_TROLLEY_ITEMS = 'emergencyTrolleyItems';
export const FOOD_FOR_GUESTS = 'foodForGuests';
export const FUEL = 'fuel';

export const OPTIONS_QUARANTINE_CENTER_STOCK = [
  { id: COVID19_TESTING_KITS, label: 'COVID-19 testing kits' },
  { id: VIRAL_TRANSPORT_MEDIA, label: 'Viral Transport Media' },
  { id: ESSENTIAL_MEDICATION, label: 'Essential medication' },
  { id: EMERGENCY_TROLLEY_ITEMS, label: 'Emergency trolley items' },
  { id: FOOD_FOR_GUESTS, label: 'Food for guests' },
  { id: FUEL, label: 'Fuel (cooking gas, generator fuel etc)' },
];
export const QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE = 'quarantineSevenDaySupplyOfPPE';
export const QUARANTINE_FIELD_SUPPLY_STERILISATION =
  'quarantineEssentialSterilisationEquipmentInStock';
export const QUARANTINE_FIELD_ANYTHING_TO_REPORT = 'quarantineAnythingToReport';

// QUARANTINE UNIT PROFILE

export const QU_PERSIST_PROFILE_PREFIX = 'qu';
export const QU_FACILITY_NAME = 'facilityName';
//WORKERS
export const QU_WORKERS = 'quarantineUnitWorkers';
export const QU_NUMBER_MEDICAL = 'numberOfMedAndTechStaff';
export const QU_NUMBER_SUPPORT = 'numberOfSupportStaff';
export const QU_NUMBER_POLICE = 'numberOfPolice';
export const QU_NUMBER_KITCHEN = 'numberOfKitchenStaff';
export const QU_NUMBER_ENFORCEMENT = 'numberOfEnforcementStaff';
export const QU_NUMBER_OTHER = 'numberOfOtherStaff';
// FACILITY
export const QU_FACILITY_DETAILS = 'facilityDetails';
export const QU_NUMBER_BEDS = 'numberOfBeds';
// COVID STATS
export const QU_COVID = 'quarantineCovid';
export const QU_NUMBER_C19_TESTED_TO_DATE = 'numberOfCOVIDTestedToDate';
export const QU_NUMBER_C19_CONFIRMED_TO_DATE = 'numberOfCOVIDConfirmedToDate';
export const QU_NUMBER_MALES_UNDER5 = 'numberOfQuarantinedMalesUnder5';
export const QU_NUMBER_MALES_6TO17 = 'numberOfQuarantinedMalesAged6to17';
export const QU_NUMBER_MALES_18TO59 = 'numberOfQuarantinedMalesAged18to59';
export const QU_NUMBER_MALES_60PLUS = 'numberOfQuarantinedMalesAged60plus';
export const QU_NUMBER_FEMALES_UNDER5 = 'numberOfQuarantinedFemalesUnder5';
export const QU_NUMBER_FEMALES_6TO17 = 'numberOfQuarantinedFemalesAged6to17';
export const QU_NUMBER_FEMALES_18TO59 = 'numberOfQuarantinedFemalesAged18to59';
export const QU_NUMBER_FEMALES_60PLUS = 'numberOfQuarantinedFemalesAged60plus';

// DAILY_REPORT_ISOLATION
export const ISO_PERSIST_DAILY_PREFIX = 'iso';
// STAFF AND CAPACITY
export const ISO_NUMBER_STAFF_UNAVAILABLE = 'isolationUnitStaffUnavailable';
export const ISO_NUMBER_MED_STAFF_UNAVAILABLE = 'numberOfIsolationUnitMedStaffUnavailable';
export const ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE =
  'numberOfIsolationUnitClinicalStaffUnavailable';
export const ISO_NUMBER_NURSES_UNAVAILABLE = 'numberOfIsolationUnitNursesUnavailable';
export const ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE =
  'numberOfIsolationUnitLabTechniciansUnavailable';
export const ISO_NUMBER_OTHER_STAFF_UNAVAILABLE = 'numberOfIsolationUnitOtherStaffUnavailable';
export const ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE = 'numberOfIsolationNonTechStaffUnavailable';
export const ISO_NUMBER_BEDS_AVAILABLE = 'numberOfIsolationUnitBedsAvailable';
export const ISO_NUMBER_ICU_BEDS_AVAILABLE = 'numberOfIsolationUnitICUBedsAvailable';
// ADMISSIONS
export const PATIENTS = 'patients';
export const ISO_NUMBER_PATIENTS = 'numPatients';
export const ISO_PAST_DAY_PATIENTS = 'numPatientsPast24Hours';
export const ISO_NUMBER_MALES_UNDER5 = 'numberOfMalePatientsUnder5';
export const ISO_NUMBER_MALES_6TO17 = 'numberOfMalePatientsAged6to17';
export const ISO_NUMBER_MALES_18TO59 = 'numberOfMalePatientsAged18to59';
export const ISO_NUMBER_MALES_60PLUS = 'numberOfMalePatientsAged60plus';
export const ISO_NUMBER_FEMALES_UNDER5 = 'numberOfFemalePatientsUnder5';
export const ISO_NUMBER_FEMALES_6TO17 = 'numberOfFemalePatientsAged6to17';
export const ISO_NUMBER_FEMALES_18TO59 = 'numberOfFemalePatientsAged18to59';
export const ISO_NUMBER_FEMALES_60PLUS = 'numberOfFemalePatientsAged60plus';
export const ISO_NUMBER_UNDERLYING_CONDITIONS = 'numPatientsUnderlyingConditions';
// PATIENT STATUS
export const ISO_NUMBER_CRITICAL = 'numCriticalPatients';
export const ISO_NUMBER_MODERATE = 'numModeratePatients';
export const ISO_NUMBER_STABLE = 'numStablePatients';
// COVID-19 TEST RESULTS
export const ISO_NUMBER_TESTS = 'numTests';
export const ISO_NUMBER_POSITIVE = 'numPositiveTests';
export const ISO_NUMBER_NEGATIVE = 'numNegativeTests';
// DISCHARGED AND DEATHS
export const ISO_NUMBER_DISCHARGED = 'numDischarged';
export const ISO_NUMBER_DEATHS = 'numDeaths';
// CONTACTS
export const ISO_NUMBER_COLLECTED_CONTACTS = 'numCollectedContacts';
export const ISO_NUMBER_TRACED_CONTACTS = 'numTracedContacts';
// STOCK AVAILABILITY
export const ISO_UNIT_STOCK = 'isolationUnitStock';
export const ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE = 'isolationUnitSevenDaySupplyOfPPE';
export const ISO_FIELD_SUPPLY_STERILISATION = 'isolationUnitEssentialSterilisationEquipmentInStock';
// MISC
export const ISO_FIELD_ANYTHING_TO_REPORT = 'isolationUnitAnythingToReport';
export const OPTIONS_ISO_UNIT_STOCK = [
  { id: COVID19_TESTING_KITS, label: 'COVID-19 testing kits' },
  { id: VIRAL_TRANSPORT_MEDIA, label: 'Viral Transport Media' },
  { id: ESSENTIAL_MEDICATION, label: 'Essential medication' },
  { id: EMERGENCY_TROLLEY_ITEMS, label: 'Emergency trolley items' },
];

// ISOLATION UNIT PROFILE
export const ISO_PERSIST_PROFILE_PREFIX = 'is';
export const ISO_FACILITY_NAME = 'facilityName';
export const ISO_PROFILE_WORKERS = 'isolationUnitWorkers';
export const ISO_COVID = 'isolationCovid';
// STAFF AND CAPACITY
export const ISO_PROFILE_NUMBER_MED_STAFF = 'numberOfMedStaff';
export const ISO_PROFILE_NUMBER_CLINICAL_STAFF = 'numberOfClinicalStaff';
export const ISO_PROFILE_NUMBER_NURSES = 'numberOfNurses';
export const ISO_PROFILE_NUMBER_LAB_TECHNICIANS = 'numberOfLabTechnicians';
export const ISO_PROFILE_NUMBER_OTHER_STAFF = 'numberOfOtherStaff';
export const ISO_PROFILE_NUMBER_NON_TECH_STAFF = 'numberOfNonTechStaff';
export const ISO_PROFILE_NUMBER_BEDS = 'numberOfBeds';
export const ISO_PROFILE_NUMBER_ICU_BEDS = 'numberOfICUBeds';
export const ISO_PROFILE_NUMBER_COVID = 'numberOfCOVIDPatientsToDate';
// PATIENTS
export const ISO_PROFILE_NUMBER_MALES_UNDER5 = 'numberOfIsolationMalesUnder5';
export const ISO_PROFILE_NUMBER_MALES_6TO17 = 'numberOfIsolationMalesAged6to17';
export const ISO_PROFILE_NUMBER_MALES_18TO59 = 'numberOfIsolationMalesAged18to59';
export const ISO_PROFILE_NUMBER_MALES_60PLUS = 'numberOfIsolationMalesAged60plus';
export const ISO_PROFILE_NUMBER_FEMALES_UNDER5 = 'numberOfIsolationFemalesUnder5';
export const ISO_PROFILE_NUMBER_FEMALES_6TO17 = 'numberOfIsolationFemalesAged6to17';
export const ISO_PROFILE_NUMBER_FEMALES_18TO59 = 'numberOfIsolationFemalesAged18to59';
export const ISO_PROFILE_NUMBER_FEMALES_60PLUS = 'numberOfIsolationFemalesAged60plus';
export const ISO_PROFILE_NUMBER_UNDERLYING = 'numberOfCovidPatientsWithUnderlyingConditions';
export const ISO_PROFILE_NUMBER_TREATED_ICU = 'numberOfCovidPatientsTreatedICUToDate';
export const ISO_PROFILE_NUMBER_TESTED_ICU = 'numberOfCovidPatientsTestedICUToDate';
export const ISO_PROFILE_NUMBER_RECOVERIES = 'recoveries';
export const ISO_PROFILE_NUMBER_DEATHS = 'deaths';
export const ISO_COVID_DEATHS = 'covidDeaths';

// DAILY REPORT COMMUNITY HEALTH UNIT
export const CHU_PERSIST_DAILY_PREFIX = 'ch';
export const CHU_LEVEL_UPTO_30 = 'levelOfComplianceBelow30';
export const CHU_LEVEL_30_TO_70 = 'levelOfCompliance30to70';
export const CHU_LEVEL_ABOVE_70 = 'levelOfComplianceAbove70';
// COMMUNITY
export const CHU_NUMBER_CHU_VISITED = 'numberCHUvisited';
export const CHU_LEVEL_MASK_COMPLIANCE = 'levelOfMasksCompliance';
export const CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE = 'levelOfSocialDistancingCompliance';
export const CHU_LEVEL_HAND_WASHING_COMPLIANCE = 'levelOfHandWashingCompliance';
export const CHU_NUMBER_IEC_MATERIALS = 'numIECmaterials';
export const OPTIONS_CHU_COMPLIANCE = [
  { id: CHU_LEVEL_UPTO_30, label: 'Upto 30%', value: CHU_LEVEL_UPTO_30 },
  { id: CHU_LEVEL_30_TO_70, label: 'Between 30-70%', value: CHU_LEVEL_30_TO_70 },
  { id: CHU_LEVEL_ABOVE_70, label: 'Greater than 70%', value: CHU_LEVEL_ABOVE_70 },
];
// MOVEMENT OF PEOPLE
export const CHU_NUMBER_VISITORS = 'numVisitors';
export const CHU_NUMBER_LEAVING_VISITORS = 'numLeavingVisitors';
// HOUSEHOLDS
export const CHU_NUMBER_HOUSEHOLD_VISITED = 'numberCHVvisited';
export const CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES = 'handWashingFacilities';
export const CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS = 'IECmaterials';
export const CHU_NUMBER_SELF_QUARANTINE_TOTAL = 'numSelfQuarantine';
export const CHU_NUMBER_SELF_QUARANTINE_WEEK = 'numSelfQuarantineThisWeek';
export const CHU_NUMBER_VISITS = 'numVisitedSelfQuarantine';
export const CHU_NUMBER_DEFAULTED_SELF_QUARANTINE = 'numDefaultedSelfQuarantine';
// CONTACT TRACING
export const CHU_NUMBER_SUSPECTED_COVID = 'suspectedCovid';
export const CHU_NUMBER_TRACED_CONTACTS = 'numTracedContacts';
export const CHU_NUMBER_REFERRALS = 'numReferrals';
// HOME BASED ISOLATION AND CARE
export const CHU_PATIENTS_HOME_ISOLATION = 'homeIsolatedCovidPatients';
export const CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE = 'homeIsolatedCovidPatientsWithPhone';
export const CHU_PATIENT_COMMENCED_HOME_ISOLATION = 'covidPatientsCommencedHomeIsolation';
export const CHU_NUMBER_HOUSEHOLDS_TRAINED = 'trainedHouseholds';
export const CHU_PATIENTS_REFERRED_HOSPITAL = 'referredHomeIsolatedCovidPatients';
export const CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS = 'totalHouseholdContacts';
export const CHU_NUMBER_CONTACTS_MONITORED = 'symptomMonitoringHouseholdContacts';
export const CHU_HOUSEHOLDS_ADEQUATE_PPE = 'householdContactsWithAdequatePPE';
export const CHU_PATIENTS_COMPLETED_HOME_ISOLATION = 'covidPatientsWithCompletedHomeIsolation';

export const CHU_PERSIST_PROFILE_PREFIX = 'chu';

export const CHU_LOCATION = 'chuLocation';
export const CHU_SUBLOCATION = 'chuSubLocation';

export const CHU_DETAILS = 'chuDetails';
export const CHU_NUMBER_VOLUNTEERS = 'numCHUVolunteers';
export const CHU_NUMBER_OVERSEEN = 'numberOfOverseenCHU';
export const CHV_NUMBER_OVERSEEN = 'numberOfOverseenCHV';
export const CHU_NUMBER_SENSITIZED_CHV = 'numSensitizedCHVsOnCovid';
export const MCHUL_CODE = 'mCHULCode';
export const FACILITY_LINK = 'facilityLink';
export const CHU_POPULATION = 'numTotalPopulation';
export const CHU_HOUSEHOLDS = 'households';
export const CHU_MARKETS = 'markets';
export const CHU_TOWNS = 'towns';
export const CHU_BODA = 'boda';
export const CHU_TEA = 'tea';
export const NUM_HANDWASHING_POINTS = 'numHandwashingPoints';

export const HOUSEHOLD_INFO = 'householdInfo';
export const NUM_HH_HANDWASHING = 'numHouseholdsWithHandwashing';
export const NUM_PREGNANT = 'numPregnant';
export const NUM_ELDERLY = 'numElderly';
export const NUM_CHRONIC_ILLNESS = 'numChronicIllness';

export const HOME_ISOLATION = 'homeIsolation';
export const COVID_PATIENTS = 'covidPatientsToDate';
export const NUM_TRAINED_HH = 'numTrainedHouseholds';
