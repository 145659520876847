import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import Button from '../../components/Button/index';
import SectionContent from '../../components/Section/Content';
import Section from '../../components/Section/index';
import InputArray from '../../components/Input/Array';
import Label from '../../components/Label';
import FacilityName from '../../components/FacilityName';
import { FIELD_IN_CHARGE_MEMBERS } from '../../schema/constants';

const InCharges = ({ onContinue, errors, values }) => {
  const canContinue = values[FIELD_IN_CHARGE_MEMBERS] && !errors[FIELD_IN_CHARGE_MEMBERS];
  return (
    <Box>
      <Section title="Facility Profiling" />

      <SectionContent>
		<FacilityName/>
        <p>
          The County Ministry of Health has partnered with Elephant Healthcare to help manage the
          coronavirus pandemic by improving outbreak and resource monitoring.
        </p>
        <p>
          In order to understand your facility's needs during this unprecedented time, the Ministry
          of Health requests that your facility complete a short daily questionnaire on your
          facility's capacity, stock and resource levels. Please provide the mobile phone numbers of
          all facility in-charges who should receive the questionnaire. The questionnaire only needs
          to be completed by one in-charge each day.
        </p>
        <p>
          By submitting mobile phone numbers below you confirm that consent is provided for Elephant
          Healthcare to contact each individual and process their data.
        </p>
        <p>Your cooperation during this public health emergency is appreciated.</p>
        <p>Thank you from The Ministry of Health and Elephant Healthcare.</p>
        <Label label="Please add your name and number below, and the contact details of any other in-charges at your facility." />

        <InputArray
          elements={values[FIELD_IN_CHARGE_MEMBERS]}
          name={FIELD_IN_CHARGE_MEMBERS}
          inputs={[
            {
              id: 'name',
              label: ['Your name', 'In-charge name'],
              clearValueOnUnmount: false,
            },
            {
              id: 'mobileNumber',
              label: ['Your mobile number', 'In-charge mobile phone number'],
              type: 'phone',
              placeholder: 'please enter mobile number',
              inputMode: 'tel',
              clearValueOnUnmount: false,
            },
          ]}
          buttonLabel="Add another In-Charge"
        />

        <Flex justifyContent="flex-end" mt={40}>
          <Button type="submit" onClick={onContinue} disabled={!canContinue } data-testid="continue-in-charges">
            Continue
          </Button>
        </Flex>
      </SectionContent>
    </Box>
  );
};

export default InCharges;
