import rg4js from 'raygun4js';

export const debug = (...args) => {
  if (isDeveloperMode) {
    console.debug(...args);
  }
};

export const isDeveloperMode = process.env.REACT_APP_DEVELOPER_MODE === 'true';

export const handleError = (error) => {
  rg4js('send', error);
};
