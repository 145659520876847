import { useMemo } from 'react';
import { handleError } from '../../utils/debug';

export const useHydration = (key, defaultValues = {}) => {
  let fromPersistence = {};
  try {
    if (typeof window.sessionStorage !== 'undefined') {
      fromPersistence = JSON.parse(window.sessionStorage.getItem(key));
    }
  } catch (securityError) {
    console.warn('Unable to read from sessionStorage - Persistence and hydration is disabled');
    handleError(securityError);
  }
  return useMemo(() => ({ ...defaultValues, ...fromPersistence }), [
    defaultValues,
    fromPersistence,
  ]);
};
