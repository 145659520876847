import React from 'react';
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';

export const overrideMuiStyles = AppComponent => {
  return () => (
    <StylesProvider injectFirst>
      <AppComponent />
    </StylesProvider>
  );
};

export const withMuiTheme = AppComponent => {
  return () => (
    <ThemeProvider
      theme={createMuiTheme({
        palette: {
          primary: {
            main: theme.colors.coreTurquoise,
            // TODO the rest
          },
          secondary: {
            main: theme.colors.alert,
          },
        },
      })}
    >
      <AppComponent />
    </ThemeProvider>
  );
};
