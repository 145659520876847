import {
  CURRENT_SITUATION,
  GUESTS,
  QH_NUMBER_STAFF_UNAVAILABLE,
  QU_NUMBER_HW_MEDICAL_UNAVAILABLE,
  QU_NUMBER_HW_SUPPORT_UNAVAILABLE,
  QU_NUMBER_HW_POLICE_UNAVAILABLE,
  QU_NUMBER_HW_KITCHEN_UNAVAILABLE,
  QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE,
  QU_NUMBER_HW_OTHER_UNAVAILABLE,
  FIELD_NUM_BEDS_AVAILABLE,
  FIELD_NUM_GUESTS,
  FIELD_NUM_GUESTS_PAST_24HOURS,
  FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS,
  FIELD_NUM_GUESTS_PREGNANT,
  QU_NUMBER_FEMALES_UNDER5,
  FIELD_NUM_RETURNED_TESTS,
  FIELD_NUM_POSITIVE_TESTS,
  FIELD_NUM_NEGATIVE_TESTS,
  FIELD_PENDING_COVID_TESTS,
  FIELD_NUM_TESTED_GUESTS,
  FIELD_NUM_POSITIVE_TRANSFERED,
  FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED,
  FIELD_NUM_DISCHARGED_COVID_NEGATIVE,
  QUARANTINE_CENTER_STOCK,
  OPTIONS_QUARANTINE_CENTER_STOCK,
  QUARANTINE_FIELD_ANYTHING_TO_REPORT,
  QU_NUMBER_FEMALES_6TO17,
  QU_NUMBER_FEMALES_18TO59,
  QU_NUMBER_FEMALES_60PLUS,
  QU_NUMBER_MALES_UNDER5,
  QU_NUMBER_MALES_6TO17,
  QU_NUMBER_MALES_18TO59,
  QU_NUMBER_MALES_60PLUS,
  QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  QUARANTINE_FIELD_SUPPLY_STERILISATION,
  OPTIONS_PPE,
  OPTIONS_STERILISING_EQUIPMENT,
  QU_PERSIST_DAILY_PREFIX,
  FIELD_FACILITY_TYPE,
} from '../../schema/constants';
import Label from '../../components/Label';

import { Field, Form, Formik, getIn } from 'formik';
import { Persister, useHydration } from '../../components/Persistence/index';
import React, { useEffect, useState } from 'react';

import InputCheckboxes from '../../components/Input/Checkboxes';
import InputRadio from '../../components/Input/Radio';
import InputText from '../../components/Input/Text';
import Page from '../../components/Page/index';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import api from '../../api/index';
import { isDeveloperMode } from '../../utils/debug';
import schema from '../../schema/validation/quarantineUnitDailyReport';
import { scrollToTopOfPage, generatePersistenceKey } from '../../helpers/index';
import { yesOrNoOptions } from '../../helpers/inputs';
import InlineNumber from '../../components/Input/InlineNumber';
import FacilityName from '../../components/FacilityName';

const DailyGeneralUpdate = ({ accessToken, facilityId, onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');
  useEffect(() => {
    scrollToTopOfPage();
  }, []);
  const [sessionStorageKey] = useState(generatePersistenceKey(QU_PERSIST_DAILY_PREFIX));
  const initialValues = useHydration(sessionStorageKey, {
    facilityId,
    accessToken,
    [FIELD_FACILITY_TYPE]: 'quarantine',
  });

  const onSubmit = async (values) => {
    try {
      setSubmissionError('');
      await api.post(process.env.REACT_APP_DAILY_REPORT_SERVICE_URL, values);
      sessionStorage.removeItem(sessionStorageKey);
      onSuccess();
    } catch (error) {
      console.error(error);
      setSubmissionError('Something went wrong');
    }
  };

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnMount={true}
      >
        {({ isValid, isSubmitting, handleSubmit, values, errors }) => (
          <Form>
            <Persister name={sessionStorageKey} ignoredKeys={['facilityId', 'accessToken']} />
			<FacilityName h1/>
            <Section title="Capacity" />
            <SectionContent>
              <Field
                label="Were any staff members unable to attend their shift today?"
                name={`${CURRENT_SITUATION}.${QH_NUMBER_STAFF_UNAVAILABLE}`}
                component={InputRadio}
                options={yesOrNoOptions}
                value={getIn(values, `${CURRENT_SITUATION}.${QH_NUMBER_STAFF_UNAVAILABLE}`)}
                className={`qu-${CURRENT_SITUATION}-${QH_NUMBER_STAFF_UNAVAILABLE}`}
              />
              {getIn(values, `${CURRENT_SITUATION}.${QH_NUMBER_STAFF_UNAVAILABLE}`) && (
                <>
                  <div style={{ marginBottom: '20px' }}>
                    <Label label="For each of these groups, provide the number of staff that were unable to attend their shift." />
                  </div>
                  <Field
                    label="Number of unavailable medical and technical staff"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_MEDICAL_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${QU_NUMBER_HW_MEDICAL_UNAVAILABLE}`,
                    )}
                    className={`qu-${QU_NUMBER_HW_MEDICAL_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable support staff"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_SUPPORT_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${QU_NUMBER_HW_SUPPORT_UNAVAILABLE}`,
                    )}
                    className={`qu-${QU_NUMBER_HW_SUPPORT_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable police officers"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_POLICE_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${CURRENT_SITUATION}.${QU_NUMBER_HW_POLICE_UNAVAILABLE}`)}
                    className={`qu-${QU_NUMBER_HW_POLICE_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable kitchen staff"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_KITCHEN_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${QU_NUMBER_HW_KITCHEN_UNAVAILABLE}`,
                    )}
                    className={`qu-${QU_NUMBER_HW_KITCHEN_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable enforcement officers"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE}`,
                    )}
                    className={`qu-${QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable other staff members"
                    name={`${CURRENT_SITUATION}.${QU_NUMBER_HW_OTHER_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${CURRENT_SITUATION}.${QU_NUMBER_HW_OTHER_UNAVAILABLE}`)}
                    className={`qu-${QU_NUMBER_HW_OTHER_UNAVAILABLE}`}
                  />
                </>
              )}
              <Field
                label="How many beds are available at your facility right now?"
                name={`${CURRENT_SITUATION}.${FIELD_NUM_BEDS_AVAILABLE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${FIELD_NUM_BEDS_AVAILABLE}`)}
                className={`qu-${FIELD_NUM_BEDS_AVAILABLE}`}
              />
            </SectionContent>
            <Section title="Guests" />
            <SectionContent>
              <Field
                label="How many guests do you have staying at your facility right now? "
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS}`)}
                className={`qu-${FIELD_NUM_GUESTS}`}
              />
              <Field
                label="How many guests have been placed in quarantine in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_PAST_24HOURS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_PAST_24HOURS}`,
                )}
                className={`qu-${FIELD_NUM_GUESTS_PAST_24HOURS}`}
              />
              <Field
                label="What is the total number of guests with underlying conditions?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS}`,
                )}
                className={`qu-${FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS}`}
              />
              <Field
                label="How many guests are pregnant?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_PREGNANT}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_GUESTS_PREGNANT}`)}
                className={`qu-${FIELD_NUM_GUESTS_PREGNANT}`}
              />

              <Label label="In the last 24 hours, how many males have been placed in quarantine in the following age ranges?" />

              <Field
                label="Aged 5 and under?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_UNDER5}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_UNDER5}`)}
                className={`qu-${QU_NUMBER_MALES_UNDER5}`}
              />
              <Field
                label="Aged 6 to 17?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_6TO17}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_6TO17}`)}
                className={`qu-${QU_NUMBER_MALES_6TO17}`}
              />
              <Field
                label="Aged 18 to 59?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_18TO59}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_18TO59}`)}
                className={`qu-${QU_NUMBER_MALES_18TO59}`}
              />
              <Field
                label="Aged 60 or over?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_60PLUS}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_MALES_60PLUS}`)}
                className={`qu-${QU_NUMBER_MALES_60PLUS}`}
              />
              <Label label="In the last 24 hours, how many females have been placed in quarantine in the following age ranges?" />
              <Field
                label="Aged 5 and under?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_UNDER5}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_UNDER5}`)}
                className={`qu-${QU_NUMBER_FEMALES_UNDER5}`}
              />
              <Field
                label="Aged 6 to 17?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_6TO17}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_6TO17}`)}
                className={`qu-${QU_NUMBER_FEMALES_6TO17}`}
              />
              <Field
                label="Aged 18 to 59?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_18TO59}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_18TO59}`)}
                className={`qu-${QU_NUMBER_FEMALES_18TO59}`}
              />
              <Field
                label="Aged 60 or over?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_60PLUS}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${QU_NUMBER_FEMALES_60PLUS}`)}
                className={`qu-${QU_NUMBER_FEMALES_60PLUS}`}
              />
            </SectionContent>
            <Section title="COVID-19" />
            <SectionContent>
              <Field
                label="How many test results were returned in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_RETURNED_TESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_RETURNED_TESTS}`)}
                className={`qu-${FIELD_NUM_RETURNED_TESTS}`}
              />
              <Field
                label="How many guests tested positive for COVID-19 in last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_POSITIVE_TESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_POSITIVE_TESTS}`)}
                className={`qu-${FIELD_NUM_POSITIVE_TESTS}`}
              />
              <Field
                label="How many guests tested negative for COVID-19 in last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_NEGATIVE_TESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_NEGATIVE_TESTS}`)}
                className={`qu-${FIELD_NUM_NEGATIVE_TESTS}`}
              />
              <Field
                label="How many COVID-19 test results are you waiting for?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_PENDING_COVID_TESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_PENDING_COVID_TESTS}`)}
                className={`qu-${FIELD_PENDING_COVID_TESTS}`}
              />
              <Field
                label="How many guests have been tested for COVID-19 in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_TESTED_GUESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_TESTED_GUESTS}`)}
                className={`qu-${FIELD_NUM_TESTED_GUESTS}`}
              />
            </SectionContent>

            <Section title="Guests transferred" />
            <SectionContent>
              <Field
                label="How many guests tested positive for COVID-19 and have been transferred out to an isolation facility in last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_POSITIVE_TRANSFERED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_POSITIVE_TRANSFERED}`,
                )}
                className={`qu-${FIELD_NUM_POSITIVE_TRANSFERED}`}
              />
              <Field
                label="Of those transferred out to an isolation facility, how many had underlying conditions?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED}`,
                )}
                className={`qu-${FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED}`}
              />
              <Field
                label="How many guests tested negative for COVID-19 and have been discharged in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_DISCHARGED_COVID_NEGATIVE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${GUESTS}.${FIELD_NUM_DISCHARGED_COVID_NEGATIVE}`,
                )}
                className={`qu-${FIELD_NUM_DISCHARGED_COVID_NEGATIVE}`}
              />
            </SectionContent>

            <Section title="Stock availability" />
            <SectionContent>
              <Field
                label="Please indicate which of the following items you are likely to run out of in the next 7 days:"
                name={QUARANTINE_CENTER_STOCK}
                component={InputCheckboxes}
                boxes={OPTIONS_QUARANTINE_CENTER_STOCK}
                value={values[QUARANTINE_CENTER_STOCK]}
              />

              <Field
                label="PPE"
                name={QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE}
                component={InputCheckboxes}
                boxes={OPTIONS_PPE}
                value={values[QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE]}
              />

              <Field
                label="Sterilisation equipment"
                name={QUARANTINE_FIELD_SUPPLY_STERILISATION}
                component={InputCheckboxes}
                boxes={OPTIONS_STERILISING_EQUIPMENT}
                value={values[QUARANTINE_FIELD_SUPPLY_STERILISATION]}
              />
            </SectionContent>
            <Section title="Other" />
            <SectionContent>
              <Field
                label="Please add any comments, concerns or questions."
                name={QUARANTINE_FIELD_ANYTHING_TO_REPORT}
                component={InputText}
                asTextArea
                rows={4}
                placeholder="300 characters (maximum)"
                value={values[QUARANTINE_FIELD_ANYTHING_TO_REPORT]}
                data-testid={`${QUARANTINE_FIELD_ANYTHING_TO_REPORT}`}
              />
              {isDeveloperMode && (
                <>
                  <p>Developer mode enabled</p>
                  <p>{JSON.stringify(values)}</p>
                  <p>{JSON.stringify(errors)}</p>
                </>
              )}
              <Submission
                text={isSubmitting ? 'Submitting...' : 'Submit'}
                canSubmit={isValid}
                handleSubmit={handleSubmit}
                error={submissionError}
                isSubmitting={isSubmitting}
                id="qu-button-submit-form"
              />
            </SectionContent>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default DailyGeneralUpdate;
