import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import InputGroup from './Group';
import Label from '../Label';
import theme, { pxToEms } from '../../theme';
import { withFormAdapter } from '../../helpers/hoc';
import ErrorMessage from '../ErrorMessage';

const inputCss = css`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 5px;

  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(19)};
  padding: ${theme.spacing(2)};
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: 0 0 0pt 2pt ${theme.colors.coreTurquoise};
  }
`;

const TextInput = styled.input`
  ${inputCss};
`;

const TextArea = styled.textarea`
  ${inputCss};
  resize: none;
`;

const Relative = styled(Box)`
  position: relative;
`;
const ActionsContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 5px;
`;

const LabelledTextInput = ({
  name,
  label,
  form,
  asTextArea,
  onChange,
  placeholder,
  value,
  errorMessage,
  disabled,
  actions,
  className,
  ...rest
}) => {
  const Input = asTextArea ? TextArea : TextInput;
  return (
    <InputGroup>
      <Label htmlFor={name} label={label} />

      <Relative>
        <Input
          id={name}
          type="text"
          autocomplete="off"
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          className={className}
          {...rest}
        />
        <ActionsContainer>{actions}</ActionsContainer>
      </Relative>
      <ErrorMessage className={`${className}_error`}>{errorMessage}</ErrorMessage>
    </InputGroup>
  );
};

export default withFormAdapter(LabelledTextInput);
