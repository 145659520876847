import {
  CURRENT_SITUATION,
  PATIENTS,
  ISO_NUMBER_STAFF_UNAVAILABLE,
  ISO_NUMBER_MED_STAFF_UNAVAILABLE,
  ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE,
  ISO_NUMBER_NURSES_UNAVAILABLE,
  ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE,
  ISO_NUMBER_OTHER_STAFF_UNAVAILABLE,
  ISO_NUMBER_BEDS_AVAILABLE,
  ISO_NUMBER_ICU_BEDS_AVAILABLE,
  ISO_NUMBER_PATIENTS,
  ISO_COVID_DEATHS,
  ISO_PAST_DAY_PATIENTS,
  ISO_NUMBER_MALES_UNDER5,
  ISO_NUMBER_MALES_6TO17,
  ISO_NUMBER_MALES_18TO59,
  ISO_NUMBER_MALES_60PLUS,
  ISO_NUMBER_FEMALES_UNDER5,
  ISO_NUMBER_FEMALES_6TO17,
  ISO_NUMBER_FEMALES_18TO59,
  ISO_NUMBER_FEMALES_60PLUS,
  ISO_NUMBER_UNDERLYING_CONDITIONS,
  ISO_NUMBER_CRITICAL,
  ISO_NUMBER_MODERATE,
  ISO_NUMBER_STABLE,
  ISO_NUMBER_TESTS,
  ISO_NUMBER_POSITIVE,
  ISO_NUMBER_NEGATIVE,
  ISO_NUMBER_DISCHARGED,
  ISO_NUMBER_DEATHS,
  ISO_NUMBER_COLLECTED_CONTACTS,
  ISO_NUMBER_TRACED_CONTACTS,
  ISO_UNIT_STOCK,
  ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  ISO_FIELD_SUPPLY_STERILISATION,
  ISO_FIELD_ANYTHING_TO_REPORT,
  OPTIONS_ISO_UNIT_STOCK,
  OPTIONS_PPE,
  OPTIONS_STERILISING_EQUIPMENT,
  ISO_PERSIST_DAILY_PREFIX,
  FIELD_FACILITY_TYPE,
  ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE,
} from '../../schema/constants';
import Label from '../../components/Label';
import { Field, Form, Formik, getIn } from 'formik';
import { Persister, useHydration } from '../../components/Persistence';
import React, { useEffect, useState } from 'react';
import InputCheckboxes from '../../components/Input/Checkboxes';
import InputRadio from '../../components/Input/Radio';
import InputText from '../../components/Input/Text';
import Page from '../../components/Page';
import Section from '../../components/Section';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import api from '../../api';
import { isDeveloperMode } from '../../utils/debug';
import schema from '../../schema/validation/isolationUnitDailyReport';
import { scrollToTopOfPage, generatePersistenceKey } from '../../helpers';
import { yesOrNoOptions } from '../../helpers/inputs';
import InlineNumber from '../../components/Input/InlineNumber';
import FacilityName from '../../components/FacilityName';

const DailyGeneralUpdate = ({ accessToken, facilityId, onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');
  useEffect(() => {
    scrollToTopOfPage();
  }, []);
  const [sessionStorageKey] = useState(generatePersistenceKey(ISO_PERSIST_DAILY_PREFIX));
  const initialValues = useHydration(sessionStorageKey, {
    facilityId,
    accessToken,
    [FIELD_FACILITY_TYPE]: 'isolation',
  });

  const onSubmit = async (values) => {
    try {
      setSubmissionError('');
      await api.post(process.env.REACT_APP_DAILY_REPORT_SERVICE_URL, values);
      sessionStorage.removeItem(sessionStorageKey);
      onSuccess();
    } catch (error) {
      console.error(error);
      setSubmissionError('Something went wrong');
    }
  };

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnMount={true}
      >
        {({ isValid, isSubmitting, handleSubmit, values, errors }) => (
          <Form>
            <Persister name={sessionStorageKey} ignoredKeys={['facilityId', 'accessToken']} />
		  	<FacilityName h1/>
            <Section title="Staff and Capacity" />
            <SectionContent>
              <Field
                label="Were any staff members unable to attend their shift today?"
                name={`${CURRENT_SITUATION}.${ISO_NUMBER_STAFF_UNAVAILABLE}`}
                component={InputRadio}
                options={yesOrNoOptions}
                value={getIn(values, `${CURRENT_SITUATION}.${ISO_NUMBER_STAFF_UNAVAILABLE}`)}
                className={`iso-${CURRENT_SITUATION}.${ISO_NUMBER_STAFF_UNAVAILABLE}`}
              />
              {getIn(values, `${CURRENT_SITUATION}.${ISO_NUMBER_STAFF_UNAVAILABLE}`) && (
                <>
                  <div style={{ marginBottom: '20px' }}>
                    <Label label="For each of these groups, provide the number of staff that were unable to attend their shift." />
                  </div>
                  <Field
                    label="Number of unavailable medical staff"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_MED_STAFF_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${ISO_NUMBER_MED_STAFF_UNAVAILABLE}`,
                    )}
                    className={`iso-${ISO_NUMBER_MED_STAFF_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable clinical staff"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE}`,
                    )}
                    className={`iso-${ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable nurses"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_NURSES_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(values, `${CURRENT_SITUATION}.${ISO_NUMBER_NURSES_UNAVAILABLE}`)}
                    className={`iso-${ISO_NUMBER_NURSES_UNAVAILABLE}`}
                  />
                  <Field
                    label="Number of unavailable lab technicians"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE}`,
                    )}
                    className={`iso-${ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE}`}
                  />

                  <Field
                    label="Number of unavailable non technical staff"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE}`,
                    )}
                    className={`iso-${ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE}`}
                  />

                  <Field
                    label="Number of unavailable other staff members"
                    name={`${CURRENT_SITUATION}.${ISO_NUMBER_OTHER_STAFF_UNAVAILABLE}`}
                    component={InputText}
                    type="number"
                    inputMode="numeric"
                    value={getIn(
                      values,
                      `${CURRENT_SITUATION}.${ISO_NUMBER_OTHER_STAFF_UNAVAILABLE}`,
                    )}
                    className={`iso-${ISO_NUMBER_OTHER_STAFF_UNAVAILABLE}`}
                  />
                </>
              )}
              <Field
                label="How many beds are available today?"
                name={`${CURRENT_SITUATION}.${ISO_NUMBER_BEDS_AVAILABLE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${ISO_NUMBER_BEDS_AVAILABLE}`)}
                className={`iso-${ISO_NUMBER_BEDS_AVAILABLE}`}
              />
              <Field
                label="How many ICU beds with ventilators are available today?"
                name={`${CURRENT_SITUATION}.${ISO_NUMBER_ICU_BEDS_AVAILABLE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${ISO_NUMBER_ICU_BEDS_AVAILABLE}`)}
                className={`iso-${ISO_NUMBER_ICU_BEDS_AVAILABLE}`}
              />
              <Field
                label="How many patients do you have in your isolation facility right now?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_PATIENTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_PATIENTS}`)}
                className={`iso-${ISO_NUMBER_PATIENTS}`}
              />
              <Field
                label="How many of these patients have underlying health conditions?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_UNDERLYING_CONDITIONS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_UNDERLYING_CONDITIONS}`,
                )}
                className={`iso-${ISO_NUMBER_UNDERLYING_CONDITIONS}`}
              />
            </SectionContent>
            <Section title="Admissions" />

            <SectionContent>
              <Field
                label="In the last 24 hours, how many new patients have been admitted?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_PAST_DAY_PATIENTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_PAST_DAY_PATIENTS}`)}
                className={`iso-${ISO_PAST_DAY_PATIENTS}`}
              />
              <Label label="In the last 24 hours, how many males were admitted in the following age ranges?" />

              <Field
                label="Aged 5 and under?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_UNDER5}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_UNDER5}`)}
                className={`iso-${ISO_NUMBER_MALES_UNDER5}`}
              />
              <Field
                label="Aged 6 to 17?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_6TO17}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_6TO17}`)}
                className={`iso-${ISO_NUMBER_MALES_6TO17}`}
              />
              <Field
                label="Aged 18 to 59?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_18TO59}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_18TO59}`)}
                className={`iso-${ISO_NUMBER_MALES_18TO59}`}
              />
              <Field
                label="Aged 60 or over?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_60PLUS}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MALES_60PLUS}`)}
                className={`iso-${ISO_NUMBER_MALES_60PLUS}`}
              />
              <Label label="In the last 24 hours, how many females were admitted in the following age ranges?" />
              <Field
                label="Aged 5 and under?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_UNDER5}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_UNDER5}`,
                )}
                className={`iso-${ISO_NUMBER_FEMALES_UNDER5}`}
              />
              <Field
                label="Aged 6 to 17?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_6TO17}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_6TO17}`,
                )}
                className={`iso-${ISO_NUMBER_FEMALES_6TO17}`}
              />
              <Field
                label="Aged 18 to 59?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_18TO59}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_18TO59}`,
                )}
                className={`iso-${ISO_NUMBER_FEMALES_18TO59}`}
              />
              <Field
                label="Aged 60 or over?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_60PLUS}`}
                component={InlineNumber}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_FEMALES_60PLUS}`,
                )}
                className={`iso-${ISO_NUMBER_FEMALES_60PLUS}`}
              />
            </SectionContent>

            <Section title="Patient Status" />
            <SectionContent>
              <Field
                label="How many patients are critically ill today?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_CRITICAL}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_CRITICAL}`)}
                className={`iso-${ISO_NUMBER_CRITICAL}`}
              />
              <Field
                label="How many patients are moderately ill today?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MODERATE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_MODERATE}`)}
                className={`iso-${ISO_NUMBER_MODERATE}`}
              />
              <Field
                label="How many patients are stable today?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_STABLE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_STABLE}`)}
                className={`iso-${ISO_NUMBER_STABLE}`}
              />
            </SectionContent>

            <Section title="COVID-19 Test Results" />
            <SectionContent>
              <Field
                label="How many COVID-19 tests have been conducted at your facility in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_TESTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_TESTS}`)}
                className={`iso-${ISO_NUMBER_TESTS}`}
              />
              <Field
                label="How many patients tested positive for COVID-19 in last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_POSITIVE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_POSITIVE}`)}
                className={`iso-${ISO_NUMBER_POSITIVE}`}
              />
              <Field
                label="How many patients tested negative for COVID-19 in last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_NEGATIVE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_NEGATIVE}`)}
                className={`iso-${ISO_NUMBER_NEGATIVE}`}
              />
            </SectionContent>

            <Section title="Discharged and Deaths" />
            <SectionContent>
              <Field
                label="How many patients have been discharged in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_DISCHARGED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_DISCHARGED}`)}
                className={`iso-${ISO_NUMBER_DISCHARGED}`}
              />
              <Field
                label="How many patients have died in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_DEATHS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_DEATHS}`)}
                className={`iso-${ISO_NUMBER_DEATHS}`}
              />

              <Field
                label="Of those, how many died due to COVID-19?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_COVID_DEATHS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${PATIENTS}.${ISO_COVID_DEATHS}`)}
                className={`iso-${ISO_COVID_DEATHS}`}
              />
            </SectionContent>

            <Section title="Contacts" />
            <SectionContent>
              <Field
                label="How many contacts have been collected in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_COLLECTED_CONTACTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_COLLECTED_CONTACTS}`,
                )}
                className={`iso-${ISO_NUMBER_COLLECTED_CONTACTS}`}
              />
              <Field
                label="How many contacts have been traced in the last 24 hours?"
                name={`${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_TRACED_CONTACTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${PATIENTS}.${ISO_NUMBER_TRACED_CONTACTS}`,
                )}
                className={`iso-${ISO_NUMBER_TRACED_CONTACTS}`}
              />
            </SectionContent>

            <Section title="Stock availability" />
            <SectionContent>
              <Field
                label="Please indicate which of the following items you are likely to run out of in the next 7 days:"
                name={ISO_UNIT_STOCK}
                component={InputCheckboxes}
                boxes={OPTIONS_ISO_UNIT_STOCK}
                value={values[ISO_UNIT_STOCK]}
              />

              <Field
                label="PPE"
                name={ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE}
                component={InputCheckboxes}
                boxes={OPTIONS_PPE}
                value={values[ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE]}
              />

              <Field
                label="Sterilisation equipment"
                name={ISO_FIELD_SUPPLY_STERILISATION}
                component={InputCheckboxes}
                boxes={OPTIONS_STERILISING_EQUIPMENT}
                value={values[ISO_FIELD_SUPPLY_STERILISATION]}
              />
            </SectionContent>
            <Section title="Other" />
            <SectionContent>
              <Field
                label="Please add any comments, concerns or questions."
                name={ISO_FIELD_ANYTHING_TO_REPORT}
                component={InputText}
                asTextArea
                rows={4}
                placeholder="300 characters (maximum)"
                value={values[ISO_FIELD_ANYTHING_TO_REPORT]}
                data-testid={`${ISO_FIELD_ANYTHING_TO_REPORT}`}
              />
              {isDeveloperMode && (
                <>
                  <p>Developer mode enabled</p>
                  <p>{JSON.stringify(values)}</p>
                  <p>{JSON.stringify(errors)}</p>
                </>
              )}
              <Submission
                text={isSubmitting ? 'Submitting...' : 'Submit'}
                canSubmit={isValid}
                handleSubmit={handleSubmit}
                error={submissionError}
                isSubmitting={isSubmitting}
                id="iso-button-submit-form"
              />
            </SectionContent>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default DailyGeneralUpdate;
