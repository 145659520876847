import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import Label from '../Label';
import theme, { pxToEms } from '../../theme';
import { withFormAdapter } from '../../helpers/hoc';
import ErrorMessage from '../ErrorMessage';

const inputCss = css`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 5px;

  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(19)};
  padding: ${theme.spacing(2)};
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: 0 0 0pt 2pt ${theme.colors.coreTurquoise};
  }
`;

const TextInput = styled.input`
  ${inputCss};
`;

const TextArea = styled.textarea`
  ${inputCss};
  resize: none;
`;

const Flex = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    flex: 2 1 auto;
  }
  > input {
    width:6em;
    flex: 0 0 0;
  }
`;
const ActionsContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 5px;
`;

const LabelledTextInput = ({
                             name,
                             label,
                             form,
                             asTextArea,
                             onChange,
                             placeholder,
                             value,
                             errorMessage,
                             disabled,
                             actions,
  className,
                             ...rest
                           }) => {
  const Input = asTextArea ? TextArea : TextInput;
  return (
    <div>

      <Flex>
        <Label htmlFor={name} label={label} className="inline-number-label" />
        <Input
          id={name}
          type="text"
          autocomplete="off"
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          className={`${className} inline-number-input`}
          {...rest}
        />
      </Flex>
      <ActionsContainer>{actions}</ActionsContainer>
      <ErrorMessage className={`${className}_error`}>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default withFormAdapter(LabelledTextInput);
