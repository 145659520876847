import styled from 'styled-components';
import theme, { pxToEms } from '../../theme';

const SectionHeading = styled.h2`
  font-size: ${pxToEms(20)};
  line-height: ${pxToEms(27)};
  padding: 0;
  margin: 0;
  color: ${theme.colors.white};
`;

export default SectionHeading;
