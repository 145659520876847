import React, { Component } from 'react';
import rg4js from 'raygun4js';
import { Box } from '@material-ui/core';
import Page, { PageCenteredContent } from '../components/Page/index';
import ConfirmationMessage from '../components/ConfirmationMessage';
import theme from '../theme';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    rg4js('send', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page>
          <PageCenteredContent>
            <Box margin={2}>
              <ConfirmationMessage>Something went wrong</ConfirmationMessage>
              <ConfirmationMessage>
                If the problem persists please try a different browser or contact Elephant support
                on{' '}
                <a
                  href="mailto:support@elephant.health"
                  style={{ color: theme.colors.coreTurquoise }}
                >
                  support@elephant.health
                </a>{' '}
                or call{' '}
                <a href="tel:+254790055523" style={{ color: theme.colors.coreTurquoise }}>
                  +254 790 055523
                </a>
              </ConfirmationMessage>
              <ConfirmationMessage>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  style={{ color: theme.colors.coreTurquoise }}
                  onClick={() => window.location.reload()}
                >
                  Reload page
                </a>
              </ConfirmationMessage>
            </Box>
          </PageCenteredContent>
        </Page>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
