import {
  FIELD_FACILITY_ID,
  CURRENT_SITUATION,
  ERROR_MESSAGE_REQUIRED,
  ISO_NUMBER_STAFF_UNAVAILABLE,
  ISO_NUMBER_MED_STAFF_UNAVAILABLE,
  ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE,
  ISO_NUMBER_NURSES_UNAVAILABLE,
  ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE,
  ISO_NUMBER_OTHER_STAFF_UNAVAILABLE,
  ISO_NUMBER_BEDS_AVAILABLE,
  ISO_NUMBER_ICU_BEDS_AVAILABLE,
  ISO_COVID_DEATHS,
  PATIENTS,
  ISO_PAST_DAY_PATIENTS,
  ISO_NUMBER_PATIENTS,
  ISO_NUMBER_MALES_UNDER5,
  ISO_NUMBER_MALES_6TO17,
  ISO_NUMBER_MALES_18TO59,
  ISO_NUMBER_MALES_60PLUS,
  ISO_NUMBER_FEMALES_UNDER5,
  ISO_NUMBER_FEMALES_6TO17,
  ISO_NUMBER_FEMALES_18TO59,
  ISO_NUMBER_FEMALES_60PLUS,
  ISO_NUMBER_UNDERLYING_CONDITIONS,
  ISO_NUMBER_CRITICAL,
  ISO_NUMBER_MODERATE,
  ISO_NUMBER_STABLE,
  ISO_NUMBER_TESTS,
  ISO_NUMBER_POSITIVE,
  ISO_NUMBER_NEGATIVE,
  ISO_NUMBER_DISCHARGED,
  ISO_NUMBER_DEATHS,
  ISO_NUMBER_COLLECTED_CONTACTS,
  ISO_NUMBER_TRACED_CONTACTS,
  ISO_UNIT_STOCK,
  ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  ISO_FIELD_SUPPLY_STERILISATION,
  ISO_FIELD_ANYTHING_TO_REPORT,
  OPTIONS_ISO_UNIT_STOCK,
  OPTIONS_PPE,
  OPTIONS_STERILISING_EQUIPMENT,
  ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE,
} from '../constants';
import { string, object, mixed, boolean } from 'yup';
import { requiredInteger, requiredString, validateNumber, notRequiredInteger } from '../utils';

const validateAdmittedPatients = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more patients than total patients`,
      `Sum of male and female patients must be equal to the number of total patients admitted in the last 24 hours`,
      function () {
        const malesUnder5 = this.parent[ISO_NUMBER_MALES_UNDER5] || 0;
        const malesMoreThan6 = this.parent[ISO_NUMBER_MALES_6TO17] || 0;
        const malesMoreThan18 = this.parent[ISO_NUMBER_MALES_18TO59] || 0;
        const malesMoreThan60 = this.parent[ISO_NUMBER_MALES_60PLUS] || 0;
        const femalesUnder5 = this.parent[ISO_NUMBER_FEMALES_UNDER5] || 0;
        const femalesMoreThan6 = this.parent[ISO_NUMBER_FEMALES_6TO17] || 0;
        const femalesMoreThan18 = this.parent[ISO_NUMBER_FEMALES_18TO59] || 0;
        const femalesMoreThan60 = this.parent[ISO_NUMBER_FEMALES_60PLUS] || 0;
        const allPatients =
          malesUnder5 +
          malesMoreThan6 +
          malesMoreThan18 +
          malesMoreThan60 +
          femalesUnder5 +
          femalesMoreThan6 +
          femalesMoreThan18 +
          femalesMoreThan60;
        return this.parent[ISO_PAST_DAY_PATIENTS] === allPatients;
      },
    );

const validatePatientsWithUnderlying = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more patients with underlying conditions than total patients`,
      `Patients with underlying conditions can not exceed the total number of patients`,
      function () {
        const patientsWithUnderlyingConditions = this.parent[ISO_NUMBER_UNDERLYING_CONDITIONS] || 0;
        return this.parent[ISO_NUMBER_PATIENTS] >= patientsWithUnderlyingConditions;
      },
    );

const requiredFields = {
  accessToken: requiredString,
  [FIELD_FACILITY_ID]: requiredString,
  [ISO_FIELD_ANYTHING_TO_REPORT]: string().max(300, ({ value }) => {
    return `Exceeded 300 characters (currently ${value.length})`;
  }),
};

const validateTotalNumberPatients = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more patients admitted in the last 24 hours than total patients`,
      `Admitted patients can not exceed the total number of patients`,
      function () {
        const admittedPatients = this.parent[ISO_PAST_DAY_PATIENTS] || 0;
        return this.parent[ISO_NUMBER_PATIENTS] >= admittedPatients;
      },
    );

const requiredFieldsIfUnavailableStaff = {
  [CURRENT_SITUATION]: object({
    [ISO_NUMBER_STAFF_UNAVAILABLE]: boolean().required(ERROR_MESSAGE_REQUIRED),
    [ISO_NUMBER_MED_STAFF_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_CLINICAL_STAFF_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_NURSES_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_LAB_TECHNICIANS_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_NON_TECH_STAFF_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_OTHER_STAFF_UNAVAILABLE]: mixed().when(ISO_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [ISO_NUMBER_BEDS_AVAILABLE]: requiredInteger(),
    [ISO_NUMBER_ICU_BEDS_AVAILABLE]: requiredInteger(),
    [PATIENTS]: object()
      .shape({
        [ISO_NUMBER_PATIENTS]: requiredInteger(),
        [ISO_NUMBER_UNDERLYING_CONDITIONS]: validatePatientsWithUnderlying(),
        [ISO_PAST_DAY_PATIENTS]: validateTotalNumberPatients(),
        [ISO_NUMBER_MALES_UNDER5]: validateAdmittedPatients(),
        [ISO_NUMBER_MALES_6TO17]: validateAdmittedPatients(),
        [ISO_NUMBER_MALES_18TO59]: validateAdmittedPatients(),
        [ISO_NUMBER_MALES_60PLUS]: validateAdmittedPatients(),
        [ISO_NUMBER_FEMALES_UNDER5]: validateAdmittedPatients(),
        [ISO_NUMBER_FEMALES_6TO17]: validateAdmittedPatients(),
        [ISO_NUMBER_FEMALES_18TO59]: validateAdmittedPatients(),
        [ISO_NUMBER_FEMALES_60PLUS]: validateAdmittedPatients(),
        [ISO_NUMBER_CRITICAL]: requiredInteger(),
        [ISO_NUMBER_MODERATE]: requiredInteger(),
        [ISO_NUMBER_STABLE]: requiredInteger(),
        [ISO_NUMBER_TESTS]: requiredInteger(),
        [ISO_NUMBER_POSITIVE]: requiredInteger(),
        [ISO_NUMBER_NEGATIVE]: requiredInteger(),
        [ISO_NUMBER_DISCHARGED]: requiredInteger(),
        [ISO_NUMBER_DEATHS]: requiredInteger(),
        [ISO_COVID_DEATHS]: requiredInteger(),
        [ISO_NUMBER_COLLECTED_CONTACTS]: requiredInteger(),
        [ISO_NUMBER_TRACED_CONTACTS]: requiredInteger(),
      })
      .required(ERROR_MESSAGE_REQUIRED),
  }).required(),

  [ISO_UNIT_STOCK]: object().shape(
    OPTIONS_ISO_UNIT_STOCK.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),

  [ISO_FIELD_SEVEN_DAY_SUPPLY_OF_PPE]: object().shape(
    OPTIONS_PPE.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),

  [ISO_FIELD_SUPPLY_STERILISATION]: object().shape(
    OPTIONS_STERILISING_EQUIPMENT.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),
};

const schema = object()
  .shape(requiredFields)
  .shape(requiredFieldsIfUnavailableStaff)
  .noUnknown(true);
export default schema;
