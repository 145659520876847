import {
  QU_NUMBER_MEDICAL,
  QU_NUMBER_SUPPORT,
  QU_NUMBER_POLICE,
  QU_NUMBER_KITCHEN,
  QU_NUMBER_ENFORCEMENT,
  QU_NUMBER_OTHER,
  QU_NUMBER_BEDS,
  QU_NUMBER_C19_TESTED_TO_DATE,
  QU_NUMBER_C19_CONFIRMED_TO_DATE,
  QU_NUMBER_MALES_UNDER5,
  QU_NUMBER_MALES_6TO17,
  QU_NUMBER_MALES_18TO59,
  QU_NUMBER_MALES_60PLUS,
  QU_NUMBER_FEMALES_UNDER5,
  QU_NUMBER_FEMALES_6TO17,
  QU_NUMBER_FEMALES_18TO59,
  QU_NUMBER_FEMALES_60PLUS,
  QU_WORKERS, 
  QU_COVID
} from '../../schema/constants';
import { Field, getIn } from 'formik';
import React from 'react';

import { Box } from 'reflexbox/styled-components';
import InlineNumber from '../../components/Input/InlineNumber';
import InputText from '../../components/Input/Text';
import Label from '../../components/Label';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import { isDeveloperMode } from '../../utils/debug'

const getWorkerSum = (vals) => {
  let sum = 0;
  if(!vals[QU_WORKERS]) {
    return 0;
  }
  // eslint-disable-next-line 
  for (let [key,  val] of Object.entries(vals[QU_WORKERS])) {
    sum = sum + val;
  }
  return sum;
};

const Details = ({ canSubmit, isSubmitting, handleSubmit, values, submissionError, errors }) => (
  <Box>
    <Section title="Number of staff available" />
    <SectionContent>

      <Field
        label="Number of medical and technical staff?"
        name={`${QU_WORKERS}.${QU_NUMBER_MEDICAL}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_MEDICAL}`)}
        className={`qu-${QU_NUMBER_MEDICAL}`}
      />
      <Field
        label="Number of support staff?"
        name={`${QU_WORKERS}.${QU_NUMBER_SUPPORT}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_SUPPORT}`)}
        className={`qu-${QU_NUMBER_SUPPORT}`}
      />
      <Field
        label="Number of police officers?"
        name={`${QU_WORKERS}.${QU_NUMBER_POLICE}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_POLICE}`)}
        className={`qu-${QU_NUMBER_POLICE}`}
      />
      <Field
        label="Number of kitchen staff?"
        name={`${QU_WORKERS}.${QU_NUMBER_KITCHEN}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_KITCHEN}`)}
        className={`qu-${QU_NUMBER_KITCHEN}`}
      />
      <Field
        label="Number of enforcement officers?"
        name={`${QU_WORKERS}.${QU_NUMBER_ENFORCEMENT}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_ENFORCEMENT}`)}
        className={`qu-${QU_NUMBER_ENFORCEMENT}`}
      />
      <Field
        label="Number of others?"
        name={`${QU_WORKERS}.${QU_NUMBER_OTHER}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_WORKERS}.${QU_NUMBER_OTHER}`)}
        className={`qu-${QU_NUMBER_OTHER}`}
      />

      <p>Total staff <span className="qu-numberOfQuarantineUnitStaff">{getWorkerSum(values)}</span></p>
    </SectionContent>

    <Section title="Capacity &amp; Testing" />
    <SectionContent>
     <Field
        label="How many beds do you have at your quarantine facility?"
        name={`${QU_NUMBER_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_NUMBER_BEDS}`)}
        className={`qu-${QU_NUMBER_BEDS}`}
      />
     <Field
        label="How many guests have been tested for COVID-19 to date?"
        name={`${QU_COVID}.${QU_NUMBER_C19_TESTED_TO_DATE}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_C19_TESTED_TO_DATE}`)}
        className={`qu-${QU_NUMBER_C19_TESTED_TO_DATE}`}
      />
     <Field
        label="How many confirmed COVID-19 cases have there been at your facility to date?"
        name={`${QU_COVID}.${QU_NUMBER_C19_CONFIRMED_TO_DATE}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_C19_CONFIRMED_TO_DATE}`)}
        className={`qu-${QU_NUMBER_C19_CONFIRMED_TO_DATE}`}
      />



      <Label label="How many males are currently in quarantine in the following age ranges?" />

     <Field
        label="Aged 5 and under?"
        name={`${QU_COVID}.${QU_NUMBER_MALES_UNDER5}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_MALES_UNDER5}`)}
        className={`qu-${QU_NUMBER_MALES_UNDER5}`}
      />
     <Field
        label="Aged 6 to 17?"
        name={`${QU_COVID}.${QU_NUMBER_MALES_6TO17}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_MALES_6TO17}`)}
        className={`qu-${QU_NUMBER_MALES_6TO17}`}
      />
     <Field
        label="Aged 18 to 59?"
        name={`${QU_COVID}.${QU_NUMBER_MALES_18TO59}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_MALES_18TO59}`)}
        className={`qu-${QU_NUMBER_MALES_18TO59}`}
      />
     <Field
        label="Aged 60 or over?"
        name={`${QU_COVID}.${QU_NUMBER_MALES_60PLUS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_MALES_60PLUS}`)}
        className={`qu-${QU_NUMBER_MALES_60PLUS}`}
      />


      <Label label="How many females are currently in quarantine in the following age ranges?" />

      <Field
        label="Aged 5 and under?"
        name={`${QU_COVID}.${QU_NUMBER_FEMALES_UNDER5}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_FEMALES_UNDER5}`)}
        className={`qu-${QU_NUMBER_FEMALES_UNDER5}`}
      />
      <Field
        label="Aged 6 to 17?"
        name={`${QU_COVID}.${QU_NUMBER_FEMALES_6TO17}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_FEMALES_6TO17}`)}
        className={`qu-${QU_NUMBER_FEMALES_6TO17}`}
      />
      <Field
        label="Aged 18 to 59?"
        name={`${QU_COVID}.${QU_NUMBER_FEMALES_18TO59}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_FEMALES_18TO59}`)}
        className={`qu-${QU_NUMBER_FEMALES_18TO59}`}
      />
      <Field
        label="Aged 60 or over?"
        name={`${QU_COVID}.${QU_NUMBER_FEMALES_60PLUS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${QU_COVID}.${QU_NUMBER_FEMALES_60PLUS}`)}
        className={`qu-${QU_NUMBER_FEMALES_60PLUS}`}
      />
      

      {isDeveloperMode && (
        <>
          <p>Developer mode enabled</p>
          <p>{JSON.stringify(values)}</p>
          <p>{JSON.stringify(errors)}</p>
        </>
      )}
      <Submission
        text={isSubmitting ? 'Submitting...' : 'Submit'}
        canSubmit={canSubmit}
        handleSubmit={handleSubmit}
        error={submissionError}
        isSubmitting={isSubmitting}
        id="qu-button-submit-form"
      />
    </SectionContent>
  </Box>
);

export default Details;
