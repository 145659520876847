import React from 'react';
import theme, { pxToEms } from '../theme';
import styled from "styled-components";


const Heading = styled.h4`
  font-weight: bold;
  font-size: ${pxToEms(20)};
  line-height: 27px;
  color: ${theme.colors.coreTurquoise};
  margin: ${theme.spacing(2)} 0;
`;

const Headline = styled.h1`
  font-weight: bold;
  font-size: ${pxToEms(24)};
  line-height: 32px;
  background: ${theme.colors.coreTurquoise};
  color: ${theme.colors.white};
  padding: ${theme.spacing(4)};
  margin: 0;
  
  
`


const facilityNameFromQueryString = new URLSearchParams(document.location.search).get('facilityName');

const FacilityName = ({h1}) => {
	if(facilityNameFromQueryString) {

		if(h1) {
			return <Headline>{facilityNameFromQueryString}</Headline>
		} else {
			return (<Heading>{facilityNameFromQueryString}</Heading>);
		}
	} else {
		return null;
	}
}

export default FacilityName;