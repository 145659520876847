import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';

const getComponentName = (component) => {
  return component.displayValue || component.name || 'InputComponent';
};

export const withFormAdapter = (InputComponent) => {
  const FormAdaptedInput = ({ field, form, ...props }) => {
    const id = field.name;
    const isTouched = !!getIn(form.touched, id);
    const error = isTouched && getIn(form.errors, id);
    const errorMessage = typeof error === 'string' ? error : '';

    const { setFieldValue, setFieldTouched } = useFormikContext();
    useEffect(() => {
      if (props.clearValueOnUnmount === false) {
        return () => undefined;
      }
      setFieldValue(id, props.value, false);
      setFieldTouched(id, false, true);
      return () => setFieldValue(id, undefined);
    }, [id, props.clearValueOnUnmount, props.value, setFieldTouched, setFieldValue]);

    return (
      <InputComponent
        id={id}
        {...field}
        {...form}
        {...props}
        errorMessage={isTouched && errorMessage}
      />
    );
  };
  FormAdaptedInput.displayName = `withFormAdapter(${getComponentName(InputComponent)})`;

  return FormAdaptedInput;
};
