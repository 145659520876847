import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import Button from '../../components/Button';
import SectionContent from '../../components/Section/Content';
import Section from '../../components/Section';
import InputArray from '../../components/Input/Array';
import { FIELD_IN_CHARGE_MEMBERS } from '../../schema/constants';
import { isDeveloperMode } from '../../utils/debug';
import FacilityName from '../../components/FacilityName';

const InCharges = ({ onContinue, errors, values }) => {
  const canContinue = values[FIELD_IN_CHARGE_MEMBERS] && !errors[FIELD_IN_CHARGE_MEMBERS];

  return (
    <Box>
      <Section title="Community Health Unit" />

      <SectionContent>
	    <FacilityName/>
        <p>
          The County MoH has partnered with Elephant Healthcare to help manage the coronavirus
          pandemic.
        </p>
        <p>
          In order to understand your community’s needs, County MoH requests that you complete a
          short weekly form to update on your community’s status during the pandemic.
        </p>
        <p>
          Please provide your name and the number that you would like us to use to send the weekly
          forms. By submitting your mobile number below, you confirm that consent is provided for
          Elephant Healthcare to contact you and process your data.
        </p>

        <InputArray
          elements={values[FIELD_IN_CHARGE_MEMBERS]}
          name={FIELD_IN_CHARGE_MEMBERS}
          inputs={[
            {
              id: 'name',
              label: ['Your name', 'In-charge name'],
              clearValueOnUnmount: false,
            },
            {
              id: 'mobileNumber',
              label: ['Your mobile number', 'In-charge mobile phone number'],
              type: 'phone',
              placeholder: 'please enter mobile number',
              inputMode: 'tel',
              clearValueOnUnmount: false,
            },
          ]}
          addEnabled={false}
        />

        {isDeveloperMode && (
          <>
            <p>Developer mode enabled</p>
            <p>{JSON.stringify(values)}</p>
            <p>{JSON.stringify(errors)}</p>
          </>
        )}

        <Flex justifyContent="flex-end" mt={40}>
          <Button
            type="submit"
            onClick={onContinue}
            disabled={!canContinue}
            data-testid="continue-in-charges"
          >
            Continue
          </Button>
        </Flex>
      </SectionContent>
    </Box>
  );
};

export default InCharges;
