const colors = {
  body: '#000000',
  white: '#ffffff',
  // TODO: this is misleading, as "primary" in MUI theme is core-turquise...
  primary: '#004F71',
  secondary: '',
  greyDarker: '',
  textDiscreteGrey: '',
  borderGrey: '#CCCCCC',
  coreTurquoise: '#469EAD',
  midGrey: '#EFEFEF',
  lightGrey: '#F8F9FA',
  alert: '#D23201',
  honeyYellow: '',
  // turquoise: '',
  disabledGrey: '#CCCCCC',
  buttonDropShadow: 'rgba(0,0,0,0.25)'
};

const spacingUnitPx = 5;
const spacing = units => `${spacingUnitPx * units}px`;
export const pxToEms = pixels => `${pixels / 16}em`;

export default {
  colors,
  spacing,
  gutter: {
    horizontal: 20,
    vertical: 30,
  },
};
