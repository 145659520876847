import {
  QU_COVID,
  FIELD_FACILITY_ID,
  FIELD_IN_CHARGE_MEMBERS,
  QU_FACILITY_NAME,
  ERROR_MESSAGE_REQUIRED,
  QU_NUMBER_MEDICAL,
  QU_NUMBER_SUPPORT,
  QU_NUMBER_POLICE,
  QU_NUMBER_KITCHEN,
  QU_NUMBER_ENFORCEMENT,
  QU_NUMBER_OTHER,
  QU_NUMBER_BEDS,
  QU_NUMBER_C19_TESTED_TO_DATE,
  QU_NUMBER_C19_CONFIRMED_TO_DATE,
  QU_NUMBER_MALES_UNDER5,
  QU_NUMBER_MALES_6TO17,
  QU_NUMBER_MALES_18TO59,
  QU_NUMBER_MALES_60PLUS,
  QU_NUMBER_FEMALES_UNDER5,
  QU_NUMBER_FEMALES_6TO17,
  QU_NUMBER_FEMALES_18TO59,
  QU_NUMBER_FEMALES_60PLUS,
  QU_WORKERS,
} from '../constants';
import { array,object } from 'yup';
import { requiredInteger, requiredNonNumeric, requiredString, validatePhoneNumber } from '../utils';

export const generateSchema = () => {
  return object().shape({
    [FIELD_FACILITY_ID]: requiredString,
    [QU_FACILITY_NAME] : requiredString,
    [FIELD_IN_CHARGE_MEMBERS]: array()
      .of(
        object().shape({
          name: requiredNonNumeric,
          mobileNumber: validatePhoneNumber,
        }),
      )
      .min(1)
      .required(ERROR_MESSAGE_REQUIRED),

    [QU_NUMBER_BEDS] : requiredInteger(),

    [QU_WORKERS]: object().shape({
      [QU_NUMBER_MEDICAL ]: requiredInteger(),
      [QU_NUMBER_SUPPORT]: requiredInteger(),
      [QU_NUMBER_POLICE ]: requiredInteger(),
      [QU_NUMBER_KITCHEN ]: requiredInteger(),
      [QU_NUMBER_ENFORCEMENT ]: requiredInteger(),
      [QU_NUMBER_OTHER ]: requiredInteger(),
    }).required(ERROR_MESSAGE_REQUIRED),

    [QU_COVID] : object().shape({
      [QU_NUMBER_C19_TESTED_TO_DATE] : requiredInteger(),
      [QU_NUMBER_C19_CONFIRMED_TO_DATE] : requiredInteger(),
      [QU_NUMBER_MALES_UNDER5] : requiredInteger(),
      [QU_NUMBER_MALES_6TO17] : requiredInteger(),
      [QU_NUMBER_MALES_18TO59] : requiredInteger(),
      [QU_NUMBER_MALES_60PLUS] : requiredInteger(),
      [QU_NUMBER_FEMALES_UNDER5] : requiredInteger(),
      [QU_NUMBER_FEMALES_6TO17] : requiredInteger(),
      [QU_NUMBER_FEMALES_18TO59] : requiredInteger(),
      [QU_NUMBER_FEMALES_60PLUS] : requiredInteger(),
    }).required(ERROR_MESSAGE_REQUIRED)
  });
};
