import {
  FIELD_FACILITY_ID,
  CURRENT_SITUATION,
  QU_NUMBER_HW_MEDICAL_UNAVAILABLE,
  QU_NUMBER_HW_SUPPORT_UNAVAILABLE,
  QU_NUMBER_HW_POLICE_UNAVAILABLE,
  QU_NUMBER_HW_KITCHEN_UNAVAILABLE,
  QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE,
  QU_NUMBER_HW_OTHER_UNAVAILABLE,
  OPTIONS_QUARANTINE_CENTER_STOCK,
  FIELD_NUM_BEDS_AVAILABLE,
  FIELD_NUM_GUESTS,
  FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS,
  FIELD_NUM_GUESTS_PREGNANT,
  FIELD_NUM_GUESTS_PAST_24HOURS,
  ERROR_MESSAGE_REQUIRED,
  QU_NUMBER_MALES_UNDER5,
  QU_NUMBER_MALES_6TO17,
  QU_NUMBER_MALES_18TO59,
  QU_NUMBER_MALES_60PLUS,
  QU_NUMBER_FEMALES_UNDER5,
  QU_NUMBER_FEMALES_6TO17,
  QU_NUMBER_FEMALES_18TO59,
  QU_NUMBER_FEMALES_60PLUS,
  FIELD_NUM_RETURNED_TESTS,
  FIELD_NUM_POSITIVE_TESTS,
  FIELD_NUM_NEGATIVE_TESTS,
  FIELD_PENDING_COVID_TESTS,
  FIELD_NUM_TESTED_GUESTS,
  FIELD_NUM_POSITIVE_TRANSFERED,
  FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED,
  FIELD_NUM_DISCHARGED_COVID_NEGATIVE,
  QUARANTINE_CENTER_STOCK,
  QUARANTINE_FIELD_ANYTHING_TO_REPORT,
  QH_NUMBER_STAFF_UNAVAILABLE,
  OPTIONS_STERILISING_EQUIPMENT,
  QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  OPTIONS_PPE,
  QUARANTINE_FIELD_SUPPLY_STERILISATION,
  GUESTS,
} from '../constants';
import { string, object, mixed, boolean } from 'yup';
import { requiredInteger, requiredString, validateNumber, notRequiredInteger } from '../utils';

const validateTotalNumberGuests = () =>
  mixed().when(FIELD_NUM_GUESTS, {
    is: (guests) => guests > 0,
    then: validateNumber()
      .required(ERROR_MESSAGE_REQUIRED)
      .test(
        `The different age groups guests should be equal to the total number of guests in the last 24hours`,
        `Should be equal to the total number of guests in the last 24 hours`,
        function () {
          const malesUnder5 = this.parent[QU_NUMBER_MALES_UNDER5] || 0;
          const malesMoreThan6 = this.parent[QU_NUMBER_MALES_6TO17] || 0;
          const malesMoreThan18 = this.parent[QU_NUMBER_MALES_18TO59] || 0;
          const malesMoreThan60 = this.parent[QU_NUMBER_MALES_60PLUS] || 0;
          const femalesUnder5 = this.parent[QU_NUMBER_FEMALES_UNDER5] || 0;
          const femalesMoreThan6 = this.parent[QU_NUMBER_FEMALES_6TO17] || 0;
          const femalesMoreThan18 = this.parent[QU_NUMBER_FEMALES_18TO59] || 0;
          const femalesMoreThan60 = this.parent[QU_NUMBER_FEMALES_60PLUS] || 0;
          const otherGuests =
            malesUnder5 +
            malesMoreThan6 +
            malesMoreThan18 +
            malesMoreThan60 +
            femalesUnder5 +
            femalesMoreThan6 +
            femalesMoreThan18 +
            femalesMoreThan60;
          return this.parent[FIELD_NUM_GUESTS_PAST_24HOURS] === otherGuests;
        },
      ),
  });

const validateQuarantinedGuests = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more quarantined guests than total guests`,
      `Can not exceed the number of total guests`,
      function () {
        const guestsQuarantined = this.parent[FIELD_NUM_GUESTS_PAST_24HOURS] || 0;
        return this.parent[FIELD_NUM_GUESTS] >= guestsQuarantined;
      },
    );

const validateGuestsWithUnderlying = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more guests with underlying conditions than total guests`,
      `Can not exceed the number of total guests`,
      function () {
        const guestsWithUnderlyingConditions =
          this.parent[FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS] || 0;
        return this.parent[FIELD_NUM_GUESTS] >= guestsWithUnderlyingConditions;
      },
    );

const validatePregnantGuests = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more pregnant guests than total guests`,
      `Can not exceed the number of total guests`,
      function () {
        const pregnantGuests = this.parent[FIELD_NUM_GUESTS_PREGNANT] || 0;
        return this.parent[FIELD_NUM_GUESTS] >= pregnantGuests;
      },
    );

const validateCovidTests = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more positive and negative tests than total number of tests`,
      `Positive and negative tests should not be more than total tests`,
      function () {
        const positiveTests = this.parent[FIELD_NUM_POSITIVE_TESTS] || 0;
        const negativeTests = this.parent[FIELD_NUM_NEGATIVE_TESTS] || 0;
        return this.parent[FIELD_NUM_RETURNED_TESTS] >= positiveTests + negativeTests;
      },
    );

const validateTestedGuests = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more guests tested for COVID-19 than the returned and waiting results`,
      `Number cant be greater than sum of returned and waiting results`,
      function () {
        const pendingTests = this.parent[FIELD_PENDING_COVID_TESTS] || 0;
        const testedGuests = this.parent[FIELD_NUM_TESTED_GUESTS] || 0;
        return this.parent[FIELD_NUM_RETURNED_TESTS] + pendingTests >= testedGuests;
      },
    );

const validateTransferredGuests = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more transferred guests positive on COVID-19 than total number tested positive`,
      `Number cant be greater than total number tested positive for COVID-19`,
      function () {
        return (
          this.parent[FIELD_NUM_POSITIVE_TESTS] >= (this.parent[FIELD_NUM_POSITIVE_TRANSFERED] || 0)
        );
      },
    );

const validatePositiveWithConditions = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more than total guests with underlying conditions or positive transferred guests`,
      `Number cant be greater than total number tested positive for COVID-19 or the total number of guests`,
      function () {
        const positiveTransferredGuests = this.parent[FIELD_NUM_POSITIVE_TRANSFERED] || 0;
        const totalGuestsWithConditions = this.parent[FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS] || 0;
        return (
          this.parent[FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED] <=
            positiveTransferredGuests ||
          this.parent[FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED] <= totalGuestsWithConditions
        );
      },
    );

const requiredFields = {
  accessToken: requiredString,
  [FIELD_FACILITY_ID]: requiredString,
  [QUARANTINE_FIELD_ANYTHING_TO_REPORT]: string().max(300, ({ value }) => {
    return `Exceeded 300 characters (currently ${value.length})`;
  }),
};

const requiredFieldsIfUnavailableStaff = {
  [CURRENT_SITUATION]: object({
    [QH_NUMBER_STAFF_UNAVAILABLE]: boolean().required(ERROR_MESSAGE_REQUIRED),
    [QU_NUMBER_HW_MEDICAL_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [QU_NUMBER_HW_SUPPORT_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [QU_NUMBER_HW_POLICE_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [QU_NUMBER_HW_KITCHEN_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [QU_NUMBER_HW_ENFORCEMENT_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [QU_NUMBER_HW_OTHER_UNAVAILABLE]: mixed().when(QH_NUMBER_STAFF_UNAVAILABLE, {
      is: true,
      then: notRequiredInteger(),
    }),
    [FIELD_NUM_BEDS_AVAILABLE]: requiredInteger(),
    [GUESTS]: object()
      .shape({
        [FIELD_NUM_GUESTS]: requiredInteger(),
        [FIELD_NUM_GUESTS_PAST_24HOURS]: validateQuarantinedGuests(),
        [FIELD_NUM_GUESTS_UNDERLYING_CONDITIONS]: validateGuestsWithUnderlying(),
        [FIELD_NUM_GUESTS_PREGNANT]: validatePregnantGuests(),
        [QU_NUMBER_MALES_UNDER5]: validateTotalNumberGuests(),
        [QU_NUMBER_MALES_6TO17]: validateTotalNumberGuests(),
        [QU_NUMBER_MALES_18TO59]: validateTotalNumberGuests(),
        [QU_NUMBER_MALES_60PLUS]: validateTotalNumberGuests(),
        [QU_NUMBER_FEMALES_UNDER5]: validateTotalNumberGuests(),
        [QU_NUMBER_FEMALES_6TO17]: validateTotalNumberGuests(),
        [QU_NUMBER_FEMALES_18TO59]: validateTotalNumberGuests(),
        [QU_NUMBER_FEMALES_60PLUS]: validateTotalNumberGuests(),
        [FIELD_NUM_RETURNED_TESTS]: requiredInteger(),
        [FIELD_NUM_POSITIVE_TESTS]: validateCovidTests(),
        [FIELD_NUM_NEGATIVE_TESTS]: validateCovidTests(),
        [FIELD_PENDING_COVID_TESTS]: requiredInteger(),
        [FIELD_NUM_TESTED_GUESTS]: validateTestedGuests(),
        [FIELD_NUM_POSITIVE_TRANSFERED]: validateTransferredGuests(),
        [FIELD_NUM_WITH_UNDERLYING_CONDITIONS_TRANSFERRED]: validatePositiveWithConditions(),
        [FIELD_NUM_DISCHARGED_COVID_NEGATIVE]: requiredInteger(),
      })
      .required(ERROR_MESSAGE_REQUIRED),
  }).required(),

  [QUARANTINE_CENTER_STOCK]: object().shape(
    OPTIONS_QUARANTINE_CENTER_STOCK.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),

  [QUARANTINE_FIELD_SEVEN_DAY_SUPPLY_OF_PPE]: object().shape(
    OPTIONS_PPE.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),

  [QUARANTINE_FIELD_SUPPLY_STERILISATION]: object().shape(
    OPTIONS_STERILISING_EQUIPMENT.reduce(
      (optionsValidation, { id }) => ({
        ...optionsValidation,
        [id]: boolean(),
      }),
      {},
    ),
  ),
};

const schema = object().shape(requiredFields).shape(requiredFieldsIfUnavailableStaff).noUnknown(true);

export default schema;