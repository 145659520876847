import React from 'react';
import ReactPinInput from 'react-pin-input';
import { Flex, Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { withFormAdapter } from '../helpers/hoc';
import theme from '../theme';
import Label from './Label';

const InputContainer = styled(Box)`
  margin-top: ${theme.spacing(2)};

  input.pincode-input-text:last-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  @media only screen and (max-width: 320px) {
    input.pincode-input-text {
      margin-right: 5px !important;
    }
  }
`;

const inputStyle = {
  borderColor: theme.colors.borderGrey,
  borderRadius: 5,
  fontSize: 18,
  width: 40,
  height: 40,
  marginRight: 8,
  marginLeft: 0,
};

const focusStyle = {
  borderColor: theme.colors.primary,
};

const PinInput = ({ label, name, onChange }) => (
  <Flex flexDirection="column" justifyContent="center">
    <p>Please note this form has been designed to be filled out by one person only. Please fill out the form if you are the In-Charge at the facility.</p>

    <Label htmlFor={name} label={label} />
    <InputContainer>
      <ReactPinInput
        id={name}
        length={4}
        initialValue=""
        onChange={(value) => onChange({ target: { value } })}
        type="numeric"
        inputStyle={inputStyle}
        inputFocusStyle={focusStyle}
      />
    </InputContainer>
  </Flex>
);

export default withFormAdapter(PinInput);
