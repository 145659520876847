import { number, string } from 'yup';

import { ERROR_MESSAGE_REQUIRED } from './constants';

export const requiredNonNumeric = string()
  .matches(/^([^0-9]*)$/, 'Please do not use numbers')
  .required(ERROR_MESSAGE_REQUIRED);

export const requiredString = string().required(ERROR_MESSAGE_REQUIRED);

export const validateNumber = (max = 10000) =>
  number()
    .integer('Please use whole numbers')
    .min(0, 'Please type a whole number above zero')
    .max(max, 'Please choose a lower number');

export const notRequiredInteger = (max = 10000) => validateNumber(max);
export const requiredInteger = (max = 10000) =>
  validateNumber(max).required(ERROR_MESSAGE_REQUIRED);

export const validatePhoneNumber = string()
  .matches(
    /^(?:254|\+254|0)?(7[0-9]{8})$/,
    'Please enter a valid Kenyan Mobile number e.g. 0789012345',
  )
  .required(ERROR_MESSAGE_REQUIRED);

export const validateMCHUL = string()
  .matches(/^\d{0,6}$/, 'Please enter upto six digits for MCHUL code')
  .required(ERROR_MESSAGE_REQUIRED);
