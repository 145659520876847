import styled from 'styled-components';
import theme, { pxToEms } from '../../theme';

const Button = styled.button`
  background: ${({ disabled }) => (disabled ? theme.colors.disabledGrey : theme.colors.primary)};
  border: none;
  border-radius: 45px;
  font-weight: 600;
  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(22)};
  letter-spacing: 0.175em;

  color: ${theme.colors.white};
  padding: 15px 0;
  width: 192px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  cursor: ${({ isLoading }) => (isLoading ? 'progress' : 'pointer')};
  box-shadow: ${({ disabled }) => (disabled ? `0 2px 6px ${theme.colors.buttonDropShadow}` : `0 6px 6px ${theme.colors.buttonDropShadow}`)};

`;

export default Button;
