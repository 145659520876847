import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DailyReportForm from './Form';
import DailyReportThankYou from './ThankYou';
import Footer from '../../components/Footer';
import LoginWithPin from './LoginWithPin';
import NotFound from '../NotFound';
import SessionExpired from './SessionExpired';
import { debug } from '../../utils/debug';
import rg4js from 'raygun4js';
import QuarantineUnitDailyReport from '../QuarantineUnitDailyReport/QuarantineFormDailyReport';
import IsolationUnitDailyReport from '../IsolationUnitDailyReport/IsolationFormDailyReport';
import CommunityHealthUnitWeeklyReport from '../CommunityHealthUnitWeeklyReport/CommunityFormWeeklyReport';
import {
  QUARANTINE_CENTER,
  ISOLATION_CENTER,
  COMMUNITY_HEALTH_CENTER,
} from '../../schema/constants';

const DailyReport = ({ facilityId, facilityType }) => {
  const [pinLoginSuccess, setPinLoginSuccess] = useState(false);
  const [expiryTime, setExpiryTime] = useState(-1);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [reportSubmitSuccess, setReportSubmitSuccess] = useState(false);
  const [authToken, setAuthToken] = useState();
  const onLoginSuccess = useCallback(({ accessToken, tokenExpiry }) => {
    debug('onLoginSuccess', { accessToken, tokenExpiry });
    setAuthToken(accessToken);
    setExpiryTime(tokenExpiry);
    setPinLoginSuccess(true);
  }, []);
  const onSubmitSuccess = useCallback((accessCode) => {
    setReportSubmitSuccess(true);
  }, []);
  const onFormPage = useMemo(() => pinLoginSuccess && !reportSubmitSuccess, [
    pinLoginSuccess,
    reportSubmitSuccess,
  ]);

  const renderFacilityForm = (facilityType) => {
    if (facilityType === QUARANTINE_CENTER) {
      return (
        <QuarantineUnitDailyReport
          accessToken={authToken}
          facilityId={facilityId}
          onSuccess={onSubmitSuccess}
        />
      );
    } else if (facilityType === ISOLATION_CENTER) {
      return (
        <IsolationUnitDailyReport
          accessToken={authToken}
          facilityId={facilityId}
          onSuccess={onSubmitSuccess}
        />
      );
    } else if (facilityType === COMMUNITY_HEALTH_CENTER) {
      return (
        <CommunityHealthUnitWeeklyReport
          accessToken={authToken}
          facilityId={facilityId}
          onSuccess={onSubmitSuccess}
        />
      );
    } else {
      return (
        <DailyReportForm
          accessToken={authToken}
          facilityId={facilityId}
          onSuccess={onSubmitSuccess}
        />
      );
    }
  };

  useEffect(() => {
    const sessionChecker = setInterval(() => {
      debug({ expiryTime: expiryTime * 1000, now: Date.now(), onFormPage });
      if (expiryTime > -1 && onFormPage && Date.now() > expiryTime * 1000) {
        setSessionExpired(true);
        clearInterval(sessionChecker);
      }
    }, 5000);
    return () => clearInterval(sessionChecker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTime, onFormPage]);

  useEffect(
    () =>
      rg4js('setUser', {
        identifier: facilityId,
        isAnonymous: false,
      }),
    [facilityId],
  );

  if (!facilityId) {
    // TODO: return some useful message
    return (
      <>
        <NotFound />
        <Footer />
      </>
    );
  }
  if (sessionExpired) {
    return (
      <>
        <SessionExpired />
        <Footer />
      </>
    );
  }
  if (!pinLoginSuccess && !process.env.REACT_APP_DEVELOPER_MODE) {
    return (
      <>
        <LoginWithPin facilityId={facilityId} onSuccess={onLoginSuccess} />
        <Footer />
      </>
    );
  }
  if (!reportSubmitSuccess) {
    return (
      <>
        {renderFacilityForm(facilityType)}
        <Footer />
      </>
    );
  }
  return (
    <>
      <DailyReportThankYou />
      <Footer />
    </>
  );
};

export default DailyReport;
