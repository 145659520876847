import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import Button from '../../components/Button/index';
import SectionContent from '../../components/Section/Content';
import Section from '../../components/Section/index';
import InputArray from '../../components/Input/Array';
import Label from '../../components/Label';
import {
  FIELD_IN_CHARGE_MEMBERS,
  QU_FACILITY_NAME,
} from '../../schema/constants';
import InputText from '../../components/Input/Text';
import { Field } from 'formik';
import { isDeveloperMode } from '../../utils/debug';

const InCharges = ({ onContinue, errors, values }) => {
  const canContinue = values[FIELD_IN_CHARGE_MEMBERS] && !errors[FIELD_IN_CHARGE_MEMBERS] && values[QU_FACILITY_NAME] && !errors[QU_FACILITY_NAME];
  return (
    <Box>
      <Section title="Quarantine Unit Profiling" />
      <SectionContent>
        <p>The County MoH has partnered with Elephant Healthcare to help manage the coronavirus pandemic.</p>
        <p>In order to understand your facility's needs, County MoH requests that your facility completes a short daily form on your facility's resource levels.</p>
        <p>Please provide the name and mobile numbers of all facility in-charges who should receive the form. The form only needs to be completed by one in charge each day.</p>
        <p>By submitting mobile numbers below, you confirm that consent is provided for Elephant Healthcare to contact each individual and process their data.</p>


        <Field
          label="What is the name of your facility?"
          name={QU_FACILITY_NAME}
          component={InputText}
          value={values[QU_FACILITY_NAME]}
          placeholder={"Facility name"}
          clearValueOnUnmount={false}
          className={QU_FACILITY_NAME}
        />

        <Label label="Please add your name and number below, and the contact details of any other in-charges at your facility." />
        <InputArray
          elements={values[FIELD_IN_CHARGE_MEMBERS]}
          name={FIELD_IN_CHARGE_MEMBERS}
          inputs={[
            {
              id: 'name',
              label: ['Your name', 'In-charge name'],
              clearValueOnUnmount: false,
            },
            {
              id: 'mobileNumber',
              label: ['Your mobile number', 'In-charge mobile phone number'],
              type: 'phone',
              placeholder: 'please enter mobile number',
              inputMode: 'tel',
              clearValueOnUnmount: false,
            },
          ]}
          buttonLabel="Add another In-Charge"
        />

        {isDeveloperMode && (
          <>
            <p>Developer mode enabled</p>
            <p>{JSON.stringify(values)}</p>
            <p>{JSON.stringify(errors)}</p>
          </>
        )}
        <Flex justifyContent="flex-end" mt={40}>
          <Button id="continue-in-charges" type="submit" onClick={onContinue} disabled={!canContinue }>
            Continue
          </Button>
        </Flex>
      </SectionContent>
    </Box>
  );
};

export default InCharges;
