import { useEffect, memo, useState } from 'react';
import { connect } from 'formik';
import debounce from 'lodash.debounce';
import isEqual from 'react-fast-compare';
import pickBy from 'ramda.pickby';
import { handleError } from '../../utils/debug';

const comparator = ({ formik: oldFormik }, { formik: newFormik }) =>
isEqual(oldFormik.values, newFormik.values);

const Persister = ({ name, ignoredKeys, formik: { values } }) => {
  const [shouldTryPersistence, setShouldTryPersistence] = useState(true);
  const saveForm = debounce(() => {
    const toStore = pickBy((_, key) => !ignoredKeys.includes(key), values);
    try {
      if (typeof window.sessionStorage !== 'undefined') {
        window.sessionStorage.setItem(name, JSON.stringify(toStore));
      }
    } catch (securityError) {
      console.warn('Unable to perist');
      handleError(securityError);
      setShouldTryPersistence(false);
    }
  }, 300);

  useEffect(() => {
    if (shouldTryPersistence) {
      saveForm();
    }
  }, [values, saveForm, shouldTryPersistence]);

  return null;
};

const WrapperPersister = connect(
  memo(Persister, comparator)
);

export { WrapperPersister as Persister };
