import React, {useState, useEffect} from 'react';
import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import theme, { pxToEms } from '../theme';
import CallbackForm from './Callback/index';
import { scrollToFooter } from '../helpers/index';

const FooterContents = styled.footer`
 background: white;
`;

const BannerButton = styled.button`
  background:#459EAD;
  padding: ${theme.spacing(3)} 0;
  cursor: pointer;
  color: white;
  display:block;
  width: 100%;
  font-weight: bold;
  font-size: ${pxToEms(16)};
  text-transform: uppercase;
  
  &.sent {
    background: #80E0A7;
  }
`;

const Footer = () => {
  const [callbackState, setCallbackState] = useState('closed');
  const [bannerMessage, setBannerMessage] = useState('Contact Elephant');
  const [showCallbackForm, setShowCallbackForm] = useState(false);

  const onSuccess = () => {
    setCallbackState('sent');
    setBannerMessage('Thank you for contacting us');
    setTimeout(() => {
      setShowCallbackForm(false);
    }, 5000)
  };

  const toggleOpen = () => {
    setShowCallbackForm(!showCallbackForm);
    setCallbackState('closed');
    setBannerMessage('Contact Elephant');
    scrollToFooter();
  };

  useEffect(() => {
    if(showCallbackForm) {
      scrollToFooter();
    }
  }, [showCallbackForm]);

 return (
   <FooterContents>
    <Box>
        <BannerButton className={callbackState} onClick={toggleOpen}>{bannerMessage}</BannerButton>
        {showCallbackForm && (
          <CallbackForm onSuccess={onSuccess} />
        )}
    </Box>
  </FooterContents>
)};

export default Footer;