import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import Button from './Button/index';
import ErrorMessage from './ErrorMessage';

const ErrorContainer = styled(Box)`
  margin-bottom: 10px;
  margin-right: 5px;

  ${ErrorMessage} {
    text-align: right;
  }
`;

const Submission = ({ isSubmitting, handleSubmit, canSubmit, error, text, id }) => (
  <Flex justifyContent="flex-end" mt={40}>
    <Box>
      <ErrorContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </ErrorContainer>
      <Button id={id} type="submit" onClick={handleSubmit} disabled={!canSubmit} isLoading={isSubmitting}>
        {text}
      </Button>
    </Box>
  </Flex>
);

export default Submission;
