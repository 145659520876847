import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import InputGroup from './Group';
import Label from '../Label';
import theme, { pxToEms } from '../../theme';
import { withFormAdapter } from '../../helpers/hoc';
import ErrorMessage from '../ErrorMessage';
import Input from 'react-phone-number-input/input';

const inputCss = css`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 5px;

  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(19)};
  padding: ${theme.spacing(2)};
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: 0 0 0pt 2pt ${theme.colors.coreTurquoise};
  }
`;

const PhoneInput = styled.input`
  ${inputCss};
`;


const Relative = styled(Box)`
  position: relative;
`;
const ActionsContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 5px;
`;

const LabelledPhoneInput = ({
                             name,
                             label,
                             asTextArea,
                             onChange,
                             placeholder,
                             value,
                             errorMessage,
                             disabled,
                             actions,
                             setFieldValue,
                             ...rest
                           }) => {

  const handleValueChange = (e) => {
    setFieldValue(name, e);
  };

  return (
    <InputGroup>
      <Label htmlFor={name} label={label} />

      <Relative>
        <Input
          id={name}
          inputComponent={PhoneInput}
          type="text"
          autocomplete="tel"
          placeholder={placeholder}
          onChange={handleValueChange}
          disabled={disabled}
          value={value}
          defaultCountry="KE"
          {...rest}
        />
        <ActionsContainer>{actions}</ActionsContainer>
      </Relative>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </InputGroup>
  );
};

export default withFormAdapter(LabelledPhoneInput);
