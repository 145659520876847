import React from 'react';
import styled from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import theme, { pxToEms } from '../theme';

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(19)};
`;

const Label = ({ label, htmlFor }) => (
  <Box mb={theme.spacing(1)}>
    <StyledLabel htmlFor={htmlFor}>{label}</StyledLabel>
  </Box>
);

export default Label;
