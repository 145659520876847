import styled from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import theme from '../../theme';

const SectionContent = styled(Box)`
  padding: ${theme.gutter.vertical}px ${theme.gutter.horizontal}px;
  max-width: 30rem;
`;

export default SectionContent;
