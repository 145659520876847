import {
  CS_CALLBACK_NAME,
  CS_CALLBACK_PHONE,
  CS_CALLBACK_COUNTY
} from '../constants';

import { object } from 'yup';
import { requiredString, validatePhoneNumber } from '../utils';

const schema = object().shape({
  [CS_CALLBACK_NAME]: requiredString,
  [CS_CALLBACK_PHONE]: validatePhoneNumber,
  [CS_CALLBACK_COUNTY]: requiredString,
})

export default schema;