import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import Label from '../Label';
import InputGroup from './Group';
import { withFormAdapter } from '../../helpers/hoc';

const StyledLabel = styled(FormControlLabel)`
  span {
    font-size: 1em;
  }
  display: block;
`;

export const InputCheckboxes = ({ label, name, boxes, onChange, fontSize, value }) => {
  const inputProps = {
    color: 'primary',
    onChange,
    fontSize,
  };
  return (
    <InputGroup>
      <Label htmlFor={name} label={label} />

      <Flex flexDirection="row">
        <Box>
          {boxes.map(({ id, label }) => (
            <StyledLabel
              key={id}
              label={label}
              control={<Checkbox {...inputProps} checked={value?.[id]} name={`${name}.${id}`} data-testid={"check-button-test"}/>}
            />
          ))}
        </Box>
      </Flex>
    </InputGroup>
  );
};

export default withFormAdapter(InputCheckboxes);
