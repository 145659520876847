import React from 'react';
import { Route, Switch } from 'wouter';
import { overrideMuiStyles, withMuiTheme } from './helpers/materialUi';
import FacilityProfiling from './pages/FacilityProfiling/index';
import DailyReport from './pages/DailyReport/index';
import NotFound from './pages/NotFound';
import './App.css';
import ErrorBoundary from './pages/ErrorBoundary';
import { ISOLATION_CENTER, QUARANTINE_CENTER, COMMUNITY_HEALTH_CENTER } from './schema/constants';
import QuarantineUnitProfiling from './pages/QuarantineUnitProfiling';
import IsolationUnitProfiling from './pages/IsolationUnitProfiling';
import CHUProfiling from './pages/ChuProfiling';

const App = () => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/facility-profiling/:facilityId">
          {({ facilityId }) => <FacilityProfiling facilityId={facilityId} />}
        </Route>
        <Route path="/quarantine-unit-profiling/:facilityId">
          {({ facilityId }) => <QuarantineUnitProfiling facilityId={facilityId} />}
        </Route>
        <Route path="/isolation-unit-profiling/:facilityId">
          {({ facilityId }) => <IsolationUnitProfiling facilityId={facilityId} />}
        </Route>
        <Route path="/daily-report/:facilityId">
          {({ facilityId }) => <DailyReport facilityId={facilityId} />}
        </Route>
        <Route path="/quarantine-unit-daily/:facilityId">
          {({ facilityId }) => (
            <DailyReport facilityId={facilityId} facilityType={QUARANTINE_CENTER} />
          )}
        </Route>
        <Route path="/isolation-unit-daily/:facilityId">
          {({ facilityId }) => (
            <DailyReport facilityId={facilityId} facilityType={ISOLATION_CENTER} />
          )}
        </Route>
        <Route path="/ch-unit-weekly/:facilityId">
          {({ facilityId }) => (
            <DailyReport facilityId={facilityId} facilityType={COMMUNITY_HEALTH_CENTER} />
          )}
        </Route>
        <Route path="/ch-unit-profiling/:facilityId">
          {({ facilityId }) => <CHUProfiling facilityId={facilityId} />}
        </Route>
        <Route path="/:rest*">
          <NotFound />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default withMuiTheme(overrideMuiStyles(App));
