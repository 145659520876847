import React from 'react';
import styled from 'styled-components';
import { Box } from 'reflexbox/styled-components';
import theme from '../../theme';
import Heading from './Heading';

const SectionContainer = styled(Box)`
  background-color: ${theme.colors.primary};
  padding: ${theme.spacing(3)} ${theme.spacing(4)};
`;

const Section = ({ title }) => (
  <SectionContainer>
    <Heading color="white">{title}</Heading>
  </SectionContainer>
);

export default Section;
