import {
  FIELD_FACILITY_ID,
  CURRENT_SITUATION,
  PAST_DAY,
  ERROR_MESSAGE_REQUIRED,
  CHU_NUMBER_CHU_VISITED,
  CHU_LEVEL_MASK_COMPLIANCE,
  CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE,
  CHU_LEVEL_HAND_WASHING_COMPLIANCE,
  OPTIONS_CHU_COMPLIANCE,
  CHU_NUMBER_IEC_MATERIALS,
  CHU_NUMBER_VISITORS,
  CHU_NUMBER_LEAVING_VISITORS,
  CHU_NUMBER_HOUSEHOLD_VISITED,
  CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES,
  CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS,
  CHU_NUMBER_SUSPECTED_COVID,
  CHU_NUMBER_TRACED_CONTACTS,
  CHU_NUMBER_REFERRALS,
  CHU_PATIENTS_HOME_ISOLATION,
  CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE,
  CHU_PATIENT_COMMENCED_HOME_ISOLATION,
  CHU_NUMBER_HOUSEHOLDS_TRAINED,
  CHU_PATIENTS_REFERRED_HOSPITAL,
  CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS,
  CHU_NUMBER_CONTACTS_MONITORED,
  CHU_HOUSEHOLDS_ADEQUATE_PPE,
  CHU_PATIENTS_COMPLETED_HOME_ISOLATION,
  CHU_NUMBER_SELF_QUARANTINE_TOTAL,
  CHU_NUMBER_SELF_QUARANTINE_WEEK,
  CHU_NUMBER_VISITS,
  CHU_NUMBER_DEFAULTED_SELF_QUARANTINE,
} from '../constants';
import { object, mixed } from 'yup';
import { requiredInteger, requiredString, validateNumber } from '../utils';

const validateHouseholdsTrained = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more trained households than the total number of households visited this week`,
      `Number cant be greater than total number of households visited by CHVs this week`,
      function () {
        const householdsTrained = this.parent[CHU_NUMBER_HOUSEHOLDS_TRAINED] || 0;
        const householdsVisited = this.parent[CHU_NUMBER_HOUSEHOLD_VISITED] || 0;
        return householdsTrained <= householdsVisited;
      },
    );
const validateHouseholdsIEC = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more households than the total number of households visited this week`,
      `Number cant be greater than total number of households visited by CHVs this week`,
      function () {
        const householdsIEC = this.parent[CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS] || 0;
        const householdsVisited = this.parent[CHU_NUMBER_HOUSEHOLD_VISITED] || 0;
        return householdsIEC <= householdsVisited;
      },
    );
const validateHouseholdsHandWashing = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more households than the total number of households visited this week`,
      `Number cant be greater than total number of households visited by CHVs this week`,
      function () {
        const householdsHandWashing =
          this.parent[CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES] || 0;
        const householdsVisited = this.parent[CHU_NUMBER_HOUSEHOLD_VISITED] || 0;
        return householdsHandWashing <= householdsVisited;
      },
    );

const validateHomeIsolationPhone = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more households  than total number of households on home-based isolation and care`,
      `Number cant be greater than total number of households on home-based isolation and care`,
      function () {
        const homeIsolation = this.parent[CHU_PATIENTS_HOME_ISOLATION] || 0;
        const homeIsolationWithPhone = this.parent[CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE] || 0;
        return homeIsolationWithPhone <= homeIsolation;
      },
    );

const validateHomeIsolationCommencement = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more households  than total number of households on home-based isolation and care`,
      `Number cant be greater than total number of households on home-based isolation and care`,
      function () {
        const homeIsolation = this.parent[CHU_PATIENTS_HOME_ISOLATION] || 0;
        const homeIsolationCommenced = this.parent[CHU_PATIENT_COMMENCED_HOME_ISOLATION] || 0;
        return homeIsolationCommenced <= homeIsolation;
      },
    );

const validateSelfQuarantineWeekly = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `No more people starting self-quarantine than total number in self-quarantine`,
      `People starting quarantine this week cant be greater than total number in self-quarantine`,
      function () {
        const selfIsolationthisWeek = this.parent[CHU_NUMBER_SELF_QUARANTINE_WEEK] || 0;
        const selfIsolationtotal = this.parent[CHU_NUMBER_SELF_QUARANTINE_TOTAL] || 0;
        return selfIsolationthisWeek <= selfIsolationtotal;
      },
    );

const complianceLevel = () => mixed().oneOf(OPTIONS_CHU_COMPLIANCE.map(({ value }) => value));

const requiredCompliance = () => complianceLevel().required(ERROR_MESSAGE_REQUIRED);

export const generateSchema = () => {
  return object().shape({
    accessToken: requiredString,
    [FIELD_FACILITY_ID]: requiredString,
    [CURRENT_SITUATION]: object()
      .shape({
        [CHU_NUMBER_CHU_VISITED]: requiredInteger(),
        [CHU_LEVEL_MASK_COMPLIANCE]: requiredCompliance(),
        [CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE]: requiredCompliance(),
        [CHU_LEVEL_HAND_WASHING_COMPLIANCE]: requiredCompliance(),
        [CHU_NUMBER_IEC_MATERIALS]: requiredInteger(),
        [CHU_NUMBER_HOUSEHOLD_VISITED]: requiredInteger(),
        [CHU_NUMBER_SELF_QUARANTINE_TOTAL]: requiredInteger(),
        [CHU_NUMBER_SELF_QUARANTINE_WEEK]: validateSelfQuarantineWeekly(),
        [CHU_NUMBER_VISITS]: requiredInteger(),
        [CHU_NUMBER_DEFAULTED_SELF_QUARANTINE]: requiredInteger(),
        [CHU_NUMBER_SUSPECTED_COVID]: requiredInteger(),
        [CHU_NUMBER_TRACED_CONTACTS]: requiredInteger(),
        [CHU_NUMBER_REFERRALS]: requiredInteger(),
        [CHU_PATIENTS_HOME_ISOLATION]: requiredInteger(),
        [CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE]: validateHomeIsolationPhone(),
        [CHU_PATIENT_COMMENCED_HOME_ISOLATION]: validateHomeIsolationCommencement(),
        [CHU_NUMBER_HOUSEHOLDS_TRAINED]: validateHouseholdsTrained(),
        [CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES]: validateHouseholdsHandWashing(),
        [CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS]: validateHouseholdsIEC(),
        [CHU_PATIENTS_REFERRED_HOSPITAL]: requiredInteger(),
        [CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS]: requiredInteger(),
        [CHU_NUMBER_CONTACTS_MONITORED]: requiredInteger(),
        [CHU_HOUSEHOLDS_ADEQUATE_PPE]: requiredInteger(),
        [CHU_PATIENTS_COMPLETED_HOME_ISOLATION]: requiredInteger(),
      })
      .required(ERROR_MESSAGE_REQUIRED),

    [PAST_DAY]: object()
      .shape({
        [CHU_NUMBER_VISITORS]: requiredInteger(),
        [CHU_NUMBER_LEAVING_VISITORS]: requiredInteger(),
      })
      .required(ERROR_MESSAGE_REQUIRED),
  });
};
