import React, { useCallback } from 'react';
import { Box } from 'reflexbox/styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import theme from '../../theme';
import Label from '../Label';
import InputGroup from './Group';
import { withFormAdapter } from '../../helpers/hoc';

export const InputRadio = ({ value, name, label, options, onChange, className }) => {
  const onChangeParsed = useCallback(
    (e) => {
      let eventValue = e.target.value;
      if (eventValue === 'false') {
        eventValue = false;
      }
      if (eventValue === 'true') {
        eventValue = true;
      }
      onChange({
        ...e,
        target: {
          ...e.target,
          id: name,
          value: eventValue,
        },
      });
    },
    [name, onChange],
  );
  return (
    <InputGroup>
      <Label htmlFor={name} label={label} />
      <RadioGroup row aria-label={label} name={name} value={value} onChange={onChangeParsed}>
        {options.map((option) => (
          <Box ml={theme.spacing(1)} key={option.value}>
            <FormControlLabel
              value={option.value}
              control={
                <Radio
                  color="primary"
                  checked={option.value === value}
                  data-testid={`${className}_${option.value}`}
                />
              }
              label={option.label}
            />
          </Box>
        ))}
      </RadioGroup>
    </InputGroup>
  );
};

export default withFormAdapter(InputRadio);
