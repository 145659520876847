import {
  ERROR_MESSAGE_REQUIRED,
  FIELD_FACILITY_ID,
  FIELD_IN_CHARGE_MEMBERS,
  ISO_PROFILE_WORKERS,
  ISO_COVID,
  ISO_PROFILE_NUMBER_MED_STAFF,
  ISO_PROFILE_NUMBER_CLINICAL_STAFF,
  ISO_PROFILE_NUMBER_NURSES,
  ISO_PROFILE_NUMBER_LAB_TECHNICIANS,
  ISO_PROFILE_NUMBER_OTHER_STAFF,
  ISO_PROFILE_NUMBER_NON_TECH_STAFF,
  ISO_PROFILE_NUMBER_BEDS,
  ISO_PROFILE_NUMBER_ICU_BEDS,
  ISO_PROFILE_NUMBER_COVID,
  ISO_PROFILE_NUMBER_MALES_UNDER5,
  ISO_PROFILE_NUMBER_MALES_6TO17,
  ISO_PROFILE_NUMBER_MALES_18TO59,
  ISO_PROFILE_NUMBER_MALES_60PLUS,
  ISO_PROFILE_NUMBER_FEMALES_UNDER5,
  ISO_PROFILE_NUMBER_FEMALES_6TO17,
  ISO_PROFILE_NUMBER_FEMALES_18TO59,
  ISO_PROFILE_NUMBER_FEMALES_60PLUS,
  ISO_PROFILE_NUMBER_UNDERLYING,
  ISO_PROFILE_NUMBER_TREATED_ICU,
  ISO_PROFILE_NUMBER_TESTED_ICU,
  ISO_PROFILE_NUMBER_RECOVERIES,
  ISO_PROFILE_NUMBER_DEATHS,
} from '../constants';
import { array, object } from 'yup';
import {
  requiredInteger,
  requiredNonNumeric,
  requiredString,
  validatePhoneNumber,
  validateNumber,
} from '../utils';

const validateTotalNumberPatients = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more patients than total patients`,
      `Sum of male and female patients must be equal to the number of total patients`,
      function () {
        const malesUnder5 = this.parent[ISO_PROFILE_NUMBER_MALES_UNDER5] || 0;
        const malesMoreThan6 = this.parent[ISO_PROFILE_NUMBER_MALES_6TO17] || 0;
        const malesMoreThan18 = this.parent[ISO_PROFILE_NUMBER_MALES_18TO59] || 0;
        const malesMoreThan60 = this.parent[ISO_PROFILE_NUMBER_MALES_60PLUS] || 0;
        const femalesUnder5 = this.parent[ISO_PROFILE_NUMBER_FEMALES_UNDER5] || 0;
        const femalesMoreThan6 = this.parent[ISO_PROFILE_NUMBER_FEMALES_6TO17] || 0;
        const femalesMoreThan18 = this.parent[ISO_PROFILE_NUMBER_FEMALES_18TO59] || 0;
        const femalesMoreThan60 = this.parent[ISO_PROFILE_NUMBER_FEMALES_60PLUS] || 0;
        const allPatients =
          malesUnder5 +
          malesMoreThan6 +
          malesMoreThan18 +
          malesMoreThan60 +
          femalesUnder5 +
          femalesMoreThan6 +
          femalesMoreThan18 +
          femalesMoreThan60;
        return this.parent[ISO_PROFILE_NUMBER_COVID] === allPatients;
      },
    );

const validatePatientsWithUnderlying = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more patients with underlying conditions than total patients`,
      `Patients with underlying conditions can not exceed the number of total patients`,
      function () {
        const patientsWithUnderlyingConditions = this.parent[ISO_PROFILE_NUMBER_UNDERLYING] || 0;
        return this.parent[ISO_PROFILE_NUMBER_COVID] >= patientsWithUnderlyingConditions;
      },
    );

const validateRecoveries = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more recovered patients than total patients`,
      `Recovered patients can not exceed the number of total patients`,
      function () {
        const patientsWithUnderlyingConditions = this.parent[ISO_PROFILE_NUMBER_RECOVERIES] || 0;
        return this.parent[ISO_PROFILE_NUMBER_COVID] >= patientsWithUnderlyingConditions;
      },
    );

const validateDeaths = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more dead patients than total patients`,
      `Number of COVID deaths can not exceed the number of total patients`,
      function () {
        const patientsWithUnderlyingConditions = this.parent[ISO_PROFILE_NUMBER_DEATHS] || 0;
        return this.parent[ISO_PROFILE_NUMBER_COVID] >= patientsWithUnderlyingConditions;
      },
    );

export const generateSchema = () => {
  return object().shape({
    [FIELD_FACILITY_ID]: requiredString,
    [FIELD_IN_CHARGE_MEMBERS]: array()
      .of(
        object().shape({
          name: requiredNonNumeric,
          mobileNumber: validatePhoneNumber,
        }),
      )
      .min(1)
      .required(ERROR_MESSAGE_REQUIRED),

    [ISO_PROFILE_NUMBER_BEDS]: requiredInteger(),
    [ISO_PROFILE_NUMBER_ICU_BEDS]: requiredInteger(),

    [ISO_PROFILE_WORKERS]: object()
      .shape({
        [ISO_PROFILE_NUMBER_MED_STAFF]: requiredInteger(),
        [ISO_PROFILE_NUMBER_CLINICAL_STAFF]: requiredInteger(),
        [ISO_PROFILE_NUMBER_NURSES]: requiredInteger(),
        [ISO_PROFILE_NUMBER_LAB_TECHNICIANS]: requiredInteger(),
        [ISO_PROFILE_NUMBER_OTHER_STAFF]: requiredInteger(),
        [ISO_PROFILE_NUMBER_NON_TECH_STAFF]: requiredInteger(),
      })
      .required(ERROR_MESSAGE_REQUIRED),

    [ISO_COVID]: object()
      .shape({
        [ISO_PROFILE_NUMBER_COVID]: requiredInteger(),
        [ISO_PROFILE_NUMBER_MALES_UNDER5]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_MALES_6TO17]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_MALES_18TO59]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_MALES_60PLUS]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_FEMALES_UNDER5]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_FEMALES_6TO17]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_FEMALES_18TO59]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_FEMALES_60PLUS]: validateTotalNumberPatients(),
        [ISO_PROFILE_NUMBER_UNDERLYING]: validatePatientsWithUnderlying(),
        [ISO_PROFILE_NUMBER_TREATED_ICU]: requiredInteger(),
        [ISO_PROFILE_NUMBER_TESTED_ICU]: requiredInteger(),
        [ISO_PROFILE_NUMBER_RECOVERIES]: validateRecoveries(),
        [ISO_PROFILE_NUMBER_DEATHS]: validateDeaths(),
      })
      .required(ERROR_MESSAGE_REQUIRED),
  });
};
