import styled from 'styled-components';
import theme, { pxToEms } from '../theme';

const Message = styled.p`
  color: ${theme.colors.black};
  font-weight: bold;
  font-size: ${pxToEms(16)};
  line-height: ${pxToEms(26)};
  text-align: center;
  margin: 0 0 ${theme.spacing(8)} 0;
`;

export default Message;
