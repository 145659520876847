import {
  CURRENT_SITUATION,
  ERROR_MESSAGE_REQUIRED,
  FIELD_ANYTHING_TO_REPORT,
  FIELD_DEATHS_CONFIRMED_CORONAVIRUS,
  FIELD_DEATHS_SUSPECTED_CORONAVIRUS,
  FIELD_DEATHS_TOTAL,
  FIELD_EMERGENCY_TROLLEY_LEVEL,
  FIELD_ESSENTIAL_MEDS_STOCK,
  FIELD_FACILITY_ID,
  FIELD_FACILITY_WAS_OPEN,
  FIELD_NUM_BEDS_AVAILABLE,
  FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN,
  FIELD_NUM_CORONAVIRUS_RECOVERIES,
  FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE,
  FIELD_NUM_ICU_BEDS_AVAILABLE,
  FIELD_NUM_PATIENTS_SEEN,
  FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION,
  FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN,
  FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS,
  FIELD_OPERATIONAL_THERMAL_GUN,
  FIELD_SEVEN_DAY_SUPPLY_OF_PPE,
  FIELD_SUPPLY_CORONAVIRUS_TESTS,
  FIELD_SUPPLY_STERILISATION,
  FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS,
  FIELD_TRACED_CONTACTS,
  OPTIONS_PPE,
  OPTIONS_STERILISING_EQUIPMENT,
  OPTIONS_STOCK,
  PAST_DAY,
  FIELD_FACILITY_TYPE
} from '../constants';
import { boolean, mixed, object, string } from 'yup';
import { requiredInteger, requiredString, validateNumber } from '../utils';

const requiredFields = {
  accessToken: requiredString,
  [FIELD_FACILITY_ID]: requiredString,
  [FIELD_FACILITY_TYPE]: requiredString,
  [FIELD_ANYTHING_TO_REPORT]: string().max(300, ({ value }) => {
    return `Exceeded 300 characters (currently ${value.length})`;
  }),
};

const conditionalBoolean = () =>
  boolean().when(FIELD_FACILITY_WAS_OPEN, {
    is: true,
    then: boolean().required(ERROR_MESSAGE_REQUIRED),
  });

const stockLevel = () =>
  mixed()
    .oneOf(OPTIONS_STOCK.map(({ value }) => value))

const validateCovidDeaths = () => mixed().when(FIELD_DEATHS_TOTAL, {
  is: deaths => deaths > 0,
  then: validateNumber().required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more coronavirus deaths than total deaths`,
      `Confirmed coronavirus deaths and suspected coronavirus deaths should not be more than total deaths`,
      function () {
        const confirmedCovidDeaths = this.parent[FIELD_DEATHS_CONFIRMED_CORONAVIRUS] || 0;
        const suspectedCovidDeaths = this.parent[FIELD_DEATHS_SUSPECTED_CORONAVIRUS] || 0;
        return this.parent[FIELD_DEATHS_TOTAL] >= confirmedCovidDeaths + suspectedCovidDeaths;
      })
})

const requiredStockLevel = () =>
  stockLevel()
    .required(ERROR_MESSAGE_REQUIRED);

const requiredFieldsIfOpen = {
  [CURRENT_SITUATION]:
    object({
      [FIELD_FACILITY_WAS_OPEN]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_NUM_HEALTH_WORKERS_UNAVAILABLE]: mixed().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: requiredInteger()
      }),
      [FIELD_NUM_BEDS_AVAILABLE]: mixed().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: requiredInteger()
      }),
      [FIELD_NUM_ICU_BEDS_AVAILABLE]: mixed().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: requiredInteger()
      }),
      [FIELD_EMERGENCY_TROLLEY_LEVEL]: stockLevel().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: requiredStockLevel()
      }),
      [FIELD_ESSENTIAL_MEDS_STOCK]: stockLevel().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: requiredStockLevel()
      }),
      [FIELD_OPERATIONAL_THERMAL_GUN]: mixed().when(FIELD_FACILITY_WAS_OPEN, {
        is: true,
        then: conditionalBoolean()
      }),
    }).required(),
  [PAST_DAY]: object().when(`${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`, {
    is: true,
    then: object()
      .shape({
        [FIELD_NUM_PATIENTS_SEEN]: requiredInteger(),
        [FIELD_NUM_SUSPECTED_COVID_PATIENTS_SEEN]: requiredInteger(),
        [FIELD_NUM_SUSPECTED_COVID_PATIENTS_WITH_UNDERLYING_CONDITIONS]: requiredInteger(),
        [FIELD_NUM_CONFIRMED_COVID_PATIENTS_SEEN]: requiredInteger(),
        [FIELD_NUM_CORONAVIRUS_RECOVERIES]: requiredInteger(),
        [FIELD_NUM_SUSPECTED_CORONAVIRUS_RELEASED_FROM_ISOLATION]: requiredInteger(),
        [FIELD_DEATHS_TOTAL]: requiredInteger(),
        [FIELD_DEATHS_SUSPECTED_CORONAVIRUS]: validateCovidDeaths(),
        [FIELD_DEATHS_CONFIRMED_CORONAVIRUS]: validateCovidDeaths(),
        [FIELD_TOTAL_CONTACTS_FROM_CORONAVIRUS]: requiredInteger(),
        [FIELD_TRACED_CONTACTS]: requiredInteger(),
      })
      .required(),
    otherwise: object({
      [FIELD_DEATHS_TOTAL]: validateNumber(),
      [FIELD_DEATHS_SUSPECTED_CORONAVIRUS]: validateCovidDeaths(),
      [FIELD_DEATHS_CONFIRMED_CORONAVIRUS]: validateCovidDeaths(),
    })
  }),
  [FIELD_SEVEN_DAY_SUPPLY_OF_PPE]: object().when(`${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`, {
    is: true,
    then: object().shape(
      OPTIONS_PPE.reduce(
        (optionsValidation, { id }) => ({
          ...optionsValidation,
          [id]: boolean(),
        }),
        {},
      ),
    ),
  }),
  [FIELD_SUPPLY_CORONAVIRUS_TESTS]: conditionalBoolean(),
  [FIELD_SUPPLY_STERILISATION]: object().when(`${CURRENT_SITUATION}.${FIELD_FACILITY_WAS_OPEN}`, {
    is: true,
    then: object().shape(
      OPTIONS_STERILISING_EQUIPMENT.reduce(
        (optionsValidation, { id }) => ({
          ...optionsValidation,
          [id]: boolean(),
        }),
        {},
      ),
    ),
  }),

};

const schema = object().shape(requiredFields).shape(requiredFieldsIfOpen).noUnknown(true);

export default schema;
