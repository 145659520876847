import {
  COVID,
  FACILITY_DETAILS,
  FIELD_AVERAGE_DAILY_PATIENTS,
  FIELD_CATCHMENT_POPULATION,
  FIELD_COMMUNITY_HEALTH_UNIT,
  FIELD_COVID_HOLDING_AREA,
  FIELD_DATE_LAST_DELIVERY,
  FIELD_DOES_COVID_TESTING,
  FIELD_ISOLATION_FACILITY,
  FIELD_MATERNAL_DELIVERIES,
  FIELD_NUMBER_BEDS,
  FIELD_NUMBER_CORONAVIRUS_BEDS,
  FIELD_NUMBER_HW_CLINICAL,
  FIELD_NUMBER_HW_HEALTH_ASSISTANTS,
  FIELD_NUMBER_HW_LABTECH,
  FIELD_NUMBER_HW_MEDICAL,
  FIELD_NUMBER_HW_NON_TECHNICAL,
  FIELD_NUMBER_HW_NURSE,
  FIELD_NUMBER_HW_OTHER_TECHNICAL,
  FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF,
  FIELD_NUMBER_ICU_BEDS,
  FIELD_OPERATIONAL_THERMAL_GUN,
  FIELD_QUARANTINE_FACILITY,
  FIELD_SERVICES_AVAILABLE,
  HEALTH_WORKERS,
  HIV,
  MATERNITY,
  TUBERCOLOSIS,
  FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE,
  FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE,
  FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE,
} from '../../schema/constants';
import { Field, getIn } from 'formik';
import React, { Suspense, lazy } from 'react';

import { Box } from 'reflexbox/styled-components';
import InputRadio from '../../components/Input/Radio';
import InputText from '../../components/Input/Text';
import Label from '../../components/Label';
import Section from '../../components/Section/index';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import { isDeveloperMode } from '../../utils/debug';
import { yesOrNoOptions } from '../../helpers/inputs';

const DatePicker = lazy(() =>
  import(/* webpackChunkName: "pages" */ '../../components/Input/Date'),
);

const DatePickerLazyLoaded = (props) => (
  <Suspense fallback={<Label label={props.label} />}>
    <DatePicker {...props} />
  </Suspense>
);

const Details = ({ canSubmit, isSubmitting, handleSubmit, values, submissionError, errors }) => (
  <Box>
    <Section title="Health workers" />
    <SectionContent>
      <Field
        label="How many medical officers are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_MEDICAL}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_MEDICAL}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_MEDICAL}`)}
      />
      <Field
        label="How many clinical officers are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_CLINICAL}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_CLINICAL}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_CLINICAL}`)}
      />
      <Field
        label="How many nurses are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_NURSE}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_NURSE}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_NURSE}`)}
      />
      <Field
        label="How many lab technicians are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_LABTECH}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_LABTECH}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_LABTECH}`)}
      />
      <Field
        label="How many other technical staff are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_OTHER_TECHNICAL}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_OTHER_TECHNICAL}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_OTHER_TECHNICAL}`)}
      />
      <Field
        label="How many other non-technical staff are employed at your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_NON_TECHNICAL}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_NON_TECHNICAL}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_NON_TECHNICAL}`)}
      />
      <Field
        label="Do you have Community Health Assistants (CHA)?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_HEALTH_ASSISTANTS}`}
        component={InputRadio}
        className={`fa-${FIELD_NUMBER_HW_HEALTH_ASSISTANTS}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_HEALTH_ASSISTANTS}`)}
        options={yesOrNoOptions}
      />
      <Field
        label="How many of your staff have been trained on coronavirus in your facility?"
        name={`${HEALTH_WORKERS}.${FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF}`}
        value={getIn(values, `${HEALTH_WORKERS}.${FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF}`)}
      />
    </SectionContent>

    <Section title="Capacity &amp; Services" />
    <SectionContent>
      <Field
        label="What is your facility's catchment population? Please give best estimate."
        name={`${FACILITY_DETAILS}.${FIELD_CATCHMENT_POPULATION}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_CATCHMENT_POPULATION}`}
        value={getIn(values, `${FACILITY_DETAILS}.${FIELD_CATCHMENT_POPULATION}`)}
      />

      <Field
        label="How many patients do you see on average per day?"
        name={`${FACILITY_DETAILS}.${FIELD_AVERAGE_DAILY_PATIENTS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_AVERAGE_DAILY_PATIENTS}`}
        value={getIn(values, `${FACILITY_DETAILS}.${FIELD_AVERAGE_DAILY_PATIENTS}`)}
      />

      <Field
        label="How many beds does your facility have?"
        name={`${FACILITY_DETAILS}.${FIELD_NUMBER_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_BEDS}`}
        value={getIn(values, `${FACILITY_DETAILS}.${FIELD_NUMBER_BEDS}`)}
      />

      <Field
        label="How many ICU beds (with ventilators) does your facility have?"
        name={`${FACILITY_DETAILS}.${FIELD_NUMBER_ICU_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_ICU_BEDS}`}
        value={getIn(values, `${FACILITY_DETAILS}.${FIELD_NUMBER_ICU_BEDS}`)}
      />

      <Field
        label="How many beds have been dedicated to coronavirus patients in your facility?"
        name={`${FACILITY_DETAILS}.${FIELD_NUMBER_CORONAVIRUS_BEDS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        className={`fa-${FIELD_NUMBER_CORONAVIRUS_BEDS}`}
        value={getIn(values, `${FACILITY_DETAILS}.${FIELD_NUMBER_CORONAVIRUS_BEDS}`)}
      />
      <Field
        label="Does your facility offer MCH service?"
        name={`${FIELD_SERVICES_AVAILABLE}.${MATERNITY}`}
        component={InputRadio}
        className={`fa-${MATERNITY}`}
        value={getIn(values, `${FIELD_SERVICES_AVAILABLE}.${MATERNITY}`)}
        options={yesOrNoOptions}
      />
      <Field
        label="Does your facility conduct maternal deliveries?"
        name={`${FIELD_SERVICES_AVAILABLE}.${FIELD_MATERNAL_DELIVERIES}`}
        component={InputRadio}
        className={`fa-${FIELD_MATERNAL_DELIVERIES}`}
        value={getIn(values, `${FIELD_SERVICES_AVAILABLE}.${FIELD_MATERNAL_DELIVERIES}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Do you provide HIV services at your facility?"
        name={`${FIELD_SERVICES_AVAILABLE}.${HIV}`}
        component={InputRadio}
        className={`fa-${HIV}`}
        value={getIn(values, `${FIELD_SERVICES_AVAILABLE}.${HIV}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Do you provide TB services at your facility?"
        name={`${FIELD_SERVICES_AVAILABLE}.${TUBERCOLOSIS}`}
        component={InputRadio}
        className={`fa-${TUBERCOLOSIS}`}
        value={getIn(values, `${FIELD_SERVICES_AVAILABLE}.${TUBERCOLOSIS}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Is there a Community Health Unit attached to your facility?"
        name={`${FIELD_SERVICES_AVAILABLE}.${FIELD_COMMUNITY_HEALTH_UNIT}`}
        component={InputRadio}
        className={`fa-${FIELD_COMMUNITY_HEALTH_UNIT}`}
        value={getIn(values, `${FIELD_SERVICES_AVAILABLE}.${FIELD_COMMUNITY_HEALTH_UNIT}`)}
        options={yesOrNoOptions}
      />
    </SectionContent>
    <Section title="COVID-19" />
    <SectionContent>
      <Field
        label="Do you have a COVID-19 holding area?"
        name={`${COVID}.${FIELD_COVID_HOLDING_AREA}`}
        component={InputRadio}
        className={`fa-${FIELD_COVID_HOLDING_AREA}`}
        value={getIn(values, `${COVID}.${FIELD_COVID_HOLDING_AREA}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Is your center an isolation facility?"
        name={`${COVID}.${FIELD_ISOLATION_FACILITY}`}
        component={InputRadio}
        className={`fa-${FIELD_ISOLATION_FACILITY}`}
        value={getIn(values, `${COVID}.${FIELD_ISOLATION_FACILITY}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Is your center a quarantine facility?"
        name={`${COVID}.${FIELD_QUARANTINE_FACILITY}`}
        component={InputRadio}
        className={`fa-${FIELD_QUARANTINE_FACILITY}`}
        value={getIn(values, `${COVID}.${FIELD_QUARANTINE_FACILITY}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Does your facility conduct COVID-19 testing?"
        name={`${COVID}.${FIELD_DOES_COVID_TESTING}`}
        component={InputRadio}
        className={`fa-${FIELD_DOES_COVID_TESTING}`}
        value={getIn(values, `${COVID}.${FIELD_DOES_COVID_TESTING}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="Do you have an operational thermal-gun?"
        name={`${COVID}.${FIELD_OPERATIONAL_THERMAL_GUN}`}
        component={InputRadio}
        className={`fa-${FIELD_OPERATIONAL_THERMAL_GUN}`}
        value={getIn(values, `${COVID}.${FIELD_OPERATIONAL_THERMAL_GUN}`)}
        options={yesOrNoOptions}
      />

      <Field
        label="How many confirmed COVID-19 cases have there been at your facility to date?"
        name={FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE}
        component={InputText}
        className={`fa-${FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE}`}
        type="number"
        inputMode="numeric"
        value={getIn(values, FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE)}
      />
      {getIn(values, FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE) === 0 || (
        <>
          <Field
            label="How many recovered COVID-19 cases have there been at your facility to date?"
            name={FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE}
            component={InputText}
            className={`fa-${FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE}`}
            type="number"
            inputMode="numeric"
            value={getIn(values, FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE)}
          />
          <Field
            label="How many COVID-19 deaths have there been at your facility to date?"
            name={FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE}
            component={InputText}
            className={`fa-${FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE}`}
            type="number"
            inputMode="numeric"
            value={getIn(values, FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE)}
          />
        </>
      )}
    </SectionContent>

    <Section title="Medication Stock Levels" />
    <SectionContent>
      <Field
        label="When did you last receive a delivery of medication from KEMSA or other sources?"
        name={FIELD_DATE_LAST_DELIVERY}
        component={DatePickerLazyLoaded}
        className={`fa-${FIELD_DATE_LAST_DELIVERY}`}
        value={values[FIELD_DATE_LAST_DELIVERY]}
      />
      {isDeveloperMode && (
        <>
          <p>Developer mode enabled</p>
          <p>{JSON.stringify(values)}</p>
          <p>{JSON.stringify(errors)}</p>
        </>
      )}
      <Submission
        text={isSubmitting ? 'Submitting...' : 'Submit'}
        canSubmit={canSubmit}
        handleSubmit={handleSubmit}
        error={submissionError}
        isSubmitting={isSubmitting}
      />
    </SectionContent>
  </Box>
);

export default Details;
