import React from 'react';
import styled from 'styled-components';
import { PageCenteredContent } from '../components/Page/index';
import Section from '../components/Section/index';
import theme from '../theme';
import Footer from '../components/Footer';

const Message = styled.h2`
  text-align: center;
  margin-top: ${theme.spacing(15)};
  flex: 1;
`;

const NotFound = () => {
  return (
    <PageCenteredContent>
      <Section title="Not Found" />
      <Message>This page does not exist</Message>
      <Footer/>
    </PageCenteredContent>
  );
};

export default NotFound;
