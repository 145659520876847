import {
  CURRENT_SITUATION,
  PAST_DAY,
  CHU_PERSIST_DAILY_PREFIX,
  FIELD_FACILITY_TYPE,
  CHU_NUMBER_CHU_VISITED,
  CHU_LEVEL_MASK_COMPLIANCE,
  CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE,
  CHU_LEVEL_HAND_WASHING_COMPLIANCE,
  OPTIONS_CHU_COMPLIANCE,
  CHU_NUMBER_IEC_MATERIALS,
  CHU_NUMBER_VISITORS,
  CHU_NUMBER_LEAVING_VISITORS,
  CHU_NUMBER_HOUSEHOLD_VISITED,
  CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES,
  CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS,
  CHU_NUMBER_SUSPECTED_COVID,
  CHU_NUMBER_TRACED_CONTACTS,
  CHU_NUMBER_REFERRALS,
  CHU_PATIENTS_HOME_ISOLATION,
  CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE,
  CHU_PATIENT_COMMENCED_HOME_ISOLATION,
  CHU_NUMBER_HOUSEHOLDS_TRAINED,
  CHU_PATIENTS_REFERRED_HOSPITAL,
  CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS,
  CHU_NUMBER_CONTACTS_MONITORED,
  CHU_HOUSEHOLDS_ADEQUATE_PPE,
  CHU_PATIENTS_COMPLETED_HOME_ISOLATION,
  CHU_NUMBER_SELF_QUARANTINE_TOTAL,
  CHU_NUMBER_SELF_QUARANTINE_WEEK,
  CHU_NUMBER_VISITS,
  CHU_NUMBER_DEFAULTED_SELF_QUARANTINE,
} from '../../schema/constants';
import { Field, Form, Formik, getIn } from 'formik';
import { Persister, useHydration } from '../../components/Persistence';
import React, { useEffect, useState } from 'react';
import InputText from '../../components/Input/Text';
import Page from '../../components/Page';
import Section from '../../components/Section';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import api from '../../api';
import { isDeveloperMode } from '../../utils/debug';
import { generateSchema } from '../../schema/validation/communityHealthUnitWeeklyReport';
import { scrollToTopOfPage, generatePersistenceKey } from '../../helpers';
import InputRadio from '../../components/Input/Radio';
import FacilityName from '../../components/FacilityName';

const DailyGeneralUpdate = ({ accessToken, facilityId, onSuccess }) => {
  const [submissionError, setSubmissionError] = useState('');
  useEffect(() => {
    scrollToTopOfPage();
  }, []);
  const [sessionStorageKey] = useState(generatePersistenceKey(CHU_PERSIST_DAILY_PREFIX));
  const initialValues = useHydration(sessionStorageKey, {
    facilityId,
    accessToken,
    [FIELD_FACILITY_TYPE]: 'community',
  });

  const onSubmit = async (values) => {
    try {
      setSubmissionError('');
      await api.post(process.env.REACT_APP_DAILY_REPORT_SERVICE_URL, values);
      sessionStorage.removeItem(sessionStorageKey);
      onSuccess();
    } catch (error) {
      console.error(error);
      setSubmissionError('Something went wrong');
    }
  };

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={generateSchema()}
        validateOnMount={true}
      >
        {({ isValid, isSubmitting, handleSubmit, values, errors }) => (
          <Form>
            <Persister name={sessionStorageKey} ignoredKeys={['facilityId', 'accessToken']} />
			<FacilityName h1/>
            <Section title="Community" />
            <SectionContent>
              <Field
                label="How many community centres were visited by CHVs this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_CHU_VISITED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_CHU_VISITED}`)}
                className={`chu-${CHU_NUMBER_CHU_VISITED}`}
              />
              <Field
                label="What is the level of compliance of use of masks in public spaces in your CHU?"
                name={`${CURRENT_SITUATION}.${CHU_LEVEL_MASK_COMPLIANCE}`}
                component={InputRadio}
                options={OPTIONS_CHU_COMPLIANCE}
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_LEVEL_MASK_COMPLIANCE}`)}
                className={`chu-${CHU_LEVEL_MASK_COMPLIANCE}`}
              />
              <Field
                label="What is the level of compliance of social distancing in public spaces in your CHU?"
                name={`${CURRENT_SITUATION}.${CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE}`}
                component={InputRadio}
                options={OPTIONS_CHU_COMPLIANCE}
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE}`,
                )}
                className={`chu-${CHU_LEVEL_SOCIAL_DISTANCING_COMPLIANCE}`}
              />
              <Field
                label="What is the level of compliance of hand washing in public spaces in your CHU?"
                name={`${CURRENT_SITUATION}.${CHU_LEVEL_HAND_WASHING_COMPLIANCE}`}
                component={InputRadio}
                options={OPTIONS_CHU_COMPLIANCE}
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_LEVEL_HAND_WASHING_COMPLIANCE}`)}
                className={`chu-${CHU_LEVEL_HAND_WASHING_COMPLIANCE}`}
              />
              <Field
                label="How many IEC materials have been distributed in community centres this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_IEC_MATERIALS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_IEC_MATERIALS}`)}
                className={`chu-${CHU_NUMBER_IEC_MATERIALS}`}
              />
            </SectionContent>

            <Section title="Movement of People" />
            <SectionContent>
              <Field
                label="How many visitors have visited from other counties in the past week?"
                name={`${PAST_DAY}.${CHU_NUMBER_VISITORS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${PAST_DAY}.${CHU_NUMBER_VISITORS}`)}
                className={`chu-${CHU_NUMBER_VISITORS}`}
              />
              <Field
                label="How many visitors from other counties have left in the past week?"
                name={`${PAST_DAY}.${CHU_NUMBER_LEAVING_VISITORS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${PAST_DAY}.${CHU_NUMBER_LEAVING_VISITORS}`)}
                className={`chu-${CHU_NUMBER_LEAVING_VISITORS}`}
              />
            </SectionContent>

            <Section title="Households" />
            <SectionContent>
              <Field
                label="How many households were visited by CHVs this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_VISITED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_VISITED}`)}
                className={`chu-${CHU_NUMBER_HOUSEHOLD_VISITED}`}
              />

              <Field
                label="How many clients are currently in self-quarantine?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_SELF_QUARANTINE_TOTAL}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_SELF_QUARANTINE_TOTAL}`)}
                className={`chu-${CHU_NUMBER_SELF_QUARANTINE_TOTAL}`}
              />
              <Field
                label="How many follow up visits have been made to those in self-quarantine this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_VISITS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_VISITS}`)}
                className={`chu-${CHU_NUMBER_VISITS}`}
              />
              <Field
                label="How many people started self-quarantine this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_SELF_QUARANTINE_WEEK}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_SELF_QUARANTINE_WEEK}`)}
                className={`chu-${CHU_NUMBER_SELF_QUARANTINE_WEEK}`}
              />
              <Field
                label="How many people have defaulted from self-quarantine this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_DEFAULTED_SELF_QUARANTINE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_NUMBER_DEFAULTED_SELF_QUARANTINE}`,
                )}
                className={`chu-${CHU_NUMBER_DEFAULTED_SELF_QUARANTINE}`}
              />
              <Field
                label="How many households visited had hand-washing facilities?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES}`,
                )}
                className={`chu-${CHU_NUMBER_HOUSEHOLD_HAND_WASHING_FACILITIES}`}
              />
              <Field
                label="How many households visited were issued IEC materials?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS}`)}
                className={`chu-${CHU_NUMBER_HOUSEHOLD_IEC_MATERIALS}`}
              />
            </SectionContent>

            <Section title="Contact Tracing" />
            <SectionContent>
              <Field
                label="How many new suspected COVID-19 contacts have been identified this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_SUSPECTED_COVID}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_SUSPECTED_COVID}`)}
                className={`chu-${CHU_NUMBER_SUSPECTED_COVID}`}
              />
              <Field
                label="How many COVID-19 patient contacts have been traced to your CHU this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_TRACED_CONTACTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_TRACED_CONTACTS}`)}
                className={`chu-${CHU_NUMBER_TRACED_CONTACTS}`}
              />
              <Field
                label="How many referrals were made to your link facility this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_REFERRALS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_REFERRALS}`)}
                className={`chu-${CHU_NUMBER_REFERRALS}`}
              />
            </SectionContent>

            <Section title="Home-Based Isolation and Care" />
            <SectionContent>
              <Field
                label="What is the total number of households with COVID-19 patients on home-based isolation and care this week? "
                name={`${CURRENT_SITUATION}.${CHU_PATIENTS_HOME_ISOLATION}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_PATIENTS_HOME_ISOLATION}`)}
                className={`chu-${CHU_PATIENTS_HOME_ISOLATION}`}
              />
              <Field
                label="Of these, how many have a functional phone?"
                name={`${CURRENT_SITUATION}.${CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE}`,
                )}
                className={`chu-${CHU_PATIENTS_HOME_ISOLATION_WITH_PHONE}`}
              />
              <Field
                label="How many COVID-19 patients commenced home-based isolation and care this week?"
                name={`${CURRENT_SITUATION}.${CHU_PATIENT_COMMENCED_HOME_ISOLATION}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_PATIENT_COMMENCED_HOME_ISOLATION}`,
                )}
                className={`chu-${CHU_PATIENT_COMMENCED_HOME_ISOLATION}`}
              />
              <Field
                label="How many households were trained on home-based isolation and care this week? "
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLDS_TRAINED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_HOUSEHOLDS_TRAINED}`)}
                className={`chu-${CHU_NUMBER_HOUSEHOLDS_TRAINED}`}
              />
              <Field
                label="How many COVID-19 patients on home-based isolation and care have been referred for hospital management?"
                name={`${CURRENT_SITUATION}.${CHU_PATIENTS_REFERRED_HOSPITAL}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_PATIENTS_REFERRED_HOSPITAL}`)}
                className={`chu-${CHU_PATIENTS_REFERRED_HOSPITAL}`}
              />

              <Field
                label="What is the total number of household contacts of all COVID-19 patients on home-based isolation and care? "
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS}`)}
                className={`chu-${CHU_NUMBER_TOTAL_HOUSEHOLD_CONTACTS}`}
              />

              <Field
                label="How many household contacts filled out the symptom monitoring schedule this week?"
                name={`${CURRENT_SITUATION}.${CHU_NUMBER_CONTACTS_MONITORED}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_NUMBER_CONTACTS_MONITORED}`)}
                className={`chu-${CHU_NUMBER_CONTACTS_MONITORED}`}
              />
              <Field
                label="How many households with COVID-19 patients on home-based isolation and care have adequate PPEs?"
                name={`${CURRENT_SITUATION}.${CHU_HOUSEHOLDS_ADEQUATE_PPE}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(values, `${CURRENT_SITUATION}.${CHU_HOUSEHOLDS_ADEQUATE_PPE}`)}
                className={`chu-${CHU_HOUSEHOLDS_ADEQUATE_PPE}`}
              />
              <Field
                label="How many COVID-19 patients on home-based isolation and care completed home-based care this week?"
                name={`${CURRENT_SITUATION}.${CHU_PATIENTS_COMPLETED_HOME_ISOLATION}`}
                component={InputText}
                type="number"
                inputMode="numeric"
                value={getIn(
                  values,
                  `${CURRENT_SITUATION}.${CHU_PATIENTS_COMPLETED_HOME_ISOLATION}`,
                )}
                className={`chu-${CHU_PATIENTS_COMPLETED_HOME_ISOLATION}`}
              />

              {isDeveloperMode && (
                <>
                  <p>Developer mode enabled</p>
                  <p>{JSON.stringify(values)}</p>
                  <p>{JSON.stringify(errors)}</p>
                </>
              )}
              <Submission
                text={isSubmitting ? 'Submitting...' : 'Submit'}
                canSubmit={isValid}
                handleSubmit={handleSubmit}
                error={submissionError}
                isSubmitting={isSubmitting}
                id="chu-button-submit-form"
              />
            </SectionContent>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default DailyGeneralUpdate;
