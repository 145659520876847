import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import theme from '../../theme';

const InputGroup = styled(FormGroup)`
  &:not(:last-child) {
    margin-bottom: ${theme.spacing(3)};
  }
`;

export default InputGroup;
