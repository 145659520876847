import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import theme, { pxToEms } from '../../theme';

const StyledButton = styled(Button)`
  min-width: unset;
  text-transform: none;
  color: ${theme.colors.primary};
  padding: 0;
  font-weight: bold;
  font-size: ${pxToEms(18)};

  svg {
    margin-right: ${theme.spacing(1)};
    font-size: 1.9rem;
  }
`;

const IconButton = ({ label, onClick, icon }) => (
  <StyledButton onClick={onClick}>
    {icon}
    {label}
  </StyledButton>
);

export const AddButton = ({ label, onClick }) => (
  <IconButton label={label} onClick={onClick} icon={<AddIcon />} />
);
export const DeleteButton = ({ label, onClick }) => (
  <IconButton label={label} onClick={onClick} icon={<DeleteIcon color="secondary" />} />
);
