import {
  CHU_DETAILS,
  CHV_NUMBER_OVERSEEN,
  CHU_NUMBER_OVERSEEN,
  CHU_NUMBER_SENSITIZED_CHV,
  MCHUL_CODE,
  FACILITY_LINK,
  CHU_POPULATION,
  CHU_HOUSEHOLDS,
  CHU_MARKETS,
  CHU_TOWNS,
  CHU_BODA,
  CHU_TEA,
  NUM_HANDWASHING_POINTS,
  NUM_PREGNANT,
  NUM_ELDERLY,
  NUM_CHRONIC_ILLNESS,
  COVID_PATIENTS,
  NUM_TRAINED_HH,
  NUM_HH_HANDWASHING,
  CHU_LOCATION,
  CHU_SUBLOCATION,
} from '../../schema/constants';
import { Field, getIn } from 'formik';
import React from 'react';

import { Box } from 'reflexbox/styled-components';
import InlineNumber from '../../components/Input/InlineNumber';
import InputText from '../../components/Input/Text';
import Label from '../../components/Label';
import Section from '../../components/Section';
import SectionContent from '../../components/Section/Content';
import Submission from '../../components/Submission';
import { isDeveloperMode } from '../../utils/debug';

const Details = ({ canSubmit, isSubmitting, handleSubmit, values, submissionError, errors }) => (
  <Box>
    <Section title="Community Health Unit" />
    <SectionContent>
      <Field
        label="Location of your Community Health Unit:"
        name={`${CHU_DETAILS}.${CHU_LOCATION}`}
        component={InputText}
        type="text"
        value={getIn(values, `${CHU_DETAILS}.${CHU_LOCATION}`)}
        className={`chu-${CHU_LOCATION}`}
      />

      <Field
        label="Sub-location of your Community Health Unit:"
        name={`${CHU_DETAILS}.${CHU_SUBLOCATION}`}
        component={InputText}
        value={getIn(values, `${CHU_DETAILS}.${CHU_SUBLOCATION}`)}
        className={`chu-${CHU_SUBLOCATION}`}
      />

      <Field
        label="How many Community Health Units (CHUs) are you overseeing?"
        name={`${CHU_DETAILS}.${CHU_NUMBER_OVERSEEN}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_NUMBER_OVERSEEN}`)}
        className={`chu-${CHU_NUMBER_OVERSEEN}`}
      />
      <Field
        label="How many Community Health Volunteers do you oversee in this CHU?"
        name={`${CHU_DETAILS}.${CHV_NUMBER_OVERSEEN}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHV_NUMBER_OVERSEEN}`)}
        className={`chu-${CHV_NUMBER_OVERSEEN}`}
      />
      <Field
        label="How many CHVs have been sensitized on COVID-19 response?"
        name={`${CHU_DETAILS}.${CHU_NUMBER_SENSITIZED_CHV}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_NUMBER_SENSITIZED_CHV}`)}
        className={`chu-${CHU_NUMBER_SENSITIZED_CHV}`}
      />
      <Field
        label="What is your MCHUL code?"
        name={`${CHU_DETAILS}.${MCHUL_CODE}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${MCHUL_CODE}`)}
        className={`chu-${MCHUL_CODE}`}
      />
      <Field
        label="Name of health facility you are linked to?"
        name={`${CHU_DETAILS}.${FACILITY_LINK}`}
        component={InputText}
        value={getIn(values, `${CHU_DETAILS}.${FACILITY_LINK}`)}
        className={`chu-${FACILITY_LINK}`}
      />
      <Field
        label="What is the total population of your CHU?"
        name={`${CHU_DETAILS}.${CHU_POPULATION}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_POPULATION}`)}
        className={`chu-${CHU_POPULATION}`}
      />

      <div style={{ marginBottom: '20px' }}>
        <Label label="What are the total number of: " />
      </div>

      <Field
        label="Households"
        name={`${CHU_DETAILS}.${CHU_HOUSEHOLDS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_HOUSEHOLDS}`)}
        className={`chu-${CHU_HOUSEHOLDS}`}
      />

      <Field
        label="Markets"
        name={`${CHU_DETAILS}.${CHU_MARKETS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_MARKETS}`)}
        className={`chu-${CHU_MARKETS}`}
      />
      <Field
        label="Towns/Centres"
        name={`${CHU_DETAILS}.${CHU_TOWNS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_TOWNS}`)}
        className={`chu-${CHU_TOWNS}`}
      />
      <Field
        label="Boda boda sheds"
        name={`${CHU_DETAILS}.${CHU_BODA}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_BODA}`)}
        className={`chu-${CHU_BODA}`}
      />
      <Field
        label="Tea buying centres"
        name={`${CHU_DETAILS}.${CHU_TEA}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${CHU_TEA}`)}
        className={`chu-${CHU_TEA}`}
      />

      <div style={{ marginTop: '30px' }}>
        <Field
          label="What is the number of community meeting places (mentioned above) with hand-washing points?"
          name={`${CHU_DETAILS}.${NUM_HANDWASHING_POINTS}`}
          component={InputText}
          type="number"
          inputMode="numeric"
          value={getIn(values, `${CHU_DETAILS}.${NUM_HANDWASHING_POINTS}`)}
          className={`chu-${NUM_HANDWASHING_POINTS}`}
        />
      </div>
    </SectionContent>

    <Section title="Household Information" />
    <SectionContent>
      <Field
        label="What is the number of households with hand-washing facilities?"
        name={`${CHU_DETAILS}.${NUM_HH_HANDWASHING}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${NUM_HH_HANDWASHING}`)}
        className={`chu-${NUM_HH_HANDWASHING}`}
      />

      <div style={{ marginBottom: '20px' }}>
        <Label label="What is the total number of households with: " />
      </div>

      <Field
        label="Pregnant women"
        name={`${CHU_DETAILS}.${NUM_PREGNANT}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${NUM_PREGNANT}`)}
        className={`chu-${NUM_PREGNANT}`}
      />
      <Field
        label="Elderly > 60"
        name={`${CHU_DETAILS}.${NUM_ELDERLY}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${NUM_ELDERLY}`)}
        className={`chu-${NUM_ELDERLY}`}
      />

      <Field
        label="Chronic Illness"
        name={`${CHU_DETAILS}.${NUM_CHRONIC_ILLNESS}`}
        component={InlineNumber}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${NUM_CHRONIC_ILLNESS}`)}
        className={`chu-${NUM_CHRONIC_ILLNESS}`}
      />
    </SectionContent>
    <Section title="Home Based Isolation &amp; Care" />
    <SectionContent>
      <Field
        label="How many COVID-19 patients are on home-based isolation and care for COVID-19 to date?"
        name={`${CHU_DETAILS}.${COVID_PATIENTS}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${COVID_PATIENTS}`)}
        className={`chu-${COVID_PATIENTS}`}
      />
      <Field
        label="How many households have been trained on home-based isolation and care?"
        name={`${CHU_DETAILS}.${NUM_TRAINED_HH}`}
        component={InputText}
        type="number"
        inputMode="numeric"
        value={getIn(values, `${CHU_DETAILS}.${NUM_TRAINED_HH}`)}
        className={`chu-${NUM_TRAINED_HH}`}
      />

      {isDeveloperMode && (
        <>
          <p>Developer mode enabled</p>
          <p>{JSON.stringify(values)}</p>
          <p>{JSON.stringify(errors)}</p>
        </>
      )}
      <Submission
        text={isSubmitting ? 'Submitting...' : 'Submit'}
        canSubmit={canSubmit}
        handleSubmit={handleSubmit}
        error={submissionError}
        isSubmitting={isSubmitting}
        id="chu-button-submit-form"
      />
    </SectionContent>
  </Box>
);

export default Details;
