import {
  COVID,
  ERROR_MESSAGE_REQUIRED,
  FACILITY_DETAILS,
  FIELD_AVERAGE_DAILY_PATIENTS,
  FIELD_CATCHMENT_POPULATION,
  FIELD_COMMUNITY_HEALTH_UNIT,
  FIELD_COVID_HOLDING_AREA,
  FIELD_DATE_LAST_DELIVERY,
  FIELD_DOES_COVID_TESTING,
  FIELD_FACILITY_ID,
  FIELD_IN_CHARGE_MEMBERS,
  FIELD_ISOLATION_FACILITY,
  FIELD_MATERNAL_DELIVERIES,
  FIELD_NUMBER_BEDS,
  FIELD_NUMBER_CORONAVIRUS_BEDS,
  FIELD_NUMBER_HW_CLINICAL,
  FIELD_NUMBER_HW_HEALTH_ASSISTANTS,
  FIELD_NUMBER_HW_LABTECH,
  FIELD_NUMBER_HW_MEDICAL,
  FIELD_NUMBER_HW_NON_TECHNICAL,
  FIELD_NUMBER_HW_NURSE,
  FIELD_NUMBER_HW_OTHER_TECHNICAL,
  FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF,
  FIELD_NUMBER_ICU_BEDS,
  FIELD_OPERATIONAL_THERMAL_GUN,
  FIELD_QUARANTINE_FACILITY,
  FIELD_SERVICES_AVAILABLE,
  HEALTH_WORKERS,
  HIV,
  MATERNITY,
  TUBERCOLOSIS,
  FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE,
  FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE,
  FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE
} from '../constants';
import { array, boolean, date, object, mixed } from 'yup';
import { requiredInteger, requiredNonNumeric, requiredString, validatePhoneNumber, validateNumber } from '../utils';

const validateCovidCases = () => mixed().when(FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE, {
  is: cases => cases > 0,
  then: validateNumber().required(ERROR_MESSAGE_REQUIRED)
    .test(
      `You can't have more deaths/recoveries than total cases`,
      `Confirmed coronavirus deaths and recoveries should not be more than total confirmed cases`,
      function () {
        const recoveredCasesToDate = this.parent[FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE] || 0;
        const deathsToDate = this.parent[FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE] || 0;
        return this.parent[FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE] >= recoveredCasesToDate + deathsToDate;
      }),
});

export const generateSchema = () => {
  return object().shape({
    [FIELD_FACILITY_ID]: requiredString,
    [FIELD_IN_CHARGE_MEMBERS]: array()
      .of(
        object().shape({
          name: requiredNonNumeric,
          mobileNumber: validatePhoneNumber,
        }),
      )
      .min(1)
      .required(ERROR_MESSAGE_REQUIRED),
    [FIELD_CONFIRMED_CORONAVIRUS_CASES_TO_DATE]: requiredInteger(),
    [FIELD_CONFIRMED_CORONAVIRUS_DEATHS_TO_DATE]: validateCovidCases(),
    [FIELD_RECOVERED_CORONAVIRUS_CASES_TO_DATE]: validateCovidCases(),
    [HEALTH_WORKERS]: object().shape({
      [FIELD_NUMBER_HW_MEDICAL]: requiredInteger(),
      [FIELD_NUMBER_HW_CLINICAL]: requiredInteger(),
      [FIELD_NUMBER_HW_NURSE]: requiredInteger(),
      [FIELD_NUMBER_HW_LABTECH]: requiredInteger(),
      [FIELD_NUMBER_HW_OTHER_TECHNICAL]: requiredInteger(),
      [FIELD_NUMBER_HW_NON_TECHNICAL]: requiredInteger(),
      [FIELD_NUMBER_HW_HEALTH_ASSISTANTS]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_NUMBER_HW_TRAINED_CORONAVIRUS_STAFF]: requiredInteger(),
    }).required(ERROR_MESSAGE_REQUIRED),

    [FACILITY_DETAILS]: object().shape({
      [FIELD_CATCHMENT_POPULATION]: requiredInteger(1000000),
      [FIELD_AVERAGE_DAILY_PATIENTS]: requiredInteger(),
      [FIELD_NUMBER_BEDS]: requiredInteger(),
      [FIELD_NUMBER_ICU_BEDS]: requiredInteger(),
      [FIELD_NUMBER_CORONAVIRUS_BEDS]: requiredInteger(),
    }).required(ERROR_MESSAGE_REQUIRED),

    [COVID]: object().shape({
      [FIELD_COVID_HOLDING_AREA]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_ISOLATION_FACILITY]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_QUARANTINE_FACILITY]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_DOES_COVID_TESTING]: boolean().required(ERROR_MESSAGE_REQUIRED),
      [FIELD_OPERATIONAL_THERMAL_GUN]: boolean().required(ERROR_MESSAGE_REQUIRED),
    }).required(ERROR_MESSAGE_REQUIRED),


    [FIELD_SERVICES_AVAILABLE]: object()
      .shape({
        [MATERNITY]: boolean().required(ERROR_MESSAGE_REQUIRED),
        [FIELD_MATERNAL_DELIVERIES]: boolean().required(ERROR_MESSAGE_REQUIRED),
        [HIV]: boolean().required(ERROR_MESSAGE_REQUIRED),
        [TUBERCOLOSIS]: boolean().required(ERROR_MESSAGE_REQUIRED),
        [FIELD_COMMUNITY_HEALTH_UNIT]: boolean().required(ERROR_MESSAGE_REQUIRED)
      })
      .required(ERROR_MESSAGE_REQUIRED),
    [FIELD_DATE_LAST_DELIVERY]: date()
      .max(new Date(), 'Date must be in the past')
      .required(ERROR_MESSAGE_REQUIRED),
  });
};
