import {
  FIELD_FACILITY_ID,
  CHU_DETAILS,
  CHU_NUMBER_OVERSEEN,
  CHU_NUMBER_SENSITIZED_CHV,
  MCHUL_CODE,
  FACILITY_LINK,
  CHU_POPULATION,
  CHU_HOUSEHOLDS,
  CHU_MARKETS,
  CHU_TOWNS,
  CHU_BODA,
  CHU_TEA,
  NUM_PREGNANT,
  NUM_ELDERLY,
  NUM_CHRONIC_ILLNESS,
  COVID_PATIENTS,
  NUM_TRAINED_HH,
  NUM_HH_HANDWASHING,
  ERROR_MESSAGE_REQUIRED,
  CHU_LOCATION,
  CHU_SUBLOCATION,
  CHV_NUMBER_OVERSEEN,
  NUM_HANDWASHING_POINTS,
  FIELD_IN_CHARGE_MEMBERS,
} from '../constants';

import { array, object } from 'yup';
import {
  requiredInteger,
  requiredNonNumeric,
  requiredString,
  validatePhoneNumber,
  validateNumber,
  validateMCHUL,
} from '../utils';

const validateSensitizedCHV = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more overseen sensitixed CHV than total CHV`,
      `Can not exceed the number of total Community Health Volunteers`,
      function () {
        const totalVolunteers = this.parent[CHV_NUMBER_OVERSEEN] || 0;
        return this.parent[CHU_NUMBER_SENSITIZED_CHV] <= totalVolunteers;
      },
    );

const validateHandwashingPoints = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Can not exceed the total number of centers`,
      `Can not exceed the total number of centers`,
      function () {
        const households = this.parent[CHU_HOUSEHOLDS] || 0;
        const markets = this.parent[CHU_MARKETS] || 0;
        const towns = this.parent[CHU_TOWNS] || 0;
        const boda = this.parent[CHU_BODA] || 0;
        const tea = this.parent[CHU_TEA] || 0;
        const totalCenters = households + markets + towns + boda + tea;

        return this.parent[NUM_HANDWASHING_POINTS] <= totalCenters;
      },
    );

const validateHouseholds = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more than the total number of Households`,
      `Can not exceed the total number of Households`,
      function () {
        const totalHouseHolds = this.parent[CHU_HOUSEHOLDS];
        return this.parent[NUM_HH_HANDWASHING] <= totalHouseHolds;
      },
    );

const validatePeopleHouseholds = () =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more than the total number of Households`,
      `Can not exceed the total number of Households`,
      function () {
        const pregnant = this.parent[NUM_PREGNANT] || 0;
        const elderly = this.parent[NUM_ELDERLY] || 0;
        const chronicIllness = this.parent[NUM_CHRONIC_ILLNESS] || 0;
        const totalHighRisk = pregnant + elderly + chronicIllness;
        return this.parent[CHU_HOUSEHOLDS] >= totalHighRisk;
      },
    );

const validateTrainedHH = (households) =>
  validateNumber()
    .required(ERROR_MESSAGE_REQUIRED)
    .test(
      `Not more than the total number of Households`,
      `Can not exceed the total number of Households`,
      function () {
        return this.parent[NUM_TRAINED_HH] <= this.parent[CHU_HOUSEHOLDS];
      },
    );

export const generateSchema = () => {
  return object().shape({
    [FIELD_FACILITY_ID]: requiredString,
    [FIELD_IN_CHARGE_MEMBERS]: array()
      .of(
        object().shape({
          name: requiredNonNumeric,
          mobileNumber: validatePhoneNumber,
        }),
      )
      .min(1)
      .required(ERROR_MESSAGE_REQUIRED),

    [CHU_DETAILS]: object()
      .shape({
        [CHU_LOCATION]: requiredString,
        [CHU_SUBLOCATION]: requiredString,
        [CHU_NUMBER_OVERSEEN]: requiredInteger(),
        [CHV_NUMBER_OVERSEEN]: requiredInteger(),
        [CHU_NUMBER_SENSITIZED_CHV]: validateSensitizedCHV(),
        [MCHUL_CODE]: validateMCHUL,
        [FACILITY_LINK]: requiredString,
        [CHU_POPULATION]: requiredInteger(),
        [CHU_HOUSEHOLDS]: requiredInteger(),
        [CHU_MARKETS]: requiredInteger(),
        [CHU_TOWNS]: requiredInteger(),
        [CHU_BODA]: requiredInteger(),
        [CHU_TEA]: requiredInteger(),
        [NUM_HANDWASHING_POINTS]: validateHandwashingPoints(),
        [NUM_HH_HANDWASHING]: validateHouseholds(),
        [NUM_PREGNANT]: validatePeopleHouseholds(),
        [NUM_ELDERLY]: validatePeopleHouseholds(),
        [NUM_CHRONIC_ILLNESS]: validatePeopleHouseholds(),
        [COVID_PATIENTS]: requiredInteger(),
        [NUM_TRAINED_HH]: validateTrainedHH(),
      })
      .required(ERROR_MESSAGE_REQUIRED),
  });
};
