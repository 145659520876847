import React, { useState } from 'react';
import { Field, Form, Formik} from 'formik';
import styled from 'styled-components';
import InputText from '../Input/Text';
import InputPhone from '../Input/Phone';
import Submission from '../Submission';
import api from '../../api/index';
import schema from '../../schema/validation/csCallback'
import {
  CS_CALLBACK_MESSAGE,
  CS_CALLBACK_NAME,
  CS_CALLBACK_PHONE,
  CS_CALLBACK_COUNTY
} from '../../schema/constants';
import theme from '../../theme';

const SexyForm = styled(Form)`
  padding: ${theme.spacing(3)} ${theme.spacing(4)};
  background: white;
  max-width: 30rem;
`;

const CallbackForm = ({onSuccess}) => {

  const [submissionError, setSubmissionError] = useState('');
  const initialValues = {
    name : '',
    phone: '',
    message: '',
    county: '',
  };

  const onSubmit = async (values) => {
    try {
      await api.post(process.env.REACT_APP_CS_CALLBACK_URL, values);
      onSuccess();
    } catch (e) {
      console.error(e);
      setSubmissionError('Something went wrong');
    }
  };

  return (<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
    {({isValid, isSubmitting, handleSubmit}) => (
      <SexyForm>
        <p>If you're having any problems, please complete this form, with a short message, and one of our customer service team will call you as soon as possible.</p>

        <p>By clicking 'send' you agree to our processing your data for the purposes of providing you customer support,
           in accordance with Elephant’s <a href="elephant.healthcare/privacy page" target="_blank">Privacy Policy</a>.</p>

          <Field
            label="Name"
            name={CS_CALLBACK_NAME}
            component={InputText}
          />
          <Field
            label="Mobile Phone number"
            name={CS_CALLBACK_PHONE}
            component={InputPhone}
            type='phone'
            inputMode='tel'
          />
          <Field
            label="County"
            name={CS_CALLBACK_COUNTY}
            component={InputText}
          />
          <Field
            label="Message"
            name={CS_CALLBACK_MESSAGE}
            component={InputText}
            asTextArea
            rows={3}
          />
        <Submission
          text={isSubmitting ? 'Sending...' : 'Send'}
          canSubmit={isValid}
          handleSubmit={handleSubmit}
          error={submissionError}
          isSubmitting={isSubmitting}
        />
      </SexyForm>
    )}
  </Formik>);
};

export default CallbackForm;