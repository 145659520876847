const cleanRequestValues = ({
  facilityId,
  facilityType,
  inChargeMembers,
  facilityName,
  chuDetails,
}) => {
  const {
    chuLocation,
    chuSubLocation,
    numberOfOverseenCHU,
    numberOfOverseenCHV,
    numSensitizedCHVsOnCovid,
    mCHULCode,
    facilityLink,
    numTotalPopulation,
    households,
    markets,
    towns,
    boda,
    tea,
    numHandwashingPoints,
    numHouseholdsWithHandwashing,
    numPregnant,
    numElderly,
    numChronicIllness,
    covidPatientsToDate,
    numTrainedHouseholds,
  } = chuDetails;

  return {
    facilityId,
    facilityType,
    inChargeMembers,
    facilityName,
    chuDetails: {
      chuLocation,
      chuSubLocation,
      numberOfOverseenCHU,
      numberOfOverseenCHV,
      numSensitizedCHVsOnCovid,
      mCHULCode,
      facilityLink,
      numTotalPopulation,
      households,
      markets,
      towns,
      boda,
      tea,
      numHandwashingPoints,
    },
    householdInfo: {
      numHouseholdsWithHandwashing,
      numPregnant,
      numElderly,
      numChronicIllness,
    },
    homeIsolation: {
      covidPatientsToDate,
      numTrainedHouseholds,
    },
  };
};

export default cleanRequestValues;
