import React from 'react';
import { Box } from 'reflexbox/styled-components';
import Page, { PageCenteredContent } from '../../components/Page/index';
import ConfirmationMessage from '../../components/ConfirmationMessage';
import theme from '../../theme';

const DailyReportThankYou = () => {
  return (
    <Page>
      <PageCenteredContent>
        <Box p={`0 ${theme.spacing(10)}`}>
          <ConfirmationMessage data-testid="daily-confirmation-message">
            We appreciate your continued efforts in keeping The Ministry of Health updated during
            the coronavirus outbreak.
          </ConfirmationMessage>
          <ConfirmationMessage>We will send you another link tomorrow.</ConfirmationMessage>
          <ConfirmationMessage>
            Thank you from The Ministry of Health and Elephant Healthcare.
          </ConfirmationMessage>
        </Box>
      </PageCenteredContent>
    </Page>
  );
};

export default DailyReportThankYou;
